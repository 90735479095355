import request from '@/utils/request'
import qs from 'qs';
// 查看优惠卷列表
export function showList(params) {
    return request({
        url: 'discount/all',
        method: 'get',
        params,
        paramsSerializer: params =>{
            return qs.stringify(params, { indices: false })
        }
    });
}
// 新增优惠卷
export function addDiscount(data) {
    return request({
        url: 'discount',
        method: 'post',
        data
    });
}
// 根据类型获取会员
export function selectMemberTypeList(type) {
    return request({
        url: 'member/member/type/'+type,
        method: 'get'
    });
}
// 获取所有服务项目
export function allServiceProject(params) {
    return request({
        url: 'service-project/all',
        method: 'get',
        params
    });
}

// 根据优惠卷id查询优惠卷
export function byId(id) {
    return request({
        url: 'discount/'+id,
        method: 'get'
    });
}
// 更新优惠卷
export function updateDiscount(data) {
    return request({
        url: 'discount',
        method: 'put',
        data
    });
}
// 删除优惠卷
export function removeDiscount(id) {
    return request({
        url: 'discount/'+id,
        method: 'delete'
    });
}
// 根据优惠卷id获取客户优惠卷列表
export function getCustomerDiscountByDiscountId(params) {
    return request({
        url: 'discount/customer/discount/discountId',
        method: 'get',
        params
    });
}
