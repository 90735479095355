import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login/Login.vue'
import shopUsers from '@/views/userManagement/shopUsers.vue'
import achievements from '@/views/achievements/index.vue'
import systemUsers from '@/views/userManagement/systemUsers.vue'
import shopManagement from '@/views/shopManagement/shopManagement.vue'
import carCategary from '@/views/carManagement/carCategary.vue'
import carClassification from '@/views/carManagement/carClassification.vue'
import orderManagement from '@/views/orderManagement/index.vue'
import orderTabs from '@/views/orderManagement/orderTabs.vue'

import evaluationManagement from '@/views/evaluationManagement/indexCopy.vue'



import goodsManagement from '@/views/goodsManagement/index.vue'
import goodsCategoryManagement from '@/views/goodsManagement/goodsCategoryManagement/index.vue'
import additionalGoodsManagement from '@/views/additionalGoodsManagement/index.vue'
import additionalGoodsTabs from '@/views/additionalGoodsManagement/additionalGoodsTabs.vue'
import extensionManagement from '@/views/extensionManagement/index.vue'
import sharConfiguration from '@/views/sharConfiguration/index.vue'
import sharConfigurationTabs from '@/views/sharConfiguration/sharConfigurationTabs.vue'
import statisticalAnalysis from '@/views/statisticalAnalysis/index.vue'
import Logs from '@/views/logs/index.vue'
import customerManagement from '@/views/customerManagement/index.vue'
import mechanismCustomerManagement from '@/views/mechanismCustomerManagement/index.vue'
import memberManagement from '@/views/memberManagement/index.vue'
import couponManagement from '@/views/memberManagement/couponManagement/index.vue'
import memberDetails from '@/views/memberManagement/memberDetails.vue'
import updateMemberDetails from '@/views/memberManagement/updateMemberDetails.vue'
import addShop from '@/views/shopManagement/addShop.vue'
import detailTabs from '@/views/customerManagement/detailTabs.vue'
import mechanismDetailTabs from '@/views/mechanismCustomerManagement/detailTab.vue'
import addStaff from '@/views/userManagement/addStaff.vue'
import carTabs from '@/views/carManagement/carTabs.vue'
import serviceDetails from '@/views/goodsManagement/serviceDetails.vue'
import ServiceDetailedDetails from '@/views/goodsManagement/ServiceDetailedDetails.vue'
import serviceMemberGrade from '@/views/goodsManagement/serviceMemberGrade.vue'
import serviceTabs from '@/views/goodsManagement/serviceTabs.vue'
import updateServiceDetailedDetails from '@/views/goodsManagement/updateServiceDetailedDetails.vue'
import updateServiceMemberGrade from '@/views/goodsManagement/updateServiceMemberGrade.vue'
import addCoupon from '@/views/memberManagement/couponManagement/addCoupon.vue'
import distributionRecord from '@/views/memberManagement/couponManagement/distributionRecord.vue'
import updateCoupon from '@/views/memberManagement/couponManagement/updateCoupon.vue'
import distributionCoupon from '@/views/memberManagement/couponManagement/distributionCoupon.vue'
import integralConfigureManagement from '@/views/memberManagement/integralConfigureManagement/index.vue'
import addIntegralConfigure from '@/views/memberManagement/integralConfigureManagement/addIntegralConfigure.vue'
import integralConfigureDetails from '@/views/memberManagement/integralConfigureManagement/integralConfigureDetails.vue'
import testWebSocket from '@/views/memberManagement/test-webSocket.vue'


import {
  ElementUI
} from 'element-ui'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Login',
  component: Login
},
{
  path: '/home',
  name: 'Home',
  component: () => import( /* webpackChunkName: "about" */ '../views/home/Home.vue'),
  children: [
    {
      path: 'shopUsers',
      name: 'shopUsers',
      component: shopUsers
    },
    {
      path: 'achievements',
      name: 'achievements',
      component: achievements,
    },
    {
      path: 'systemUsers',
      name: 'systemUsers',
      component: systemUsers
    },
    {
      path: 'addStaff',
      name: 'addStaff',
      component: addStaff
    },
    {
      path: 'carTabs',
      name: 'carTabs',
      component: carTabs,
      children: [
        {
          path: 'editCarCat',
          name: 'editCarCat',
          component: () => import( /* webpackChunkName: "about" */ '../views/carManagement/editCarCat.vue'),
        },
        {
          path: 'carConsumption',
          name: 'carConsumption',
          component: () => import( /* webpackChunkName: "about" */ '../views/carManagement/carConsumption.vue'),
        },
      ]
    },
    {
      path: 'shopManagement',
      name: 'shopManagement',
      component: shopManagement,
    },
    {
      path: 'addShop',
      name: 'addShop',
      component: addShop,
      children: [
        {
          path: 'shopBasicInfo',
          name: 'shopBasicInfo',
          component: () => import( /* webpackChunkName: "about" */ '../views/shopManagement/shopBasicInfo.vue'),
        },
        {
          path: 'siteManagement',
          name: 'siteManagement',
          component: () => import( /* webpackChunkName: "about" */ '../views/shopManagement/siteManagement.vue'),
        },
        {
          path: 'staffInfo',
          name: 'staffInfo',
          component: () => import( /* webpackChunkName: "about" */ '../views/shopManagement/staffInfo.vue'),
        },
        {
          path: 'usageRecord',
          name: 'usageRecord',
          component: () => import( /* webpackChunkName: "about" */ '../views/shopManagement/usageRecord.vue'),
        },
      ]
    },
    // 个人客户管理
    {
      path: 'customerManagement',
      name: 'customerManagement',
      component: customerManagement
    },
    // 个人客户详情
    {
      path: 'detailTabs',
      name: 'detailTabs',
      component: detailTabs,
      children: [
        {
          path: 'customerDetail',
          name: 'customerDetail',
          component: () => import( /* webpackChunkName: "about" */ '../views/customerManagement/customerDetail.vue'),
        },
        {
          path: 'address',
          name: 'address',
          component: () => import( /* webpackChunkName: "about" */ '../views/customerManagement/address.vue'),
        },
        {
          path: 'myfriend',
          name: 'myfriend',
          component: () => import( /* webpackChunkName: "about" */ '../views/customerManagement/myfriend.vue'),
        },
        {
          path: 'orderRecords',
          name: 'orderRecords',
          component: () => import( /* webpackChunkName: "about" */ '../views/customerManagement/orderRecords.vue'),
        },
        {
          path: 'moneyRecords',
          name: 'moneyRecords',
          component: () => import( /* webpackChunkName: "about" */ '../views/customerManagement/moneyRecords.vue'),
        },
      ]
    },
    // 机构客户管理
    {
      path: 'mechanismCustomerManagement',
      name: 'mechanismCustomerManagement',
      component: mechanismCustomerManagement
    },
    // 机构客户详情
    {
      path: 'mechanismDetailTabs',
      name: 'mechanismDetailTabs',
      component: mechanismDetailTabs,
      children: [
        {
          path: 'mechanismDetail',
          name: 'mechanismDetail',
          component: () => import( /* webpackChunkName: "about" */ '../views/mechanismCustomerManagement/customerDetail.vue'),
        },
        {
          path: 'mechanismAddress',
          name: 'mechanismAddress',
          component: () => import( /* webpackChunkName: "about" */ '../views/mechanismCustomerManagement/address.vue'),
        },
        {
          path: 'mechanismMyfriend',
          name: 'mechanismMyfriend',
          component: () => import( /* webpackChunkName: "about" */ '../views/mechanismCustomerManagement/myfriend.vue'),
        },
        {
          path: 'mechanismOrderRecords',
          name: 'mechanismOrderRecords',
          component: () => import( /* webpackChunkName: "about" */ '../views/mechanismCustomerManagement/orderRecords.vue'),
        },
        {
          path: 'mechanismMoneyRecords',
          name: 'mechanismMoneyRecords',
          component: () => import( /* webpackChunkName: "about" */ '../views/mechanismCustomerManagement/moneyRecords.vue'),
        },
      ]
    },
    {
      path: 'carClassification',
      name: 'carClassification',
      component: carClassification
    },
    {
      path: 'carCategary',
      name: 'carCategary',
      component: carCategary
    },
    {
      path: 'orderManagement',
      name: 'orderManagement',
      component: orderManagement
    }
    ,
    {
      path: 'orderTabs',
      name: 'orderTabs',
      component: orderTabs,
      children: [
        {
          path: 'orderDetails',
          name: 'orderDetails',
          component: () => import( /* webpackChunkName: "about" */ '../views/orderManagement/orderDetails.vue'),
        },
        {
          path: 'orderLogs',
          name: 'orderLogs',
          component: () => import( /* webpackChunkName: "about" */ '../views/orderManagement/orderLogs.vue'),
        },
      ]
    }
    ,
    {
      path: 'evaluationManagement',
      name: 'evaluationManagement',
      component: evaluationManagement
    },

    {
      path: 'serviceDetails',
      name: 'serviceDetails',
      component: serviceDetails,
    },
    {
      path: 'ServiceDetailedDetails',
      name: 'ServiceDetailedDetails',
      component: ServiceDetailedDetails,
    },
    {
      path: 'serviceMemberGrade',
      name: 'serviceMemberGrade',
      component: serviceMemberGrade,
    },
    {
      path: 'serviceTabs',
      name: 'serviceTabs',
      component: serviceTabs,
      children: [
        {
          path: 'updateServiceDetails',
          name: 'updateServiceDetails',
          component: () => import( /* webpackChunkName: "about" */ '../views/goodsManagement/updateServiceDetails.vue'),
        },
        {
          path: 'serviceImg',
          name: 'serviceImg',
          component: () => import( /* webpackChunkName: "about" */ '../views/goodsManagement/serviceImg.vue'),
        },
      ]
    },
    {
      path: 'updateServiceDetailedDetails',
      name: 'updateServiceDetailedDetails',
      component: updateServiceDetailedDetails,
    },
    {
      path: 'updateServiceMemberGrade',
      name: 'updateServiceMemberGrade',
      component: updateServiceMemberGrade,
    },
    {
      path: 'goodsManagement',
      name: 'goodsManagement',
      component: goodsManagement,
      children: [
        {
          path: 'goodsServiceDetails',
          name: 'goodsServiceDetails',
          component: () => import( /* webpackChunkName: "about" */ '../views/goodsManagement/serviceDetails.vue'),
        },
        {
          path: 'serviceParameters',
          name: 'serviceParameters',
          component: () => import( /* webpackChunkName: "about" */ '../views/goodsManagement/serviceParameters.vue'),
        },
      ]
    },
    {
      path: 'goodsCategoryManagement',
      name: 'goodsCategoryManagement',
      component: goodsCategoryManagement,
    },
    // 附加商品管理
    {
      path: 'additionalGoodsManagement',
      name: 'additionalGoodsManagement',
      component: additionalGoodsManagement,
    },
    // 新增附加商品
    {
      path: 'additionalGoodsTabs',
      name: 'additionalGoodsTabs',
      component: additionalGoodsTabs,
      children: [
        {
          path: 'additionalGoods',
          name: 'additionalGoods',
          component: () => import( /* webpackChunkName: "about" */ '../views/additionalGoodsManagement/additionalGoods.vue'),
        },
        {
          path: 'additionalGoodsRecord',
          name: 'additionalGoodsRecord',
          component: () => import( /* webpackChunkName: "about" */ '../views/additionalGoodsManagement/additionalGoodsRecord.vue'),
        },
      ]
    },
    {
      path: 'extensionManagement',
      name: 'extensionManagement',
      component: extensionManagement,
    },
    // 分享配置管理
    {
      path: 'sharConfiguration',
      name: 'sharConfiguration',
      component: sharConfiguration,
    },
    // 新增分享配置
    {
      path: 'sharConfigurationTabs',
      name: 'sharConfigurationTabs',
      component: sharConfigurationTabs,
      children: [
        {
          path: 'sharConfigurationDatil',
          name: 'sharConfigurationDatil',
          component: () => import( /* webpackChunkName: "about" */ '../views/sharConfiguration/sharConfiguration.vue'),
        },
        {
          path: 'sharConfigurationRecord',
          name: 'sharConfigurationRecord',
          component: () => import( /* webpackChunkName: "about" */ '../views/sharConfiguration/sharConfigurationRecord.vue'),
        },
      ]
    },


    {
      path: 'statisticalAnalysis',
      name: 'statisticalAnalysis',
      component: statisticalAnalysis
    },
    {
      path: 'logs',
      name: 'logs',
      component: Logs
    },
    {
      path: 'memberManagement',
      name: 'memberManagement',
      component: memberManagement
    },
    {
      path: 'couponManagement',
      name: 'couponManagement',
      component: couponManagement
    }, {
      path: 'integralConfigureManagement',
      name: 'integralConfigureManagement',
      component: integralConfigureManagement
    }, {
      path: 'addIntegralConfigure',
      name: 'addIntegralConfigure',
      component: addIntegralConfigure
    }, {
      path: 'integralConfigureDetails',
      name: 'integralConfigureDetails',
      component: integralConfigureDetails
    },
    // todo 测试webSocket
    // {
    //   path: 'testWebSocket',
    //   name: 'testWebSocket',
    //   component: testWebSocket
    // },
    {
      path: 'memberDetails',
      name: 'memberDetails',
      component: memberDetails
    }, {
      path: 'updateMemberDetails',
      name: 'updateMemberDetails',
      component: updateMemberDetails
    }, {
      path: 'addCoupon',
      name: 'addCoupon',
      component: addCoupon
    }, {
      path: 'updateCoupon',
      name: 'updateCoupon',
      component: updateCoupon
    }, {
      path: 'distributionCoupon',
      name: 'distributionCoupon',
      component: distributionCoupon
    }, {
      path: 'distributionRecord',
      name: 'distributionRecord',
      component: distributionRecord
    }]
},

]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to and from are Route Object,next() must be called to resolve the hook}
  // to将要访问的路径 from代表从哪个路径跳转而来
  // next 是一个函数，表示放行 next() 放行 next('/login') 强制跳转到登录页面
  // 如果是登录页，直接放行即可
  if (to.path === '/') return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return next('/')
  next()
})

// 解决el重复点击菜单路由报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default router