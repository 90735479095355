<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
body {
  height: 100%;
  width: 100%;
  background-color: #f0f2f6;
  padding: 0;
  margin: 0;
  font-size: 14px;

  .el-table th.gutter {
    display: table-cell !important;
  }

  .el-table .el-table__body .el-table__cell {
    height: 32px !important;
    padding: 0px !important;
    line-height: 32px !important;
    font-size: 14px;
  }

  .el-table th.el-table__cell {
    height: 35px !important;
    padding: 0px !important;
    line-height: 35px !important;
    font-size: 14px;
  }
  // 分页器页码颜色
  .el-pager li.active {
    color: #e3620b;
  }
  // 取消数字输入框后的上下减
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }

  ::v-deep input[type="number"] {
    -moz-appearance: textfield !important;
  }
  // 设置模态框的样式

  .el-dialog__body {
    // padding: 0 !important;
    padding: 0 40px !important;
  }

  .el-dialog__header,
  .el-drawer__header,
  .el-dialog__title {
    text-align: center;
    font-size: 16px !important;
  }

  .el-dialog__footer {
    display: flex;
    justify-content: center;

    .el-button {
      margin: 0 20px;
    }
  }

  // 设置模态框的样式
  .el-dialog__header {
    text-align: center;
    background-color: white;
    z-index: 2;
    position: sticky;
    top: 0;
  }

  .el-dialog__footer {
    text-align: center;
    background-color: white;
    z-index: 2;
    position: sticky;
    bottom: 0;
  }

  /* // 修改滚动条样式 */
  // ::-webkit-scrollbar {
  //   // display: none;
  //   // width: 10px;
  //   /*高宽分别对应横竖滚动条的尺寸 */
  //   // max-height: 10px;
  //   // background-color: #dde;
  // }

  // /* 滚动槽的轨道 */
  // ::-webkit-scrollbar-track {
  //   border-radius: 30px;
  // }

  /* 滚动条的小滑块 */
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 3px;
  //   // background: #5B87A3;
  //   /* background: linear-gradient(180deg, #1160cf 0%, #cd06bf 100%); */
  // }
  // 设置表单样式
  .el-form-item {
    // margin-bottom: 12px !important;

    &:last-child {
      margin-bottom: 20px !important;
    }
  }
}

// 设置抽屉的样式
.demo-drawer__footer {
  display: flex;
  justify-content: center;
}

.el-drawer__header {
  text-align: center;
}

.demo-drawer__content {
  padding: 0 40px;
}

.el-switch__core:after {
  transition: none !important;
}
.el-pagination {
  span {
    font-size: 14px !important;
  }
}
// 设置按钮区域的样式
.searchBtn {
  display: flex;
  .el-button {
    margin-left: 20px !important;
  }

  .el-input,
  .el-select,
  .el-cascader {
    width: 150px;
    margin-right: 30px;
  }
}
</style>