<template>
  <!-- 新增服务项目会员价格 -->
  <div class="ServiceDetailedDetails">
    <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item>新增服务项目会员价格</el-menu-item>
    </el-menu>
    <!-- <el-tag style="font-size: 15px">新增服务项目会员价格:</el-tag> -->
    <!-- <el-tag style="margin-left: 320px; font-size: 15px"
    >原价:{{ this.price }}
    </el-tag
    > -->
    <div class="shopDetail">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item>
          <div class="titleBox">
            <div class="div1Box">会员等级</div>
            <div>价格</div>
            <div>优惠价格</div>
            <div>折扣</div>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="addSiteServiceDetailed"
              >添加
            </el-button>
          </div>

          <div v-if="this.formData.serviceMemberGrade.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.formData.serviceMemberGrade"
              :key="index"
            >
              <div class="div1Box div1Box2">
                <el-select
                  v-model="item.memberId"
                  placeholder="请选择"
                  @change="cancelInput"
                  style="width: 100%; border-top: none; border-inline: none"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="div1Box2">
                <el-input
                  v-model="item.price"
                  @input="change(item)"
                  placeholder="请输入项目会员价格"
                  :disabled="disabled"
                  type="number"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2">
                <el-input
                  v-model="item.discountPrice"
                  :disabled="true"
                  placeholder="输入项目会员价自动生成优惠价格"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2 div1Box5">
                <el-input
                  v-model="item.discount"
                  :disabled="true"
                  placeholder="请选择会员等级自动生成折扣率"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
                <div
                  class="delBox"
                  @click="delBtn(item)"
                  v-show="item.memberType === '原价' ? false : true"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item style="margin-left: 750px">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="commit">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  addServiceDetailed,
  selectById,
  selectPrice,
  getMemberName,
  addServiceMember,
  updateServiceMemberGrade
} from '@/api/goodsManagement/goodsManagement.js'

export default {
  name: 'ServiceDetailedDetails',
  components: {},
  props: {},
  data() {
    return {
      list: [],
      price: '',
      options: [],
      options1: [],
      serviceProjectId: '',
      memberId: '',
      disabled: true,
      number: 1,
      formData: {
        serviceMemberGrade: []
      }
    }
  },
  computed: {},
  watch: {},
  mounted() {

  },
  async created() {
    this.getMemberName()
    this.serviceProjectId = sessionStorage.getItem("id")
    // console.log(this.serviceProjectId)
    const res = await selectById(this.serviceProjectId)
    // console.log("djksfjslk", res.status)
    if (res.status === 200) {
      this.price = res.data.price
    }
    this.formData.serviceMemberGrade.push(
      {
        number: this.number,
        serviceProjectId: this.serviceProjectId,
        memberId: '',
        price: res.data.price,
        discountPrice: '',
      }
    )

  },
  methods: {
    // 取消项目价格的输入
    cancelInput() {
      this.disabled = false
    },
    //改变事件
    async change(item) {
      // console.log("dada-->" + item)
      const res = await selectById(item.serviceProjectId)
      if (res.status === 200) {
        // 服务项目原价
        const originalPrice = Number(res.data.price)
        // 服务项目的会员价
        const price = Number(item.price)

        if (price >= originalPrice) {
          this.$message({
            message: "会员价大于等于了原价",
            type: 'error'
          });
          return
        }
        // 优惠的价格
        const discountPrice = originalPrice - price;
        // 优惠率
        const discount = (originalPrice - discountPrice) / originalPrice
        // console.log("折扣率未四舍五入===>" + discount);
        item.discount = Number(discount.toFixed(2))
        item.discountPrice = discountPrice
        // console.log("折扣率===>" + item.discount);
        this.list.push(item.memberId)
        this.$forceUpdate()


        // const favorablePrice = res.data.favorablePrice
        // const price = res.data.price
        // console.log("获取的数据====》" + favorablePrice)
        // console.log("获取的数据====》" + price)
        // if(price<=favorablePrice){
        //   item.price=0.01;
        //   item.discount =0;
        //   this.list.push(item.memberId)
        //   return
        // }
        // item.price = price - favorablePrice
        // // 加优惠率
        // const discount = item.price / price
        // console.log("折扣率未四舍五入===>" + discount);
        // item.discount = discount.toFixed(2)
        // console.log("折扣率===>" + item.discount);
        // this.list.push(item.memberId)
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 获取会员列表
    async getMemberName() {
      const res = await getMemberName()
      if (res.status === 200) {
        this.options = res.data
      }
    },
    // 取消
    cancel() {
      this.$router.push('/home/goodsManagement');
    },
    // 删除
    delBtn(val) {
      // console.log(val);
      this.$confirm('此操作将永久删除该项服务项目清单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        for (let i = 0; i < this.formData.serviceMemberGrade.length; i++) {
          if (this.formData.serviceMemberGrade[i].number === val.number) {
            this.formData.serviceMemberGrade.splice(i, 1);
            this.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //确认
    async commit() {
      if (this.formData.serviceMemberGrade.length === 0 || this.formData.serviceMemberGrade[0] === null || this.formData.serviceMemberGrade[0].memberId === '') {
        this.$message({
          message: '请全部填写完!',
          type: 'error'
        });
      } else {
        for (let item of this.formData.serviceMemberGrade) {
          if (item.memberId === '') {
            return this.$message({
              showClose: true,
              message: '请将资料填写完整！',
              type: 'warning'
            });
          }
          if (item.price === '') {
            return this.$message({
              showClose: true,
              message: '请将资料填写完整！',
              type: 'warning'
            });
          }
        }
        // console.log(this.formData.serviceMemberGrade)
        const res = await addServiceMember(this.formData.serviceMemberGrade)
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.$router.push('/home/goodsManagement');
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }
    },
    addSiteServiceDetailed() {
      this.number = this.number += 1;
      this.formData.serviceMemberGrade.push(
        {
          number: this.number,
          serviceProjectId: this.serviceProjectId,
          memberId: '',
          price: this.price,
          discountPrice: '',
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.ServiceDetailedDetails {
  padding: 0px 30px;
  box-sizing: border-box;

  .shopDetail {
    width: 420px;
    margin-top: 40px;

    .el-myselect {
      width: 100%;
    }

    .el-mytime {
      width: 108px;
    }

    .carid {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;

      span {
        cursor: pointer;
        margin: 0 8px;
      }
    }

    .titleBox {
      position: relative;
    }

    .titleBox,
    .contentBox {
      display: flex;

      div {
        flex: 1;
        // width: 260px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;

        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      .div1Box {
        border-right: none;
      }

      .div1Box2 {
        border-top: none;
      }

      .div1Box5 {
        position: relative;

        .delBox {
          cursor: pointer;
          position: absolute;
          top: 6px;
          right: 10px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 12px;
          background-color: #ddd;
          padding: 0;
        }
      }

      .btnBox {
        position: absolute;
        top: 0;
        right: -80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }

    .contentBox1 {
      // width: 520px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      border-top: none;
    }
  }

  width: 100%;
  min-height: 300px;
  padding: 20px 30px;

  ::v-deep.el-form-item__content {
    margin-left: 80px !important;
  }

  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }

  .shopDetail {
    width: 1000px;
    padding-top: 20px;

    .el-myselect {
      width: 100%;
    }

    .el-mytime {
      width: 108px;
    }

    .carid {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;

      span {
        cursor: pointer;
        margin: 0 8px;
      }
    }

    .titleBox {
      position: relative;
    }

    .titleBox,
    .contentBox {
      display: flex;

      div {
        flex: 1;
        // width: 260px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;

        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      .div1Box {
        border-right: none;
      }

      .div1Box2 {
        border-top: none;
      }

      .div1Box5 {
        position: relative;

        .delBox {
          cursor: pointer;
          position: absolute;
          top: 6px;
          right: 10px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 12px;
          background-color: #ddd;
          padding: 0;
        }
      }

      .btnBox {
        position: absolute;
        top: 0px;
        right: -80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }

    .contentBox1 {
      // width: 520px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      border-top: none;
    }
  }
}
</style>
