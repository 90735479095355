import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import aMap from 'vue-amap'   // 这个引入的最好放在引入的vue上面
import Axios from "axios"					//axios接口引用
Vue.prototype.$axios = Axios



Vue.use(aMap)
aMap.initAMapApiLoader({
  key: '0c6af0b1fe93e48f79e04b581766a263',  // 你的key
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.Geolocation', 'AMap.Geocoder', 'AMap.AMapManager', 'AMap.Marker'],//应用功能项
  v: '1.4.15', //版本
  uiVersion: '1.0' //ui版本
})

const Base64 = require('js-base64').Base64
// // 导入flexible
// import 'amfe-flexible'
// // import 'px2rem'
// import './utils/flexible'
// import './utils/px2rem'


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 修改storage值 跳转登录页
window.addEventListener('popstate', function (e) {
  router.isBack = true;
}, false);
window.addEventListener('storage', function (e) {
  sessionStorage.setItem(e.key, e.oldValue);
});
window.addEventListener('storage', function () {
  sessionStorage.clear();
  window.location.replace('/');
});
