<template>
  <div class="login-container">
    <el-form class="formbox" :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item>
        <h3>乐电车美后台管理系统</h3>
      </el-form-item>
      <el-form-item prop="account">
        <el-input
          class="userinput"
          v-model="ruleForm.account"
          prefix-icon="el-icon-user"
          placeholder="请输入电话号码"
          maxlength="12"
        ></el-input>
      </el-form-item>
      <el-form-item prop="pwd">
        <el-input
          class="userinput"
          v-model="ruleForm.pwd"
          prefix-icon="el-icon-unlock"
          placeholder="请输入密码"
          show-password
          maxlength="16"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="primary"
          style="width: 100%; height: 50px; margin-top: 30px"
          @click="handleLogin"
          >登录</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login } from '@/api/login'
export default {
  name: 'Login',
  data() {
    return {
      ruleForm: {
        account: '',
        pwd: ''
      },
      loading: false,
      rules: {
        account: [
          { required: true, message: '请输入电话号码', trigger: 'blur' }
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {

  },
  methods: {
    // 登录请求
    async handleLogin() {
      const Base64 = require('js-base64').Base64;
      const msg = Base64.encode(JSON.stringify(this.ruleForm))
      // console.log('1', msg);
      const res = await login({ msg: msg, type: 1 })
      // console.log('1', res);
      if (res.status === 200) {
        sessionStorage.setItem('token', res.data.token)
        sessionStorage.setItem('userLoginfo', JSON.stringify(res.data))
        sessionStorage.setItem('menuList', JSON.stringify(res.data.menuList))
        this.$message({
          showClose: true,
          message: '登录成功！',
          type: 'success'
        });
        setTimeout(() => {
          this.$router.push('/home/shopManagement')
        }, 500);
      } else {
        this.$message({
          showClose: true,
          message: '登录失败！' + res.msg,
          type: 'error'
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.formbox .el-input__inner {
  padding-bottom: 20px;
  padding-top: 20px;
  height: 50px;
  line-height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}

.login-container {
  width: 100%;
  height: 100vh;
  background-color: #283443;
  position: relative;

  .formbox {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -250px;
    margin-left: -250px;
    padding: 20px;

    h3 {
      color: #eee;
      font-size: 26px;
      text-align: center;
      margin-bottom: 20px;
    }

    .userinput {
      margin-top: 20px;
    }
  }
}
</style>
