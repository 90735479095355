import request from '@/utils/request'
import qs from 'qs';

// 查看会员列表
export function showList(params) {
  return request({
    url: 'member/list',
    method: 'get',
    params
  });
}
// 新增会员
export function add(data) {
  return request({
    url: 'member',
    method: 'post',
    data
  });
}
// 根据会员id获取会员对象
export function getById(id) {
  return request({
    url: 'member/' + id,
    method: 'get'
  });
}
// 编辑会员
export function update(data) {
  return request({
    url: 'member',
    method: 'put',
    data
  });
}
// 删除会员
export function remove(id) {
  return request({
    url: 'member/' + id,
    method: 'delete'
  });
}

// 派发优惠卷客户列表
export function getCouponList(params) {
  return request({
    url: 'discount/distribution/discount/list',
    method: 'get',
    params
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}

// 派发优惠卷客户
export function distributionCoupon(data) {
  return request({
    url: 'discount/distribution/discount',
    method: 'post',
    data
  });
}

// 会员列表
export function memberList() {
  return request({
    url: 'service-project/member/name',
    method: 'get'
  });
}

// 查看优惠券派发记录列表
export function distributionRecordList(params) {
  return request({
    url: 'discount/distribution/record/list',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}
