<template>
  <!-- 评价管理 -->
  <div class="evaluationManagement">
    <div class="searchBar">
      <!--      <el-select placeholder="请选择店铺" />-->

      <el-input placeholder="姓名/订单号" clearable v-model="page.name" />
      <button @click="search">查询</button>
    </div>
    <div class="staffTable">
      <!-- <el-table
        :data="tableData"
        style="width: 100%"
        @expand-change="(row, expandRows) => clickTable(row, expandRows)"
      >
        <el-table-column type="expand">
          <template slot-scope="props"> -->
      <!-- <el-table :data="props.row.serviceStaffData" style="width: 100%"> -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column label="客户编号" prop="customerId" width="100"> </el-table-column>
        <el-table-column label="客户名称" prop="customerName" width="130">
          <template slot-scope="scope">
            <div class="mycell">
              {{scope.row.customerName}}
              <span v-if="scope.row.gender === 1" style="margin-left: 10px;">先生</span>
              <span v-if="scope.row.gender === 2" style="margin-left: 10px;">女士</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="手机号" prop="telephone" width="120"> </el-table-column>
        <el-table-column label="是否是vip" prop="isVip" width="100">
          <template slot-scope="props">
            <span v-if="props.row.isVip === 1">{{ props.row.memberName }}</span>
            <span v-else>{{ "否" }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column label="评价编号" prop="id"> </el-table-column> -->
        <el-table-column label="订单编号" prop="orderNumber" width="170"> </el-table-column>
        <el-table-column label="店铺名称" prop="shopName"> </el-table-column>
        <!-- <el-table-column label="服务人员名称" prop="serviceName">
        </el-table-column> -->
        <el-table-column label="星级" prop="level" width="100"> </el-table-column>
        <el-table-column label="内容" prop="content"> </el-table-column>
        <el-table-column label="评价时间" prop="createTime" width="170"> </el-table-column>
      </el-table>

      <!--服务员工分页-->
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="currentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
      <!-- </template>
        </el-table-column> -->

      <!-- <el-table-column label="客户编号" prop="customerId"> </el-table-column>
        <el-table-column label="客户名称" prop="customerName">
        </el-table-column>
        <el-table-column label="是否是vip" prop="isVip">
          <template slot-scope="props">
            <span v-if="props.row.isVip === 1">{{ "是" }}</span>
            <span v-else>{{ "是" }}</span>
          </template>
        </el-table-column>
        <el-table-column label="评论总数" prop="evaluateCount">
        </el-table-column>
      </el-table> -->

      <!--      客户列表分页-->
      <!-- <div class="block">
        <el-pagination
          v-model="page"
          @current-change="currentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>

import { getEvaluateCustomerList, getEvaluateCustomerListById } from "@/api/evaluationManagement/evaluationManagement.js"

export default {
  name: "evaluationManagement",
  data() {
    return {
      // serviceStaffShow: false,
      total: 0,
      page: {
        page: 1,
        size: 10,
        name: '',
        shopId: null,
        customerId: null
      },
      // serviceStaffTotal: 0,
      // serviceStaffPage: {
      //   page: 1,
      //   size: 2,
      //   name: '',
      //   shopId: '',
      //   customerId: ''
      // },
      tableData: [],
    }

  },
  created() {
    this.getEvaluateCustomerList();
  },
  methods: {
    // 客户列表分页
    currentChange(currentPage) {
      this.page.page = currentPage;
      // console.log("size==>" + this.page.page)
      this.getEvaluateCustomerList();
    },
    // 查询
    search() {
      this.page.page = 1;
      this.getEvaluateCustomerList();
    },
    // 服务员工分页
    // serviceStaffCurrentChange(currentPage) {
    //   this.serviceStaffPage.page = currentPage;
    //   console.log("size==>" + this.serviceStaffPage.page)
    // },
    // 表格展开
    // async clickTable(row, expandRows) {
    //   if (expandRows.length > 0) {
    //     console.log("展开")
    //     const customerId = row.customerId;
    //     console.log("客户id==>" + customerId)
    //     this.serviceStaffPage.customerId = customerId
    //     const res = await getEvaluateCustomerList(this.serviceStaffPage)
    //     console.log("表格行数据===>" + this.serviceStaffPage.customerId)
    //     if (res.status === 200) {
    //       // 遍历原列表,将获取的数据添加
    //       this.tableData.forEach((item, index) => {
    //         if (item.customerId === customerId) {
    //           this.tableData[index].serviceStaffData = res.data.rows;
    //         }
    //       })
    //       // 判断分页是否显示
    //       this.serviceStaffTotal = res.data.total;
    //       if (res.data.total > 1) {
    //         this.serviceStaffShow = true;
    //       }
    //     } else {
    //       this.$message({
    //         showClose: true,
    //         message: res.msg,
    //         type: 'error'
    //       });
    //     }
    //   }
    // },
    // 评论客户列表
    async getEvaluateCustomerList() {
      const res = await getEvaluateCustomerList(this.page)
      if (res.status === 200) {
        // 给每行添加属性
        // res.data.rows.map(item => {
        //   item.serviceStaffData = [];
        // })
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.evaluationManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    width: 100%;
    min-height: 300px;

    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}

.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>