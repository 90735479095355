import request from '@/utils/request'
import qs from 'qs';

// 订单列表及分页显示
export function getOrderList(data) {
  return request({
    url: '/order/selectOrderByStatus',
    method: 'POST',
    data
  })
}

// 获取所有附加商品
export function getAdditionalGoods(params) {
  return request({
    url: 'additional/goods/all',
    method: 'get',
    params
  });
}

// 获取订单附加商品
export function getOrderAdditionalGoods(params) {
  return request({
    url: 'order/find/order/shop/additionalGoods',
    method: 'get',
    params
  });
}

// 新增附加商品
export function addOrderAdditionalGoods(data) {
  return request({
    url: 'order/order/shop/additionalGoods',
    method: 'post',
    data
  });
}

// 更新附加商品
export function editOrderAdditionalGoods(data) {
  return request({
    url: 'order/modify/order/shop/additionalGoods',
    method: 'put',
    data
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}
// 删除订单
export function delOrder(params) {
  return request({
    url: '/order/deleteOrder',
    method: 'get',
    params: params
  })
}
// 订单导出excel
export function importOrderExcel(params) {
  return request({
    url: '/order/import/excel',
    method: 'get',
    params,
    responseType: 'blob'
  })
}
// 服务项目列表
export function getServiceList(params) {
  return request({
    url: '/service-project/all',
    method: 'get',
    params: params
  })
}
// 获取订单详情
export function getOrderBasicInfo(params) {
  return request({
    url: '/order/selectOrder',
    method: 'get',
    params: params
  })
}
// 查询该订单所有流程
export function getSelectOrderProcess(params) {
  return request({
    url: '/order/selectOrderProcess',
    method: 'get',
    params: params
  })
}
// 编辑订单
export function editOrder(data) {
  return request({
    url: '/order/updateOrder',
    method: 'post',
    data
  })
}

// 编辑订单状态
export function updateOrderStatus(data) {
  return request({
    url: '/order/updateOrderStatus',
    method: 'post',
    data
  })
}
// 店铺接单，工位分配
export function carToTheStore(data) {
  return request({
    url: '/order/carToTheStore',
    method: 'put',
    data
  })
}
// 暂时结束订单标识
export function orderTemporaryEnd(data) {
  return request({
    url: 'order/temporary/end',
    method: 'put',
    data
  })
}
// 3店铺接单
export function shopMeetOrder(orderId) {
  return request({
    url: '/order/shopMeetOrder/' + orderId,
    method: 'put'
  })
}
// 4店铺接单:工位分配、取钥匙员工分配、洗车分配
export function shopStaffAllocation(data) {
  return request({
    url: '/order/shopStaffAllocation',
    method: 'put',
    data
  })
}
// 修改订单状态为16等待到店取车
export function updateOrderStatusSixteen(orderId) {
  return request({
    url: '/order/updateOrderStatusSixteen?orderId=' + orderId,
    method: 'get'
  })
}
// 6取钥匙人员到达现场
export function pickUpUserToTheStore(orderId) {
  return request({
    url: '/order/pickUpUserToTheStore/' + orderId,
    method: 'put'
  })
}
// 7取钥匙扫二维码
export function keyScanningCode(data) {
  return request({
    url: '/order/keyScanningCode',
    method: 'put',
    data
  })
}

// 车辆检查
export function inspect(data) {
  return request({
    url: '/order/inspect',
    method: 'put',
    data
  })
}

// 客户确认
export function customerConfirm(orderId) {
  return request({
    url: '/order/confirm/' + orderId,
    method: 'put'
  })
}
// 获取分配员工信息
export function getDistribution(orderId) {
  return request({
    url: '/order/get/distribution/' + orderId,
    method: 'get',
  })
}
// 修改工位-洗车人员
export function editDistribution(data) {
  return request({
    url: '/order/distribution',
    method: 'put',
    data
  })
}

// 修改订单状态为21客户确定到达
export function customerConfirmsArrival(orderId) {
  return request({
    url: '/order/updateOrderStatusTwentyOne?orderId=' + orderId,
    method: 'get'
  })
}

// 开工
export function start(orderId) {
  return request({
    url: '/order/start/' + orderId,
    method: 'put'
  })
}
// 完工
export function updateOrderStatusEleven(orderId) {
  return request({
    url: '/order/updateOrderStatusEleven?orderId=' + orderId,
    method: 'get',
  })
}

// 确认到达
export function updateOrderStatusFourteen(orderId) {
  return request({
    url: '/order/updateOrderStatusFourteen?orderId=' + orderId,
    method: 'get',
  })
}
// 取车扫码
export function updateOrderStatusSeventeen(data) {
  return request({
    url: '/order/updateOrderStatusSeventeen',
    method: 'post',
    data
  })
}
// 取车扫码
export function updateOrderStatusTwelve(data) {
  return request({
    url: '/order/updateOrderStatusTwelve',
    method: 'post',
    data
  })
}
// 修改订单状态为13停车位地址照片
export function updateOrderStatusThirteen(data) {
  return request({
    url: '/order/updateOrderStatusThirteen',
    method: 'post',
    data
  })
}// 修改订单状态为13停车位地址照片
export function confirmArrival(orderId) {
  return request({
    url: '/order/updateOrderStatusFourteen?orderId=' + orderId,
    method: 'get'
  })
}
// 修改订单状态为15送钥匙扫一扫
export function keyScanning(data) {
  return request({
    url: '/order/updateOrderStatusFifteen',
    method: 'post',
    data
  })
}
// 修改订单状态为18客户已开走
export function updateOrderStatusEighteen(orderId) {
  return request({
    url: '/order/updateOrderStatusEighteen?orderId=' + orderId,
    method: 'get'
  })
}
// 修改订单状态为19订单结束
export function updateOrderStatusNineteen(orderId) {
  return request({
    url: '/order/updateOrderStatusNineteen?orderId=' + orderId,
    method: 'get'
  })
}

// 根据店铺id查看空闲工位
export function getSelectByStationIdBystatus(params) {
  return request({
    url: '/shop-station/selectByStationIdBystatus',
    method: 'get',
    params: params
  })
}

// 根据店铺id查询员工信息
export function getSelectShopStaffByShopId(params) {
  return request({
    url: '/shop-staff/selectShopStaffByShopId',
    method: 'get',
    params: params
  })
}

// 根据文件id删除文件
export function deleteFile(params) {
  return request({
    url: '/file/delete',
    method: 'post',
    params
  })
}