<template>
  <!-- 机构客户管理 -->
  <div class="customerManagement">
    <div class="searchBar">
      <!-- <el-select
        v-model="page.data.isVip"
        placeholder="请选择机构管理员"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select> -->
      <el-input
        v-model="page.adminName"
        clearable
        placeholder="机构管理员名称"
      />
      <el-input v-model="page.name" clearable placeholder="机构名称" />
      <button @click="queryBtn">查询</button>
      <button @click="checkAdd">新增</button>
    </div>

    <div class="staffTable">
      <!-- <el-table
        :data="tableData"
        border
        @sort-change="sortChange"
        style="width: 100%"
      > -->
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="name" label="机构名称">
        </el-table-column>
        <el-table-column prop="mechanismAdminName" label="机构管理员" width="100">
          <template slot-scope="scope">
            <div class="mycell">
              <span>{{ scope.row.mechanismAdminName }}</span>
              <span v-if="scope.row.gender === 1" style="margin-left: 10px;">先生</span>
              <span v-if="scope.row.gender === 2" style="margin-left: 10px;">女士</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="carCount" label="机构车辆数" width="100"> </el-table-column>
        <el-table-column prop="memberName" label="机构关联会员"></el-table-column>
        <el-table-column prop="createTime" label="注册时间"> </el-table-column>
        <el-table-column prop="balance" label="机构余额" width="100">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="checkEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getMechanismList, deleteMechanism } from '@/api/mechanismCustomerManagement/mechanismCustomerManagement.js'
export default {
  name: 'mechanismCustomerManagement',
  data() {
    return {
      page: {
        adminName: '',
        name: "",
        time: [],
        page: 1,
        size: 10,
        start: 0
      },
      total: 0,
      // options: [
      //   {
      //     id: 1,
      //     label: '是'
      //   },
      //   {
      //     id: 2,
      //     label: '否'
      //   }
      // ],
      //表格数据
      tableData: []
    }
  },
  created() {
    this.getMechanismList();
  },
  methods: {
    // 查看客户列表分页
    async getMechanismList() {
      const res = await getMechanismList(this.page);
      console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 查询
    queryBtn() {
      this.page.page = 1;
      this.getMechanismList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getMechanismList();
    },
    // 排序 {column,prop,order} 列数据|排序字段|排序方式
    // sortChange(column) {
    //   // console.log(column);
    //   this.page.level = null;
    //   if (column.order === null) {
    //     this.page.level = null;
    //   }
    //   if (column.prop === 'moneySum' && column.order === 'ascending') {
    //     this.page.level = 0;
    //   }
    //   if (column.prop === 'moneySum' && column.order === 'descending') {
    //     this.page.level = 1;
    //   }
    //   this.getMechanismList();
    // },
    // 删除用户
    del(val) {
      // console.log('删除用户', val);
      this.$confirm('确认删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteMechanism(parseInt(val.id));
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getMechanismList();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新增用户信息跳转
    checkAdd() {
      sessionStorage.setItem('checkEdit', 'add');
      sessionStorage.setItem('mechanismId', null);
      this.$router.push('/home/mechanismDetailTabs/customerDetail');
    },
    // 编辑用户信息跳转
    checkEdit(val) {
      // console.log('查看用户信息', val);
      sessionStorage.setItem('checkEdit', 'edit');
      sessionStorage.setItem('mechanismId', val.id);
      this.$router.push('/home/mechanismDetailTabs/customerDetail');
    },
  }
}
</script>

<style lang='scss' scoped>
.customerManagement {
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .staffTable {
    width: 100%;
    min-height: 300px;
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
.mycell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  .vipIcon {
    width: 19px;
    height: 16px;
    vertical-align: sub;
    margin-right: 3px;
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>