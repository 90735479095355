<template>
  <!-- 配置管理 -->
  <div class="sharConfiguration">
    <div class="searchBar">
      <el-select
        v-model="page.type"
        placeholder="请选择类型"
        clearable
        :style="{ width: '30%' }"
      >
        <el-option
          v-for="(item, index) in typeOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
        ></el-option>
      </el-select>
      <button @click="queryBtn">查询</button>
      <button @click="addBtn">新增</button>
    </div>
    <div class="userTable">
      <el-table :data="additionalGoodsList" border style="width: 100%">
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="title" label="标题"> </el-table-column>
        <el-table-column prop="depict" label="描述"> </el-table-column>
        <el-table-column prop="type" label="类型" width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.type === 1">分享人</div>
            <div v-if="scope.row.type === 2">被分享人</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="jobNo" label="库存"> </el-table-column> -->
        <el-table-column prop="discountName" label="现金券名称"> </el-table-column>
        <el-table-column prop="denomination" label="优惠金额" width="80"> </el-table-column>
        <!-- <el-table-column prop="discountType" label="优惠券类型" width="100"> </el-table-column> -->
        <el-table-column prop="userName" label="操作人名称" width="100"> </el-table-column>
        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="editBtn(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteExtensionConfigure(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getExtensionConfigureList, deleteExtensionConfigure } from '@/api/sharConfiguration/index.js'
export default {
  name: 'sharConfiguration',
  data() {
    return {
      additionalGoodsList: [], // 附加商品列表数据
      page: {
        page: 1,
        size: 12,
        type: null
      }, // 附加商品请求数据
      total: 0,
      typeOptions: [{
        "label": "分享人",
        "value": 1
      }, {
        "label": "被分享人",
        "value": 2
      }],
    }
  },
  created() {
    this.getExtensionConfigureList();
  },
  methods: {
    // 附加商品列表数据
    async getExtensionConfigureList() {
      const res = await getExtensionConfigureList(this.page);
      console.log(res);
      if (res.status === 200) {
        this.additionalGoodsList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查询按钮
    queryBtn() {
      this.page.page = 1;
      this.getExtensionConfigureList();
    },
    // 新增按钮
    addBtn() {
      sessionStorage.setItem("sharConfigurationType", 'add')
      this.$router.push({
        name: 'sharConfigurationDatil',
        params: {
          name: 'add'
        }
      })
    },
    // 编辑
    editBtn(val) {
      console.log(val);
      sessionStorage.setItem("sharConfigurationType", 'edit')
      sessionStorage.setItem("sharConfigurationId", val.id)
      this.$router.push({
        name: 'sharConfigurationDatil',
        params: {
          name: 'edit'
        }
      })
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getExtensionConfigureList();
    },
    // 删除附加商品
    deleteExtensionConfigure(val) {
      // console.log('删除附加商品', val);
      this.$confirm('确认删除该附加商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteExtensionConfigure(val.id);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getExtensionConfigureList();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },

}


</script>

<style lang='scss' scoped>
.sharConfiguration {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .userTable {
    width: 100%;
    min-height: 300px;
    .noneBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>


