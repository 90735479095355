
<template>
  <!-- 店铺用户 -->
  <div class="shopUsers">
    <div class="searchBar">
      <el-select
        v-model="userData.data.type"
        placeholder="请选择用户属性"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="userData.data.roleId"
        placeholder="请选择职位"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in roleList"
          :key="item.roleId"
          :label="item.roleName"
          :value="item.roleId"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="userData.data.shopName"
        clearable
        placeholder="请输入店铺名称"
      />
      <el-input
        v-model="userData.data.userName"
        clearable
        placeholder="请输入用户姓名"
      />
      <button @click="queryBtn">查询</button>
      <button @click="addBtn">新增</button>
    </div>
    <div class="userTable">
      <el-table :data="userList" border style="width: 100%">
        <el-table-column type="index" label="编号" width="60">
        </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="idNumber" label="身份证号" width="180">
        </el-table-column>
        <el-table-column prop="telephone" label="联系方式"> </el-table-column>
        <el-table-column prop="jobNo" label="工号"> </el-table-column>
        <el-table-column prop="jobNum" label="角色">
          <template slot-scope="scope">
            <div v-if="scope.row.roleList.length > 0">
              <span v-for="item in scope.row.roleList" :key="item.roleId">
                {{ item.roleName }}
              </span>
            </div>
            <div class="noneBox" v-else>暂无角色</div>
          </template>
        </el-table-column>
        <el-table-column prop="shop" label="所属店铺">
          <template slot-scope="scope">
            <div v-if="scope.row.shopLists.length > 0">
              <span v-for="item in scope.row.shopLists" :key="item.shopId">
                {{ item.shopName }}
              </span>
            </div>
            <div class="noneBox" v-else>暂无店铺</div>
          </template>
        </el-table-column>
        <el-table-column prop="jurisdiction" label="登录权限状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.jurisdiction"
              active-color="#01DF6E "
              inactive-color="#B3B3B3"
              :active-value="1"
              :inactive-value="2"
              disabled
            >
              >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="editBtn(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button @click="reset(scope.row)" type="text" size="small"
              >重置密码</el-button
            >
            <el-button type="text" size="small" @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="userData.page"
          :page-size="userData.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelectShopUser, getSelectRole, deleteUser, resetPwd } from '@/api/userManagement/userManagement.js'
export default {
  name: 'shopUsers',
  data() {
    return {
      userList: [], // 用户列表数据
      userData: {
        data: {
          roleId: null,
          shopName: "",
          type: null,
          userName: ""
        },
        page: 1,
        size: 12,
        start: 0
      }, // 用户请求数据
      total: 0,
      options: [
        {
          id: 1,
          name: '系统用户'
        },
        {
          id: 2,
          name: '店铺用户'
        }
      ], // 用户属性数据
      roleList: [], // 角色数据
    }
  },
  created() {
    this.getSelectShopUser();
    this.getSelectRole();
  },
  methods: {
    // 用户列表数据
    async getSelectShopUser() {
      const res = await getSelectShopUser(this.userData);
      // console.log(res);
      if (res.status === 200) {
        this.userList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 角色数据
    async getSelectRole() {
      const res = await getSelectRole();
      // console.log(res);
      if (res.status === 200) {
        this.roleList = res.data;
      }
    },
    // 查询按钮
    queryBtn() {
      this.userData.page = 1;
      this.getSelectShopUser();
    },
    // 新增按钮
    addBtn() {
      // console.log('xinzeng员工');
      sessionStorage.setItem("editType", 'add')
      this.$router.push({
        name: 'addStaff',
        params: {
          name: 'add'
        }
      })
    },
    // 编辑
    editBtn(val) {
      // console.log('xinzeng员工', val);
      sessionStorage.setItem("editType", 'edit')
      sessionStorage.setItem("userId", val.userId)
      this.$router.push({
        name: 'addStaff',
        params: {
          name: 'edit'
        }
      })
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.userData.page = currentPage;
      this.getSelectShopUser();
    },
    // 重置密码 
    reset(val) {
      console.log('重置密码', val);
      this.$confirm('确认重置该用户密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await resetPwd({ userId: val.userId });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '重置成功',
            type: 'success'
          });
          this.getSelectShopUser();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置'
        });
      });
    },
    // 删除用户
    deleteUser(val) {
      // console.log('删除用户', val);
      this.$confirm('确认删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteUser({ userId: val.userId });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getSelectShopUser();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },

}


</script>

<style lang='scss' scoped>
.shopUsers {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .userTable {
    width: 100%;
    min-height: 300px;
    .noneBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>