<template>
  <div class="editCar">
    <el-dialog
      title="编辑车辆分类"
      :visible.sync="editShow"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="110px"
        label-position="left"
      >
        <el-form-item label="车辆分类名称" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="仅限输入15个字符"
            :maxlength="15"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="父级车辆分类" prop="parentId">
          <el-select
            v-model="formData.parentId"
            placeholder="请选择父级车辆分类"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="handelConfirm"
          size="small"
          :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectCarClass, updateCarClass } from '@/api/carManagement/carManagement.js'
export default {
  name: "editCar",
  data() {
    return {
      editShow: false,//编辑弹窗状态
      formData: {
        id: null,
        name: '',
        parentId: null,
      },
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入车辆分类名称',
          trigger: 'blur'
        }],
        parentId: [{
          required: true,
          message: '请选择父级车辆分类',
          trigger: 'change'
        }],
      },
      options: [
        {
          id: 0,
          name: '无'
        }
      ]
    }
  },
  methods: {
    // 查看车辆分类树状
    async getSelectCarClass() {
      const res = await getSelectCarClass();
      // console.log(res);
      if (res.status === 200) {
        res.data.forEach(item => {
          item.children.forEach(item1 => {
            this.options.push(item1);
          })
        })
      }
    },
    // 打开编辑模态框
    openEdit(val) {
      this.options = [
        {
          id: 0,
          name: '无'
        }
      ];
      this.getSelectCarClass();
      // console.log(val)
      this.formData.id = val.id;
      this.formData.name = val.name;
      this.formData.parentId = val.parentId;
      this.editShow = true;
    },
    // 编辑提交
    handelConfirm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        const res = await updateCarClass(this.formData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功',
            type: 'success'
          });
          this.loading = false;
          this.$emit('getSelectCarClass');
          this.editShow = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      })
    },
    // 取消按钮
    cancel() {
      this.$emit('getSelectCarClass');
      this.editShow = false;
    }
  }
};
</script>

<style lang='scss' scoped>
</style>