<template>
  <div class="add">
    <!-- 新增店铺 -->
    <div class="header">
      <template>
        <el-menu
          :default-active="$route.path"
          text-color="#7D7D7D"
          active-text-color="#E3620B"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <!-- <el-menu-item
            :index="item.path"
            v-for="item in addMenuList"
            :key="item.id"
            >{{ this.edit ? "新增员工" : "编辑员工" }}</el-menu-item
          > -->
          <el-menu-item :index="addMenuList[0].path">基础信息</el-menu-item>
          <!-- <el-menu-item :index="addMenuList[0].path" v-else
            >编辑员工</el-menu-item
          > -->
        </el-menu>
        <div class="addStaff">
          <el-form
            ref="elForm"
            :model="formData"
            :rules="rules"
            size="medium"
            label-width="135px"
            label-position="right"
          >
            <el-form-item label="用户属性" prop="type">
              <el-select
                v-model="formData.type"
                placeholder="请选择用户属性"
                clearable
                :style="{ width: '30%' }"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="姓名" prop="name">
              <el-input
                v-model="formData.name"
                placeholder="请输入姓名"
                clearable
                :style="{ width: '30%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="身份证号" prop="idNumber">
              <el-input
                v-model="formData.idNumber"
                placeholder="请输入身份证号"
                :maxlength="18"
                clearable
                :style="{ width: '30%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="telephone">
              <el-input
                v-model="formData.telephone"
                placeholder="请输入电话号码"
                :maxlength="11"
                clearable
                :style="{ width: '30%' }"
              ></el-input>
            </el-form-item>
            <el-form-item label="工号" prop="jobNo">
              <el-input
                v-model="formData.jobNo"
                placeholder="请输入工号"
                clearable
                :style="{ width: '30%' }"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="角色" prop="roleList">
              <el-select
                v-model="formData.roleList"
                value-key="roleId"
                placeholder="请选择角色"
                clearable
                multiple
                :style="{ width: '30%' }"
              >
                <el-option
                  v-for="(item, index) in roleList"
                  :key="index"
                  :label="item.roleName"
                  :value="item"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="formData.type === 2"
              label="所属店铺"
              prop="shopIdList"
            >
              <el-select
                v-model="formData.shopIdList"
                placeholder="请选择所属店铺"
                clearable
                multiple
                :style="{ width: '30%' }"
              >
                <el-option
                  v-for="(item, index) in shopList"
                  :key="index"
                  :label="item.shopName"
                  :value="item.shopId"
                  :disabled="item.disabled"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="登录权限" prop="jurisdiction" required>
              <el-switch
                v-model="formData.jurisdiction"
                active-color="#01DF6E "
                :active-value="1"
                :inactive-value="2"
                inactive-color="#B3B3B3"
              ></el-switch>
            </el-form-item>
            <el-form-item size="large">
              <el-button @click="cancel">取消</el-button>
              <el-button type="primary" :loading="loading" @click="submitForm"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getSelectRole, addUser, getSelectAllShop, getSelectUserById, updateUser } from '@/api/userManagement/userManagement.js'
export default {
  name: 'addStaff',
  data() {
    return {
      id: null,//是否是新增状态
      value: false,//权限状态
      activeName: "first",
      addMenuList: [{ id: 1, title: '员工信息', name: 'first', path: '/home/addStaff' }],
      loading: false,
      editType: sessionStorage.getItem('editType'),
      userId: sessionStorage.getItem('userId'),
      formData: {
        type: null,
        name: '',
        idNumber: '',
        telephone: '',
        jobNo: '',
        roleList: [],
        shopIdList: [],
        jurisdiction: 1,
      },
      rules: {
        type: [{
          required: true,
          message: '请选择用户属性',
          trigger: 'change'
        }],
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        idNumber: [{
          required: true,
          message: '请输入身份证号',
          trigger: 'blur'
        }, {
          pattern: /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
          message: '请输入正确的身份证',
          trigger: 'blur'
        }],
        telephone: [{
          required: true,
          message: '请输入电话号码',
          trigger: 'blur'
        }],
        jobNo: [{
          required: true,
          message: '请输入工号',
          trigger: 'blur'
        }],
        roleList: [{
          required: true,
          message: '请选择角色',
          trigger: 'change'
        }],
        shopIdList: [{
          required: true,
          message: '请选择所属店铺',
          trigger: 'change'
        }],
      },
      options: [
        {
          id: 1,
          name: '系统用户'
        },
        {
          id: 2,
          name: '店铺用户'
        }
      ], // 用户属性数据
      roleList: [],  // 角色数据
      shopList: [],  // 店铺数据
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    },
    // 用户数据
    async getSelectUserById() {
      const res = await getSelectUserById({ userId: this.userId });
      // console.log(res);
      if (res.status === 200) {
        this.formData = {
          id: res.data.userId,
          type: res.data.type,
          name: res.data.name,
          idNumber: res.data.idNumber,
          telephone: res.data.telephone,
          jobNo: res.data.jobNo,
          roleList: res.data.roleList,
          shopIdList: [],
          jurisdiction: res.data.jurisdiction,
        };
        res.data.shopLists.forEach(item => {
          this.formData.shopIdList.push(item.shopId);
        })
      }
      // console.log(this.formData);
    },
    // 角色数据
    async getSelectRole() {
      const res = await getSelectRole();
      // console.log(res);
      if (res.status === 200) {
        this.roleList = res.data;
      }
    },
    // 店铺数据
    async getSelectAllShop() {
      const res = await getSelectAllShop({
        object: ""
      });
      // console.log(res);
      if (res.status === 200) {
        this.shopList = res.data;
      }
    },
    //提交员工信息
    submitForm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        this.loading = true;
        // TODO 提交表单
        // console.log(this.formData);
        if (this.editType === 'add') {
          const res = await addUser(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            this.loading = false;
            this.cancel()
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        } else {
          const res = await updateUser(this.formData);
          // console.log(res);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '编辑成功',
              type: 'success'
            });
            this.loading = false;
            this.cancel()
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: 'error'
            });
            this.loading = false;
          }
        }
      })
    },
    // 取消按钮
    cancel() {
      this.$router.push('/home/shopUsers')
    }
  },
  created() {
    this.getSelectRole();
    this.getSelectAllShop();
    if (this.editType === 'edit') {
      this.getSelectUserById();
    }
    if (sessionStorage.getItem('staffId')) {
      // console.log('员工Id', sessionStorage.getItem('staffId'));
      this.id = sessionStorage.getItem('staffId')
    } else {
      // console.log('员工Id', sessionStorage.getItem('staffId'));
      this.id = sessionStorage.getItem('staffId')

    }
    // console.log(this.$route.path)
    if (this.$route.path === '/home/addShop/shopBasicInfo') {
      this.activeName = 'first'
    } else if (this.$route.path === '/home/addShop/siteManagement') {
      this.activeName = 'second'
    } else {
      this.$route.path === '/home/addShop/staffInfo'
      this.activeName = 'third'
    }
  }
};
</script>

<style lang="scss" scoped>
.add {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu {
      position: sticky;
      top: 0;
      z-index: 6;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      margin-right: 45px;
    }
    .addStaff {
      // width: 320px;
      padding-top: 40px;
      min-height: 300px;
      ::v-deep .el-button {
        width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 5px;
        margin-top: 30px;
        margin-bottom: 20px;
      }
      .el-button--default {
        background: #f4fff5;
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        color: #01df6e;
        margin-right: 20px;
      }
      .el-myselect {
        width: 100%;
      }
      .el-mytime {
        width: 108px;
      }
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  // ::v-deep .add .header .el-menu--horizontal > .el-menu-item[data-v-442fe11b] {
  //   margin-right: 77px !important;
  // }
}
</style>