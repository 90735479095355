<template>
  <!-- 编辑服务项目清单 -->
  <div class="ServiceDetailedDetails">
    <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item>编辑服务项目清单</el-menu-item>
    </el-menu>
    <!-- <el-tag style="font-size: 15px">编辑服务项目清单:</el-tag> -->
    <div class="shopDetail">
      <el-form ref="form" :model="formData" label-width="100px">
        <el-form-item>
          <div class="titleBox">
            <div class="div1Box">序号</div>
            <div class="div1box3">施工员A</div>
            <div class="div1box3" v-if="serviceForm.people === 2">施工员B</div>
            <div class="div1box6">施工时间</div>
            <el-button
              class="btnBox"
              type="warning"
              size="mini"
              @click="addSiteServiceDetailed"
              >添加
            </el-button>
          </div>

          <div v-if="this.formData.serviceDetailedList.length !== 0">
            <div
              class="contentBox"
              v-for="(item, index) in this.formData.serviceDetailedList"
              :key="index"
            >
              <div class="div1Box div1Box2">
                {{ index + 1 }}
              </div>
              <div class="div1Box2 div1Box3">
                <el-input
                  v-model="item.beforeConstructor"
                  placeholder="请输入施工员A作业"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2 div1Box4" v-if="serviceForm.people === 2">
                <el-input
                  v-model="item.afterConstructor"
                  placeholder="请输入施工员B作业"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
              </div>
              <div class="div1Box2 div1Box5 div1box6">
                <el-input
                  type="number"
                  v-model="item.minute"
                  placeholder="请输入施工时间"
                  style="border: none; width: 90%; height: 90%; padding: 0"
                />
                <div class="delBox" @click="delBtn(item)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>

        <el-form-item style="margin-left: 750px">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="commit">确定 </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addServiceDetailed, selectById, findServiceDetailedById, updateServiceDetailed, removeServiceDetailed } from '@/api/goodsManagement/goodsManagement.js'

export default {
  name: 'ServiceDetailedDetails',
  components: {},
  props: {},
  data() {
    return {
      serviceProjectId: '',
      number: 1,
      formData: {
        serviceDetailedList: []
      },
      serviceForm: {}
    }
  },
  computed: {},
  watch: {},
  created() {
    this.init();
  },
  methods: {
    // 获取数据
    async init() {
      this.serviceProjectId = sessionStorage.getItem("id")
      // console.log(this.serviceProjectId)
      const res1 = await selectById(this.serviceProjectId)
      if (res1.status === 200) {
        this.serviceForm = res1.data
      }
      const res = await findServiceDetailedById(this.serviceProjectId)
      if (res.status === 200) {
        this.formData.serviceDetailedList = res.data
        if (res.data.length === 0) {
          this.formData.serviceDetailedList.push(
            {
              orderNumber: this.number++,
              serviceProjectId: this.serviceProjectId,
              beforeConstructor: '',
              afterConstructor: '',
              minute: ''
            }
          )
        }
        this.number = res.data.length + 1
      }
    },
    // 删除
    delBtn(val) {
      console.log(val);
      this.$confirm('此操作将永久删除该项服务项目清单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (val.id) {
          const res = await removeServiceDetailed(val.id);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '删除成功！',
              type: 'success'
            });
            this.init();
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          for (let i = 0; i < this.formData.serviceDetailedList.length; i++) {
            if (this.formData.serviceDetailedList[i].orderNumber === val.orderNumber) {
              this.formData.serviceDetailedList.splice(i, 1);
              this.$message({
                showClose: true,
                message: '删除成功！',
                type: 'success'
              });
            }
          }
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 取消
    cancel() {
      this.$router.push('/home/goodsManagement');
    },
    //确认
    async commit() {
      // console.log(this.formData.serviceDetailedList)
      for (let item of this.formData.serviceDetailedList) {
        if (item.beforeConstructor === '') {
          return this.$message({
            showClose: true,
            message: '请将资料填写完整！',
            type: 'warning'
          });
        }
        if (item.minute === '') {
          return this.$message({
            showClose: true,
            message: '请将资料填写完整！',
            type: 'warning'
          });
        }
      }
      const res = await updateServiceDetailed(this.formData.serviceDetailedList)
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '修改成功',
          type: 'success'
        });
        this.$router.push('/home/goodsManagement');
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }
    },
    addSiteServiceDetailed() {
      this.formData.serviceDetailedList.push(
        {
          orderNumber: this.number++,
          serviceProjectId: this.serviceProjectId,
          beforeConstructor: '',
          afterConstructor: '',
          minute: ''
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.ServiceDetailedDetails {
  padding: 0px 30px;
  box-sizing: border-box;
  .shopDetail {
    width: 420px;
    margin-top: 40px;

    .el-myselect {
      width: 100%;
    }

    .el-mytime {
      width: 108px;
    }

    .carid {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;

      span {
        cursor: pointer;
        margin: 0 8px;
      }
    }

    .titleBox {
      position: relative;
      .div1box3 {
        border-right: none;
      }
    }

    .titleBox,
    .contentBox {
      display: flex;

      div {
        flex: 1;
        // width: 260px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;

        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      .div1Box {
        flex: 0.5;
        color: #606266;
        font-size: inherit;
        font-weight: normal;
        border-right: none;
      }
      .div1box6 {
        flex: 0.5;
      }

      .div1Box2 {
        border-top: none;
      }
      .div1Box5 {
        position: relative;
        .delBox {
          cursor: pointer;
          position: absolute;
          top: 6px;
          right: 10px;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          border-radius: 12px;
          background-color: #ddd;
          padding: 0;
        }
      }
      .div1Box3 {
        border-right: none;
      }
      .div1Box4 {
        border-right: none;
      }

      .btnBox {
        position: absolute;
        top: 0px;
        right: -80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }

    .contentBox1 {
      // width: 520px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      border-top: none;
    }
  }

  width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  ::v-deep.el-form-item__content {
    margin-left: 80px !important;
  }

  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }

  .shopDetail {
    width: 1000px;
    padding-top: 20px;

    .el-myselect {
      width: 100%;
    }

    .el-mytime {
      width: 108px;
    }

    .carid {
      width: 100%;
      height: 40px;
      line-height: 40px;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;

      span {
        cursor: pointer;
        margin: 0 8px;
      }
    }

    .titleBox {
      position: relative;
    }

    .titleBox,
    .contentBox {
      display: flex;

      div {
        flex: 1;
        // width: 260px;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #7d7d7d;
        background: #ffffff;
        padding-left: 10px;
        box-sizing: border-box;
        border: 1px solid #dddddd;

        ::v-deep.el-input__inner {
          width: 100%;
          height: 100%;
          border: none;
        }
      }

      .div1Box {
        border-right: none;
      }

      .div1Box2 {
        border-top: none;
      }

      .btnBox {
        position: absolute;
        top: 0px;
        right: -80px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        background: linear-gradient(
          270deg,
          #e7710d 0%,
          #f6903a 50%,
          #e7710d 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
      }
    }

    .contentBox1 {
      // width: 520px;
      width: 100%;
      height: 36px;
      line-height: 36px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      box-sizing: border-box;
      border: 1px solid #dddddd;
      border-top: none;
    }
  }
}
</style>
