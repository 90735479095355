<template>
  <!-- 订单管理 -->
  <div class="orderManagement">
    <div class="searchBar">
      <el-select
        v-model="searchData.status"
        placeholder="请选择订单状态"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in statusList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.shopName"
        placeholder="请选择店铺"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in shopList"
          :key="item.value"
          :label="item.label"
          :value="item.shopName"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.serviceProjectId"
        placeholder="请选择服务项目"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in servicePro"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.serviceModel"
        placeholder="请选择服务方式"
        clearable
        @change="search"
      >
        <el-option
          v-for="item in serviceMethod"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-date-picker
        class="mydatapicker"
        v-model="value1"
        type="datetimerange"
        @change="changeTime"
        value-format="yyyy-MM-dd HH:mm:ss"
        range-separator="至"
        start-placeholder="查询开始时间"
        end-placeholder="查询结束时间"
      >
      </el-date-picker>
      <!-- <el-input
        placeholder="请输入店铺名称"
        prefix-icon="el-icon-search"
        v-model="searchData.shopName"
        clearable
      >
      </el-input> -->
      <!-- <el-input placeholder="姓名/手机/车牌" /> -->
      <button @click="search">查询</button>
    </div>
    <div class="leadingOutBox">
      <el-select
        v-model="leadingOutStatus"
        placeholder="请选择导出的数据"
        clearable
      >
        <el-option
          v-for="item in leadingOutPro"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button :loading="loading" @click="leadingOut">导出</el-button>
    </div>
    <div class="staffTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="orderNumber" fixed label="订单编号" width="180">
          <template slot-scope="scope">
            <div class="mycell">
              <span>{{ scope.row.orderNumber }}</span>
              <!-- <span class="isEdit" v-if="scope.row.isEdit">改</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="客户姓名" width="130">
          <template slot-scope="scope">
            <div class="mycell">
              <img
                src="../../assets/vipicon.png"
                alt=""
                class="vipIcon"
                v-if="scope.row.isVip === 1"
              />
              {{ scope.row.userName }}
              <span v-if="scope.row.gender === 1" style="margin-left: 10px"
                >先生</span
              >
              <span v-if="scope.row.gender === 2" style="margin-left: 10px"
                >女士</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="客户编号" width="120">
        </el-table-column>
        <el-table-column prop="telephone" label="手机号" width="120">
        </el-table-column>
        <el-table-column label="车牌号" width="120">
          <template slot-scope="scope">
            <div class="mycell">
              <img
                src="../../assets/vipicon.png"
                alt=""
                class="vipIcon"
                v-if="scope.row.carIsVip === 1"
              />
              <span>{{ scope.row.carNumber }}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="orderServiceProjectVos"
          label="服务项目"
          width="200"
        >
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.orderServiceProjectVos"
              :key="index"
            >
              {{ item.serviceProjectName }}&nbsp;&nbsp;&nbsp;
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="project" label="服务方式" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.serviceModel === 1">到店洗车</span>
            <span v-else>上门取钥匙</span>
          </template>
        </el-table-column>
        <el-table-column prop="pickUp" label="取车方式" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.pickUp === 1">到店取车</span>
            <span v-else>完工送钥匙</span>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="店铺" width="150">
        </el-table-column>
        <!-- <el-table-column prop="staff" label="店员"> </el-table-column> -->
        <!-- <el-table-column prop="money" label="订单金额"> </el-table-column> -->
        <el-table-column
          prop="orderPrice"
          label="服务金额"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="additionalGoodsPrice"
          label="附加商品金额"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="fineMoney"
          label="实付金额"
          width="80"
        ></el-table-column>
        <el-table-column prop="status" label="订单状态" width="180">
          <template slot-scope="scope">
            <div class="mycell">
              <span class="orderStatusCancel" v-if="scope.row.status === 1"
                >已下单</span
              >
              <span class="orderStatusGetOrder" v-if="scope.row.status === 2"
                >已支付</span
              >
              <span class="orderStatusGetOrder" v-if="scope.row.status === 3"
                >已接单</span
              >
              <span class="orderStatusGetKey" v-if="scope.row.status === 4"
                >取钥匙分配
              </span>
              <span class="orderStatusCheck" v-if="scope.row.status === 5"
                >到店洗车分配
              </span>
              <span class="orderStatusCheck" v-if="scope.row.status === 6"
                >取钥匙员工到达现场 </span
              ><span class="orderStatusCheck" v-if="scope.row.status === 7"
                >取钥匙扫码
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 8"
                >车辆检查
              </span>
              <span class="orderStatusFinish" v-if="scope.row.status === 9"
                >客户确认
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 10"
                >开工
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 11"
                >完工
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 12"
                >送钥匙分配
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 13"
                >送钥匙中
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 14"
                >送钥匙到达
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 15"
                >送钥匙扫码
              </span>
              <span class="orderStatusFinish" v-if="scope.row.status === 16"
                >等待到店取车
              </span>
              <span class="orderStatusFinish" v-if="scope.row.status === 17"
                >取车成功
              </span>
              <span class="orderStatusFinish" v-if="scope.row.status === 18"
                >车已开走
              </span>
              <span class="orderStatusFinish" v-if="scope.row.status === 19"
                >订单结束
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 20"
                >订单暂停
              </span>
              <span class="orderStatusSendKey" v-if="scope.row.status === 21"
                >客户确认到达
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isTemporaryEnd" label="订单是否异常" width="120">
          <template slot-scope="scope">
            <div v-if="scope.row.isTemporaryEnd === 1">是</div>
            <div v-else>否</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="temporaryEndReason"
          label="订单异常原因"
          width="180"
        >
        </el-table-column>

        <el-table-column prop="createTime" label="订单时间" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="editShop(scope.row)" type="text" size="small"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.status === 1"
              type="text"
              size="small"
              @click="delOrder(scope.row.orderId)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-size="size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getOrderList, delOrder, getServiceList, getSelectAllShop, importOrderExcel } from '@/api/myorder/myorder.js'
export default {
  name: "orderManagement",
  data() {
    return {
      total: 0,//总条数
      currentPage1: 1,//当前页
      size: 10,
      loading: false,
      leadingOutStatus: 1,
      leadingOutPro: [{
        label: '导出当前页数据',
        value: 1
      }, {
        label: '导出所有数据',
        value: 2
      }],
      statusList: [{
        value: 1,
        label: '已下单'
      }, {
        value: 2,
        label: '已支付'
      }, {
        value: 3,
        label: '已接单'
      }, {
        value: 4,
        label: '取钥匙分配'
      }, {
        value: 5,
        label: '到店洗车分配'
      }, {
        value: 6,
        label: '取钥匙员工到达现场'
      }, {
        value: 7,
        label: '取钥匙扫码'
      }, {
        value: 8,
        label: '车辆检查'
      }, {
        value: 9,
        label: '客户确认'
      }, {
        value: 10,
        label: '开工'
      }, {
        value: 11,
        label: '完工'
      }, {
        value: 12,
        label: '送钥匙分配'
      }, {
        value: 13,
        label: '送钥匙中'
      }, {
        value: 14,
        label: '送钥匙到达'
      }, {
        value: 15,
        label: '送钥匙扫码'
      }, {
        value: 16,
        label: '等待到店取车'
      }, {
        value: 21,
        label: '客户确认到达'
      }, {
        value: 17,
        label: '取车成功'
      }, {
        value: 18,
        label: '车已开走'
      }, {
        value: 19,
        label: '已完成'
      }, {
        value: 20,
        label: '订单暂停'
      }],//订单状态选择
      shopList: [],
      serviceMethod: [
        {
          value: 1,
          label: '到店洗车'
        },
        {
          value: 2,
          label: '上门取钥匙'
        }
      ],//服务项目选择
      method: '',//服务项目显示
      servicePro: [],//服务项目选择
      project: '',//服务项目显示
      value1: '',//查询时间段
      shopName: '',//店铺名称
      searchData: {
        object: "",//输入框内容
        pickUp: null,//取车方式：1到店自取2完工送钥匙
        serviceModel: null,//服务方式1到店洗车2上门取钥匙
        serviceProjectId: null,//服务项目id
        shopName: "",//店铺名称
        status: null,//订单状态显示
        timeEnd: "",//查询开始时间
        timeStart: ""//查询结束时间
      },//查询字段内容
      tableData: [],
    };
  },
  created() {
    // 初始化页面
    this.init();
    this.getServiceList();
    this.getSelectAllShop();
  },
  methods: {
    // 获取订单列表
    async init() {
      const res = await getOrderList({ data: this.searchData, page: this.currentPage1, size: this.size })
      if (res.status === 200) {
        this.tableData = res.data.rows
        this.total = res.data.total
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: res.msg,
        });
      }
      // console.log('init', res);
    },
    // 查看所有店铺不分页
    async getSelectAllShop() {
      const res = await getSelectAllShop({
        object: ''
      });
      // console.log(res);
      if (res.status === 200) {
        this.shopList = res.data;
      }
    },
    // 获取服务项目列表
    async getServiceList() {
      const res = await getServiceList({ page: 1, size: 1000 })
      if (res.status === 200) {
        this.servicePro = res.data.rows
      }
      // console.log('init', res);
    },
    //查询按钮
    search() {
      // console.log('search', this.searchData);
      this.currentPage1 = 1;
      this.init();
    },
    // 导出按钮
    async leadingOut() {
      const excelData = {
        page: this.currentPage1,
        size: this.size,
        object: this.searchData.object, //输入框内容
        pickUp: this.searchData.pickUp, //取车方式：1到店自取2完工送钥匙
        serviceModel: this.searchData.serviceModel, //服务方式1到店洗车2上门取钥匙
        serviceProjectId: this.searchData.serviceProjectId, //服务项目id
        shopName: this.searchData.shopName, //店铺名称
        status: this.searchData.status, //订单状态显示
        timeEnd: this.searchData.timeEnd, //查询开始时间
        timeStart: this.searchData.timeStart //查询结束时间
      };
      // console.log(this.leadingOutStatus);
      if (this.leadingOutStatus === 1) {
        // excelData.page = this.currentPage1;
        // excelData.size = this.size;
        // console.log('1');
      } else if (this.leadingOutStatus === 2) {
        excelData.page = 1;
        excelData.size = 99999;
      } else {
        return this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要导出的数据!",
        });
      }
      this.loading = true;
      // console.log(excelData);
      // return
      let res = await importOrderExcel(excelData);
      // console.log(res);
      if (res.type == 'application/json') {
        // console.log('1');
        const that = this;
        const reader = new FileReader();  //创建一个FileReader实例
        reader.readAsText(res, 'utf-8'); //读取文件,结果用字符串形式表示
        reader.onload = function () {//读取完成后,**获取reader.result**
          const res1 = JSON.parse(reader.result);
          // console.log('res1', res1);
          that.loading = false;
          that.$message({
            showClose: true,
            type: "error",
            message: res1.msg,
          });
        }
        return;
      }
      const fileName = res.contentDisposition.split(';');
      const filename2 = fileName[1].split('=');
      const filename3 = decodeURIComponent(filename2[1]);
      // console.log(fileName, filename2, filename3);
      let blob = new Blob([res], {
        type: res.type
      });
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = filename3;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
      window.URL.revokeObjectURL(href);
      this.loading = false;
    },
    //时间选择框改变
    changeTime(val) {
      // console.log('changge', val);
      if (val !== null) {
        this.searchData.timeStart = val[0]
        this.searchData.timeEnd = val[1]
      } else {
        this.searchData.timeStart = ''
        this.searchData.timeEnd = ''
      }
    },
    ///翻页
    handleCurrentChange(val) {
      // console.log('当前页', val);
      this.currentPage1 = val;
      this.init()
    },
    // 查看订单详情
    editShop(val) {
      // console.log('查看订单', val.orderId); 
      sessionStorage.setItem('orderId', val.orderId)
      this.$router.push('/home/orderTabs')
    },
    // 删除订单
    async delOrder(val) {
      this.$confirm("是否删除该订单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delOrder({ orderId: val })
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: "删除成功!",
          });
          this.init()
        } else {
          this.$message({
            showClose: true,
            type: "info",
            message: "删除失败!" + res.msg,
          });
        }
      })
      // console.log('删除订单', val);
      // console.log('del', res);
    },
  }
};
</script>

<style lang='scss' scoped>
.orderManagement {
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 15px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      // margin-right: 10px;
    }
  }
  .leadingOutBox {
    // text-align: right;
    margin-bottom: 20px;
    button {
      min-width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-left: 15px;
      padding: 0 10px;
    }
  }
  .staffTable {
    width: 100%;
    min-height: 300px;
    .block {
      text-align: center;
      padding-top: 20px;
    }
    .mycell {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      // justify-content: center;
      img {
        width: 19px;
        height: 16px;
        margin-right: 3px;
      }
      span {
        display: inline-block;
      }
      .isEdit {
        display: inline-block;
        width: 24px;
        height: 16px;
        background: #eb5050;
        border-radius: 2px;

        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 17px;
        text-align: center;
        margin-left: 10px;
      }
      // 已支付
      .orderStatusIng {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4995f5;
        line-height: 20px;
      }
      .orderStatusIng::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #4995f5;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
      //订单已完成
      .orderStatusFinish {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #36cb0a;
        line-height: 20px;
      }
      .orderStatusFinish::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #36cb0a;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
      // 订单已暂停
      .orderStatusCancel {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #acacac;
        line-height: 20px;
      }
      .orderStatusCancel::before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background: #acacac;
        border-radius: 50%;
        vertical-align: top;
        margin-right: 2px;
        margin-top: 3px;
      }
    }
    // 订单已接单
    .orderStatusGetOrder {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      line-height: 20px;
    }
    .orderStatusGetOrder::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: #acacac;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 2px;
      margin-top: 3px;
    }
    // 订单取钥匙
    .orderStatusGetKeyr {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      line-height: 20px;
    }
    .orderStatusGetKey::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: #acacac;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 2px;
      margin-top: 3px;
    }
    // 订单车辆检查
    .orderStatusCheck {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      line-height: 20px;
    }
    .orderStatusCheck::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: #acacac;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 2px;
      margin-top: 3px;
    }
    // 订单送钥匙
    .orderStatusSendKey {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      line-height: 20px;
    }
    .orderStatusSendKey::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: #acacac;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 2px;
      margin-top: 3px;
    }
    // 订单待取车
    .orderStatusWaitGetCar {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #acacac;
      line-height: 20px;
    }
    .orderStatusWaitGetCar::before {
      content: "";
      display: inline-block;
      width: 14px;
      height: 14px;
      background: #acacac;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 2px;
      margin-top: 3px;
    }
  }
}
// 输入框和选择框大小\
.mydatapicker {
  margin-right: 10px;
}
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
::v-deep .el-input__icon {
  line-height: 24px !important;
}
::v-deep .el-input__inner {
  height: 29px !important;
}
::v-deep .el-date-editor .el-range-separator {
  line-height: 21px !important;
}
</style>