<template>
  <!-- 客户管理 -->
  <div class="customerManagement">
    <div class="searchBar">
      <el-select
        v-model="page.isVip"
        placeholder="请选择是否会员"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input v-model="page.object" clearable placeholder="姓名/手机/车牌" />
      <button @click="queryBtn">查询</button>
      <button @click="checkAdd">新增</button>
      <div class="leadingOutBox">
        <el-select
          v-model="leadingOutStatus"
          placeholder="请选择导出的数据"
          clearable
        >
          <el-option
            v-for="item in leadingOutPro"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button :loading="loading" @click="leadingOut">导出</el-button>
      </div>
    </div>

    <div class="staffTable">
      <el-table
        :data="tableData"
        border
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column prop="number" label="客户编号" width="120">
        </el-table-column>
        <!-- <el-table-column prop="vxName" label="微信昵称">
          <template slot-scope="scope">
            <div class="mycell">
              <img
                src="../../assets/vipicon.png"
                alt=""
                class="vipIcon"
                v-if="scope.row.isVip === 1 && scope.row.vxName"
              />
              <span v-if="scope.row.vxName">{{ scope.row.vxName }}</span>
              <span style="color: #ccc" v-else>暂无微信昵称</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="openId" label="UID">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.openId">{{ scope.row.openId }}</span>
              <span style="color: #ccc" v-else>暂无UID</span>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column prop="userName" label="姓名" width="120">
          <template slot-scope="scope">
            <div class="mycell">
              <img
                src="../../assets/vipicon.png"
                alt=""
                class="vipIcon"
                v-if="scope.row.isVip === 1"
              />
              <span>{{ scope.row.userName }}</span>
              <span v-if="scope.row.gender === 1" style="margin-left: 10px"
                >先生</span
              >
              <span v-if="scope.row.gender === 2" style="margin-left: 10px"
                >女士</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="carCount" label="车辆数" width="80">
        </el-table-column>
        <el-table-column prop="telephone" label="手机号" width="120">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.telephone">{{ scope.row.telephone }}</span>
              <span style="color: #ccc" v-else>暂无手机号</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="memberName" label="会员名称" width="80">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.isVip === 1">{{
                scope.row.memberName
              }}</span>
              <span style="color: #ccc" v-else>非会员</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="grade" label="会员等级" width="80">
        </el-table-column>
        <el-table-column prop="vipTime" label="会员到期时间" width="110"> </el-table-column>
        <el-table-column prop="createTime" label="注册时间" width="110"> </el-table-column>
        <el-table-column
          prop="moneySum"
          label="消费总金额"
          sortable="custom"
          width="120"
        >
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button @click="checkEdit(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <!-- <el-button type="text" size="small" @click="del(scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getSelectCustomer, deleteCustomer, importConnectionExcel } from '@/api/customerManagement/customerManagement.js'
export default {
  name: 'customerManagement',
  data() {
    return {
      page: {
        isVip: null,
        level: null,
        memberId: null,
        object: "",
        page: 1,
        size: 10,
      },
      loading: false,
      leadingOutStatus: 1,
      leadingOutPro: [{
        label: '导出当前页数据',
        value: 1
      }, {
        label: '导出所有数据',
        value: 2
      }],
      total: 0,
      options: [
        {
          id: 1,
          label: '是'
        },
        {
          id: 2,
          label: '否'
        }
      ],
      //表格数据
      tableData: []
    }
  },
  created() {
    this.getSelectCustomer();
  },
  methods: {
    // 查看客户列表分页
    async getSelectCustomer() {
      const res = await getSelectCustomer(this.page);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 查询
    queryBtn() {
      this.page.page = 1;
      this.getSelectCustomer();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getSelectCustomer();
    },
    // 排序 {column,prop,order} 列数据|排序字段|排序方式
    sortChange(column) {
      // console.log(column);
      this.page.level = null;
      if (column.order === null) {
        this.page.level = null;
      }
      if (column.prop === 'moneySum' && column.order === 'ascending') {
        this.page.level = 0;
      }
      if (column.prop === 'moneySum' && column.order === 'descending') {
        this.page.level = 1;
      }
      this.getSelectCustomer();
    },
    // 删除用户
    del(val) {
      // console.log('删除用户', val);
      this.$confirm('确认删除该用户?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteCustomer({ customerId: parseInt(val.id) });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getSelectCustomer();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 新增用户信息跳转
    checkAdd() {
      sessionStorage.setItem('checkEdit', 'add');
      sessionStorage.setItem('customerId', null);
      this.$router.push('/home/detailTabs/customerDetail');
    },
    // 编辑用户信息跳转
    checkEdit(val) {
      // console.log('查看用户信息', val);
      sessionStorage.setItem('checkEdit', 'edit');
      sessionStorage.setItem('customerId', val.id);
      this.$router.push('/home/detailTabs/customerDetail');
    },
    // 导出按钮
    async leadingOut() {
      // console.log(this.leadingOutStatus);
      const excelData = {
        page: this.page.page,
        size: this.page.size,
        isVip: this.page.isVip,
        level: this.page.level,
        memberId: this.page.memberId,
        object: this.page.object,
      };
      if (this.leadingOutStatus === 1) {
        // console.log('1');
      } else if (this.leadingOutStatus === 2) {
        excelData.page = 1;
        excelData.size = 99999;
      } else {
        return this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要导出的数据!",
        });
      }
      this.loading = true;
      // console.log(excelData);
      // return
      let res = await importConnectionExcel(excelData);
      // console.log('res', res);
      if (res.type == 'application/json') {
        // console.log('1');
        const that = this;
        const reader = new FileReader();  //创建一个FileReader实例
        reader.readAsText(res, 'utf-8'); //读取文件,结果用字符串形式表示
        reader.onload = function () {//读取完成后,**获取reader.result**
          const res1 = JSON.parse(reader.result);
          // console.log('res1', res1);
          that.loading = false;
          that.$message({
            showClose: true,
            type: "error",
            message: res1.msg,
          });
        }
        return;
      }
      const fileName = res.contentDisposition.split(';');
      const filename2 = fileName[1].split('=');
      const filename3 = decodeURIComponent(filename2[1]);
      // console.log(fileName, filename2, filename3);
      let blob = new Blob([res], {
        type: res.type
      });
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = filename3;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
      window.URL.revokeObjectURL(href);
      this.loading = false;
    },
  }
}
</script>

<style lang='scss' scoped>
.customerManagement {
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
    .leadingOutBox {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 20px;
      button {
        min-width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
        margin-left: 15px;
        padding: 0 10px;
      }
    }
  }
  .staffTable {
    width: 100%;
    min-height: 300px;
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
.mycell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  .vipIcon {
    width: 19px;
    height: 16px;
    vertical-align: sub;
    margin-right: 3px;
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>