<template>
  <!-- 商品管理 -->
  <div class="goodsManagement">
    <!--    头部-->
    <div class="searchBar">
      <el-select
        v-model="page.stationType"
        placeholder="请选择工位类型"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="page.name"
        clearable
        placeholder="服务项目名称"
        @change="queryBtn"
      />
      <button @click="queryBtn">查询</button>
      <button @click="reset">重置</button>
      <button @click="addBtn">新增</button>
    </div>

    <!--    列表-->
    <div class="staffTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="id"
          label="编号"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="服务名称"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="serviceProjectCategoryName"
          label="服务类别"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="标题"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          prop="stationType"
          label="工位类型"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.stationType === 1">{{ "洗车工位" }}</span>
            <span v-else>{{ "美容工位" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="原价" align="center" width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.price + "(元)" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="minute"
          label="总分钟数"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.minute + "(分)" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="people"
          label="施工人数"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.people + "(人)" }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="commission"
          label="提成（元）"
          align="center"
          width="100"
        >
        </el-table-column>
        <el-table-column
          prop="commissionPercentage"
          label="提成百分比（%）"
          align="center"
          width="140"
        >
        </el-table-column>

        <el-table-column
          prop="effect"
          label="项目效果"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-popover placement="right" width="200" trigger="click">
              {{ scope.row.effect }}
              <span
                slot="reference"
                style="cursor: pointer; text-decoration: underline"
                >查看详情</span
              >
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column
          prop="towel"
          label="毛巾分类"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-popover placement="right" width="200" trigger="click">
              {{ scope.row.towel }}
              <span
                slot="reference"
                style="cursor: pointer; text-decoration: underline"
                >查看详情</span
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          prop="product"
          label="产品清单"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-popover placement="right" width="200" trigger="click">
              {{ scope.row.product }}
              <span
                slot="reference"
                style="cursor: pointer; text-decoration: underline"
                >查看详情</span
              >
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="toll" label="工具清单" align="center" width="80">
          <template slot-scope="scope">
            <el-popover placement="right" width="200" trigger="click">
              {{ scope.row.toll }}
              <span
                slot="reference"
                style="cursor: pointer; text-decoration: underline"
                >查看详情</span
              >
            </el-popover>
          </template>
        </el-table-column>

        <el-table-column label="服务清单" align="center" width="80">
          <template slot-scope="scope">
            <el-button type="text" @click="selectServiceDetailed(scope.row.id)"
              >查看</el-button
            >
          </template>
        </el-table-column>

        <el-table-column label="服务价格" align="center" width="80">
          <template slot-scope="scope">
            <el-button
              type="text"
              @click="selectServiceMemberGrade(scope.row.id, scope.row.price)"
              >查看</el-button
            >
          </template>
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button @click="update(scope.row)" type="text" size="small"
              >编辑
            </el-button>
            <el-button type="text" size="small" @click="remove(scope.row.id)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="currentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!--    弹窗-->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        ref="data"
        :model="data"
        :label-position="labelPosition"
        label-width="80px"
      >
        <el-form-item label="服务名称">
          <el-input v-model="data.name" type="text"></el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="data.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="data.title" type="text"></el-input>
        </el-form-item>
        <el-form-item label="工位类型">
          <el-select v-model="data.stationType" placeholder="请选择工位类型">
            <el-option label="洗车工位" value="1"></el-option>
            <el-option label="美容工位" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="commit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectAllServiceProject, removeServiceProject } from "@/api/goodsManagement/goodsManagement.js";

export default {
  name: 'goodsManagement',
  data() {
    return {
      serviceForm: {
        id: "",
        name: '',
        title: '',
        stationType: '',
        price: '',
        people: '',
        effect: '',
        towel: '',
        toll: '',
        product: '',
      },
      labelPosition: 'right',
      dialogVisible: false,
      options: [{
        id: '1',
        label: '洗车工位'
      }, {
        id: '2',
        label: '美容工位'
      }],
      page: {
        page: 1,
        size: 10,
        start: 0,
        stationType: '',
        name: ''
      },
      data: {
        name: "",
        title: '',
        stationType: '',
      },
      total: 0,
      activeName: "first",
      tableData: [],
      addMenuList: [
        { id: 1, title: '服务参数', name: 'first', path: '/home/goodsManagement/serviceParameters' },
        { id: 2, title: '服务价格', name: 'second', path: '/home/goodsManagement/servicePrice' },
      ]
    };
  },
  methods: {
    // 服务价格查看按钮
    selectServiceMemberGrade(id, price) {
      sessionStorage.setItem('serviceId', id);
      sessionStorage.setItem('price', price);
      this.$router.push('/home/updateServiceMemberGrade');
      // console.log(id)
    },
    // 编辑
    update(item) {
      sessionStorage.setItem('serviceId', item.id);
      this.$router.push('/home/serviceTabs/updateServiceDetails');
    },
    // 删除
    remove(id) {
      this.$confirm('此操作将永久删除服务项目及相关信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await removeServiceProject(id);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getGoodsList();
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 弹窗关闭
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },
    // 弹窗取消
    cancel() {
      this.dialogVisible = false
      this.data = {}
    },
    // 查看服务清单跳转页面
    selectServiceDetailed(id) {
      // console.log(id)
      sessionStorage.setItem('id', id);
      this.$router.push('/home/updateServiceDetailedDetails');
    },
    // 弹窗确认
    commit() {
      this.dialogVisible = false
      this.data = {}
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    },
    queryBtn() {
      this.page.page = 1;
      this.getGoodsList();
    },
    //重置
    reset() {
      this.page.stationType = ''
      this.page.name = ''
      this.getGoodsList()
    },
    addBtn() {
      this.$router.push('/home/serviceDetails');
    },
    // 服务项目list
    async getGoodsList() {
      const res = await getSelectAllServiceProject(this.page);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 分页
    currentChange(currentPage) {
      this.page.page = currentPage;
      // console.log("size==>", this.page.page)
      this.getGoodsList()
    }
  },
  created() {
    this.getGoodsList();
  }
};
</script>

<style lang="scss" scoped>
.goodsManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    width: 100%;
    min-height: 300px;

    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}

.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>