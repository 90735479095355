<template>
  <!-- 商品类别管理 -->
  <div class="goodsCategoryManagement">
    <!--    头部-->
    <div class="searchBar">
      <el-input
        v-model="page.name"
        clearable
        placeholder="服务项目类别名称"
        @change="queryBtn"
      />
      <el-date-picker
        v-model="page.date"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <button @click="queryBtn">查询</button>
      <button @click="addBtn">新增</button>
    </div>

    <!--    列表-->
    <div class="staffTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column
          prop="id"
          label="编号"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="服务项目类别"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="createTime"
          label="创建时间"
          align="center"
        >
        </el-table-column>

        <el-table-column fixed="right" label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button @click="update(scope.row)" type="text" size="small"
              >编辑
            </el-button>
            <el-button type="text" size="small" @click="remove(scope.row.id)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="currentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!--    服务项目类别弹窗-->
    <el-dialog
      :title="isEdit === 1 ? '新增服务项目类别' : '编辑服务项目类别'"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="135px"
      >
        <el-form-item label="服务项目类别名称" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="请输入服务项目类别名称"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="commit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getServiceProjectCategoryList, delServiceProjectCategory, addServiceProjectCategory, editServiceProjectCategory, getServiceProjectCategoryDetails } from "@/api/goodsManagement/goodsManagement.js";

export default {
  name: 'goodsCategoryManagement',
  data() {
    return {
      isEdit: 1,
      dialogVisible: false,
      page: {
        page: 1,
        size: 10,
        date: [],
        name: ''
      },
      formData: {
        name: '',
        id: null
      },
      rules: {
        name: [{
          required: true,
          message: '请输入服务项目类别名称',
          trigger: 'blur'
        }],
      },
      total: 0,
      tableData: [],
    };
  },
  created() {
    this.getServiceProjectCategoryList();
  },
  methods: {
    // 服务项目类别list
    async getServiceProjectCategoryList() {
      const res = await getServiceProjectCategoryList(this.page);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 服务项目类别详情
    async getServiceProjectCategoryDetails(val) {
      const res = await getServiceProjectCategoryDetails(val);
      if (res.status === 200) {
        this.formData = {
          name: res.data.name,
          id: res.data.id
        };
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 新增
    addBtn() {
      this.formData = {
        name: '',
        id: null
      };
      this.isEdit = 1;
      this.dialogVisible = true;
    },
    // 编辑
    update(item) {
      // console.log(item);
      this.getServiceProjectCategoryDetails(item.id);
      this.isEdit = 2;
      this.dialogVisible = true;
    },
    // 弹层取消
    handleClose() {
      this.dialogVisible = false;
    },
    // 弹层取消
    cancel() {
      this.dialogVisible = false;
    },
    // 弹层确定
    async commit() {
      if (this.isEdit === 1) {
        const res = await addServiceProjectCategory(this.formData);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功！',
            type: 'success'
          });
          this.getServiceProjectCategoryList();
          this.dialogVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      } else {
        const res = await editServiceProjectCategory(this.formData);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '编辑成功！',
            type: 'success'
          });
          this.getServiceProjectCategoryList();
          this.dialogVisible = false;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }
    },
    // 删除
    remove(id) {
      this.$confirm('此操作将永久删除服务项目类别及相关信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await delServiceProjectCategory(id);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getServiceProjectCategoryList();
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 搜索
    queryBtn() {
      this.getServiceProjectCategoryList();
    },
    // 分页
    currentChange(currentPage) {
      this.page.page = currentPage;
      // console.log("size==>", this.page.page)
      this.getServiceProjectCategoryList()
    }
  }
};
</script>

<style lang="scss" scoped>
.goodsCategoryManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    width: 100%;
    min-height: 300px;

    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px;
  margin-right: 10px !important;
}
.el-date-editor {
  margin-right: 10px !important;
}

.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>
