import { render, staticRenderFns } from "./addCoupon.vue?vue&type=template&id=a1fae1ea&scoped=true&"
import script from "./addCoupon.vue?vue&type=script&lang=js&"
export * from "./addCoupon.vue?vue&type=script&lang=js&"
import style0 from "./addCoupon.vue?vue&type=style&index=0&id=a1fae1ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1fae1ea",
  null
  
)

export default component.exports