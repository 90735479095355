<template>
  <!-- 车辆列表 -->
  <div class="carCategary">
    <div class="searchBar">
      <div>
        <el-cascader
          v-model="carData.data.classificationId"
          :options="options"
          :props="Props"
          placeholder="请选择车辆分类"
          @change="handleChange"
          clearable
        >
        </el-cascader>
      </div>
      <el-select
        v-model="carData.data.isNewEnergy"
        placeholder="请选择是否新能源"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in isNewEnergyOptions"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="carData.data.object"
        clearable
        placeholder="姓名/手机/车牌"
      />
      <button @click="queryBtn">查询</button>
      <button @click="addBtn">新增</button>
      <div class="leadingOutBox">
        <el-select
          v-model="leadingOutStatus"
          placeholder="请选择导出的数据"
          clearable
        >
          <el-option
            v-for="item in leadingOutPro"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-button :loading="loading" @click="leadingOut">导出</el-button>
      </div>
    </div>
    <div class="staffTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="carNumber" label="车牌号码">
          <template slot-scope="scope">
            <div class="mycell">
              <img
                src="../../assets/vipicon.png"
                alt=""
                class="vipIcon"
                v-if="scope.row.isVip === 1 && scope.row.carNumber"
              />
              <span v-if="scope.row.carNumber">{{ scope.row.carNumber }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="carClassName" label="车辆类型">
        </el-table-column>
        <el-table-column prop="isNewEnergy" label="新能源">
          <template slot-scope="scope">
            <span v-if="scope.row.isNewEnergy">是</span>
            <span v-if="!scope.row.isNewEnergy">否</span>
          </template>
        </el-table-column>
        <el-table-column prop="isVip" label="是否会员车">
          <template slot-scope="scope">
            <span v-if="scope.row.isVip === 1">是</span>
            <span v-if="scope.row.isVip === 2">否</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="isMechanism" label="是否机构车">
          <template slot-scope="scope">
            <span v-if="scope.row.isMechanism === 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column> -->
        <!-- <el-table-column prop="license" label="行驶证信息"> </el-table-column> -->
        <el-table-column prop="userVoList" label="客户名称">
          <template slot-scope="scope">
            <div
              style="margin-right: 15px"
              v-for="item in scope.row.userVoList"
              :key="item.userId"
            >
              <span v-if="item.userName"
                >{{ item.userName }} &nbsp;&nbsp;
                {{ item.gender === 1 ? "先生" : "女士" }}</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="number" label="客户编号">
          <template slot-scope="scope">
            <div
              style="margin-right: 15px"
              v-for="item in scope.row.userVoList"
              :key="item.userId"
            >
              <span v-if="item.userName">{{ item.number }} </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="consumeCount" label="消费总次数">
        </el-table-column>
        <el-table-column prop="consumeCountMoney" label="消费总金额">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="editShop(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <!-- <el-button type="text" size="small" @click="deleteShop(scope.row)"
              >删除</el-button
            > -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="carData"
          @current-change="handleCurrentChange"
          :current-page.sync="carData.page"
          :page-size="carData.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCarList, deleteCar, getSelectCarClass, importCarExcel } from '@/api/carManagement/carManagement.js'
export default {
  name: 'carCategary',
  data() {
    return {
      carData: {
        data: {
          classificationId: null,
          isNewEnergy: null,
          object: ""
        },
        page: 1,
        size: 10,
        start: 0
      },
      loading: false,
      leadingOutStatus: 1,
      leadingOutPro: [{
        label: '导出当前页数据',
        value: 1
      }, {
        label: '导出所有数据',
        value: 2
      }],
      total: 0,
      options: [], // 车辆类型
      isNewEnergyOptions: [
        {
          id: true,
          label: '是'
        },
        {
          id: false,
          label: '否'
        }
      ],
      Props: {
        value: 'id',
        children: 'children',
        label: 'name',
        emitPath: false
      },
      tableData: [
        // {
        //   id: '1',
        //   type: '宝马',
        //   carNum: '川A23456',
        //   drivingLicense: '4567890-09876sad',
        //   client: '客户1',
        //   buys: '2',
        //   totalMoney: '999'
        // },
        // {
        //   id: '21',
        //   type: '宝马',
        //   carNum: '川A23456',
        //   drivingLicense: '4567890-09876sad',
        //   client: '客户1',
        //   buys: '2',
        //   totalMoney: '999'
        // },
        // {
        //   id: '3',
        //   type: '宝马',
        //   carNum: '川A23456',
        //   drivingLicense: '4567890-09876sad',
        //   client: '客户1',
        //   buys: '2',
        //   totalMoney: '999'
        // },
        // {
        //   id: '4',
        //   type: '宝马',
        //   carNum: '川A23456',
        //   drivingLicense: '4567890-09876sad',
        //   client: '客户1',
        //   buys: '2',
        //   totalMoney: '999'
        // },
        // {
        //   id: '5',
        //   type: '宝马',
        //   carNum: '川A23456',
        //   drivingLicense: '4567890-09876sad',
        //   client: '客户1',
        //   buys: '2',
        //   totalMoney: '999'
        // },
        // {
        //   id: '6',
        //   type: '宝马',
        //   carNum: '川A23456',
        //   drivingLicense: '4567890-09876sad',
        //   client: '客户1',
        //   buys: '2',
        //   totalMoney: '999'
        // }
      ]
    }
  },
  created() {
    this.getCarList();
    this.getSelectCarClass();
  },
  methods: {
    // 车辆列表
    async getCarList() {
      const res = await getCarList(this.carData);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 车辆分类数据
    async getSelectCarClass() {
      const res = await getSelectCarClass();
      // console.log(res);
      if (res.status === 200) {
        this.options = res.data;
      }
      // console.log(this.options);
      this.options.forEach(item => {
        if (item.children.length === 0) {
          item.children = undefined;
        } else {
          item.children.forEach(item1 => {
            if (item1.children.length === 0) {
              item1.children = undefined;
            }
          })
        }
      });
    },
    // 级联选择
    handleChange(val) {
      // console.log(val);
    },
    // 查询
    queryBtn() {
      this.carData.page = 1;
      this.getCarList();
    },
    // 导出按钮
    async leadingOut() {
      // console.log(this.leadingOutStatus);
      const carExcelData = {
        classificationId: this.carData.data.classificationId,
        object: this.carData.data.object,
        isNewEnergy: this.carData.data.isNewEnergy,
        page: this.carData.page,
        size: this.carData.size
      }
      if (this.leadingOutStatus === 1) {
        // console.log('1');
      } else if (this.leadingOutStatus === 2) {
        carExcelData.page = 1;
        carExcelData.size = 99999;
      } else {
        return this.$message({
          showClose: true,
          type: "warning",
          message: "请选择需要导出的数据!",
        });
      }
      this.loading = true;
      // console.log(carExcelData);
      // return
      let res = await importCarExcel(carExcelData);
      // console.log(res, res.type);
      if (res.type == 'application/json') {
        // console.log('1');
        const that = this;
        const reader = new FileReader();  //创建一个FileReader实例
        reader.readAsText(res, 'utf-8'); //读取文件,结果用字符串形式表示
        reader.onload = function () {//读取完成后,**获取reader.result**
          const res1 = JSON.parse(reader.result);
          // console.log('res1', res1);
          that.loading = false;
          that.$message({
            showClose: true,
            type: "error",
            message: res1.msg,
          });
        }
        return;
      }
      const fileName = res.contentDisposition.split(';');
      const filename2 = fileName[1].split('=');
      const filename3 = decodeURIComponent(filename2[1]);
      // console.log(fileName, filename2, filename3);
      let blob = new Blob([res], {
        type: res.type
      });
      let downloadElement = document.createElement('a');
      let href = window.URL.createObjectURL(blob);
      downloadElement.href = href;
      downloadElement.download = filename3;
      document.body.appendChild(downloadElement);
      downloadElement.click();
      document.body.removeChild(downloadElement); //移除元素；防止连续点击创建多个a标签
      window.URL.revokeObjectURL(href);
      this.loading = false;
    },
    // 新增按钮
    addBtn() {
      sessionStorage.setItem('carId', NaN);
      sessionStorage.setItem('carCheckEdit', 'add');
      this.$router.push('/home/carTabs/editCarCat');
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.carData.page = currentPage;
      this.getCarList();
    },
    // 删除
    deleteShop(val) {
      // console.log(val);
      this.$confirm('确认删除该车辆?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteCar({ carId: val.id });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getCarList();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // 编辑
    editShop(val) {
      // console.log(val);
      sessionStorage.setItem('carId', val.id);
      sessionStorage.setItem('carCheckEdit', 'edit');
      this.$router.push('/home/carTabs/editCarCat');
    }
  }
}
</script>

<style lang='scss' scoped>
.carCategary {
  padding: 20px 30px;
  min-height: 300px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
    .leadingOutBox {
      // text-align: right;
      margin-left: auto;
      margin-bottom: 20px;
      button {
        min-width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 30px;
        cursor: pointer;
        margin-left: 15px;
        padding: 0 10px;
      }
    }
  }
  .staffTable {
    width: 100%;
    min-height: 300px;
    .mycell {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      .vipIcon {
        width: 19px;
        height: 16px;
        vertical-align: sub;
        margin-right: 3px;
      }
    }
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px !important;
}
.searchBar::v-deep .el-cascader {
  width: 160px !important;
  height: 30px !important;
  line-height: 30px;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>