<template>
  <!-- 车辆分类 -->
  <div class="carClassification">
    <el-button type="primary" @click="addBtn">新增</el-button>
    <div class="tableList">
      <el-table
        :data="tableData"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        :default-expand-all="false"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column prop="name" label="车辆类型"> </el-table-column>
        <el-table-column label="操作" width="300">
          <template slot-scope="scope">
            <div class="btn" v-if="scope.row.parentId !== null">
              <!-- <span>添加子级</span>
            <span @click="addMember(id)">添加人员</span> -->
              <span type="text" @click="toEdit(scope.row)" size="small"
                >编辑</span
              >
              <span type="text" @click="del(scope.row.id)" size="small"
                >删除</span
              >
              <!-- <span @click="toEdit(scope.row)">编辑</span> -->
              <!-- <span @click="DeleteOrganization(scope.row.id)">删除</span> -->
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 新增弹窗 -->
    <el-dialog
      title="新增车辆分类"
      :visible.sync="add"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="110px"
        label-position="left"
      >
        <el-form-item label="车辆分类名称" prop="name">
          <el-input
            v-model="formData.name"
            placeholder="仅限输入15个字符"
            :maxlength="15"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="父级车辆分类" prop="parentId">
          <el-select
            v-model="formData.parentId"
            placeholder="请选择父级车辆分类"
            clearable
            :style="{ width: '100%' }"
          >
            <el-option
              v-for="(item, index) in options"
              :key="index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="handelConfirm"
          size="small"
          :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑弹窗 -->
    <!-- <edit-car></edit-car> -->
    <editCar ref="edit" @getSelectCarClass="getSelectCarClass"></editCar>
  </div>
</template>

<script>
import { getSelectCarClass, addCarClass, deleteCarClass } from '@/api/carManagement/carManagement.js'
import editCar from '../../components/carManagement/carClassification/editCar.vue';
export default {
  name: 'carClassification',
  components: {
    editCar
  },
  data() {
    return {
      add: false,//新增弹窗状态
      formData: {
        name: '',
        parentId: null,
      },
      loading: false,
      rules: {
        name: [{
          required: true,
          message: '请输入车辆分类名称',
          trigger: 'blur'
        }],
        parentId: [{
          required: true,
          message: '请选择父级车辆分类',
          trigger: 'change'
        }],
      },

      tableData: [
        // {
        //   id: '1',
        //   parentId: 0,
        //   name: '宝马',
        //   children: [
        //     {
        //       id: '2',
        //       parentId: 1,
        //       name: 'X5',
        //     },
        //     {
        //       id: '5',
        //       parentId: 1,
        //       name: 'X2',
        //     },
        //     {
        //       id: '6',
        //       parentId: 1,
        //       name: 'X7',
        //     }
        //   ]
        // },
        // {
        //   id: '3',
        //   parentId: 0,
        //   name: '奔驰',
        //   children: [
        //     {
        //       id: '4',
        //       parentId: 3,
        //       name: 'S450',
        //     }
        //   ]
        // }
      ],
      options: [
        {
          id: 0,
          name: '无'
        }
      ]
    }
  },
  created() {
    this.getSelectCarClass();
  },
  methods: {
    // 查看车辆分类树状
    async getSelectCarClass() {
      const res = await getSelectCarClass();
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data;
      }
    },
    // 新增按钮
    addBtn() {
      this.options = [
        {
          id: 0,
          name: '无'
        }
      ];
      this.tableData.forEach(item => {
        item.children.forEach(item1 => {
          this.options.push(item1);
        })
      })
      this.add = true;
    },
    // 删除分类
    async del(val) {
      this.$confirm('确认删除该车辆分类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteCarClass({ carClassId: val });
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getSelectCarClass();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    //取消按钮
    cancel() {
      this.formData = {
        name: '',
        parentId: null,
      };
      this.add = false
    },
    // 新增提交
    handelConfirm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        this.loading = true;
        const res = await addCarClass(this.formData);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '新增成功',
            type: 'success'
          });
          this.loading = false;
          this.getSelectCarClass();
          this.cancel()
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
          this.loading = false;
        }
      })
    },
    // 编辑
    toEdit(val) {
      this.$refs.edit.openEdit(val);
    }
  }
}
</script>

<style lang='scss' scoped>
.carClassification {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .tableList {
    width: 100%;
    margin-top: 30px;
  }
  .btn {
    width: 100%;
    display: flex;
    align-items: center;
    span {
      margin-right: 10px;
      cursor: pointer;
    }
  }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
}
</style>
