import request from '@/utils/request'

// 积分配置列表
export function showList(params) {
    return request({
        url: 'integral/configure/list',
        method: 'get',
        params
    });
}

// 新增积分配置
export function add(data) {
    return request({
        url: 'integral/configure',
        method: 'post',
        data
    });
}

// 根据id获取积分配置对象
export function byId(id) {
    return request({
        url: 'integral/configure/' + id,
        method: 'get'
    });
}

// 跟新积分配置
export function update(data) {
    return request({
        url: 'integral/configure',
        method: 'put',
        data
    });
}

// 删除积分配置
export function remove(id) {
    return request({
        url: 'integral/configure/' + id,
        method: 'delete'
    });
}