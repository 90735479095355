<template>
  <!-- 店铺管理列表 -->
  <div class="shopManagement">
    <div class="myList" v-if="edit == false">
      <div class="searchBar">
        <el-select v-model="value" placeholder="请选择店铺状态" clearable>
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
          placeholder="请输入店铺名称"
          prefix-icon="el-icon-search"
          v-model="input"
          clearable
        >
        </el-input>
        <!-- <el-button type="primary" @click="search()">查询</el-button
      ><el-button type="primary" @click="add()">新增</el-button> -->
        <button @click="search">查询</button>
        <button @click="add(null, 1)">新增</button>
      </div>
      <div class="shopTable">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="name" label="店铺名称" width="130">
          </el-table-column>
          <el-table-column label="店铺装饰图" width="130">
            <template slot-scope="scope">
              <span
                @click="preview(scope.row)"
                style="text-decoration: underline; cursor: pointer"
                >预览</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="address" label="店铺地址"> </el-table-column>
          <el-table-column prop="count" label="工位数量"> </el-table-column>
          <el-table-column prop="telephone" label="联系方式"> </el-table-column>
          <el-table-column prop="status" label="店铺状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">营业中</span>
              <span v-if="scope.row.status === 2">已打烊</span>
              <span v-if="scope.row.status === 3">关闭</span>
              <span v-if="scope.row.status === 4">即将打烊</span>
              <span v-if="scope.row.status === 5">开业在即，敬请期待</span>
            </template>
          </el-table-column>
          <el-table-column label="营业时间">
            <template slot-scope="scope">
              <span
                >{{ scope.row.businessTimeStart }} -
                {{ scope.row.businessTimeEnd }}</span
              >
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作">
            <template slot-scope="scope">
              <el-button @click="add(scope.row.id, 2)" type="text" size="small"
                >编辑</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="deleteShop(scope.row.id)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage1"
            :page-size="8"
            layout="total, prev, pager, next, jumper"
            :total="listTotal"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 店铺图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="previewList"
    />
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import { getShopList, delShop, getPhoto } from '@/api/myshop/shop.js'
export default {
  name: 'shopManagement',
  components: { ElImageViewer },
  data() {
    return {
      edit: false,//编辑状态
      previewList: [],//预览店铺图片数组
      showViewer: false, // 显示查看器  
      input: '',//搜索框文字
      value: null,//选择店铺状态
      currentPage1: 1,//当前页码
      listTotal: null,//列表总数
      status: [{
        value: '1',
        label: '营业中'
      }, {
        value: '2',
        label: '打烊'
      }, {
        value: '3',
        label: '关闭'
      }, {
        value: '4',
        label: '即将打烊'
      }, {
        value: '5',
        label: '开业在即，敬请期待'
      }],
      tableData: []//店铺列表信息
    }
  },
  created() {
    // 打开页面获取店铺数据
    this.getShopList(1)
  },
  methods: {
    // 获取店铺数据
    async getShopList(currentPage) {
      const data = {
        name: this.input, status: this.value, userLatitude: null,
        userLongitude: null
      }
      const res = await getShopList({ data, page: currentPage, size: 8 })
      if (res.status === 200) {
        this.tableData = res.data.rows
        this.listTotal = res.data.total
      }
      // console.log("res", res);
    },
    //翻页
    handleCurrentChange(val) {
      // console.log('当前页', val);
      this.getShopList(val)
    },
    // 编辑状态修改
    showList(val) {
      this.edit = val
    },
    // 查询
    search() {
      // console.log('查询', this.value, this.input);
      this.getShopList(1)
    },
    // 新增(val1为店铺Id,val2为新增编辑判断状态（1为新增，2为编辑）)
    add(val1, val2) {
      // console.log('新增', val1, val2);
      if (val2 === 2) {
        sessionStorage.setItem('shopId', val1)
        this.$router.push('/home/addShop/shopBasicInfo')
      } else {
        sessionStorage.removeItem('shopId')
        this.$router.push('/home/addShop/shopBasicInfo')
      }

    },
    // 删除店铺
    deleteShop(val) {
      // const res = await delShop({ shopId: val })
      this.$confirm("是否删除该店铺?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        const res = await delShop({ shopId: val })
        if (res.status === 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: "删除成功!",
          });
          this.getShopList(this.currentPage1)
        } else {
          this.$message({
            showClose: true,
            type: "info",
            message: "删除失败!" + res.msg,
          });
        }
      })


    },
    // 编辑店铺
    editShop(val) {
      this.edit = true
      // console.log('编辑', val);
    },
    // 点击预览店铺图片
    async preview(val) {
      // console.log('预览店铺', val);
      this.previewList = [];
      const res = await getPhoto({ shopId: val.id })
      // console.log('photo', res.data);
      if (res.status === 200) {
        if (res.data.length === 0) {
          this.$message({
            type: 'info',
            showClose: true,
            message: '该店铺还没有装饰图'
          })
        } else {
          const list = res.data
          list.map((item) => {
            this.previewList.push(item.decorateUrl)
          })
          this.showViewer = true
        }

      }
      // this.previewList = val.imgList
    },
    // 关闭查看器
    closeViewer() {
      this.showViewer = false
    }

  }

}
</script>

<style lang='scss' scoped>
.shopManagement {
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    .el-input {
      width: 160px !important;
      margin-right: 10px !important;
    }
    .el-select {
      width: 160px !important;
      height: 30px !important;
      margin-right: 10px;
    }
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .shopTable {
    width: 100%;
    min-height: 300px;
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
  .searchBar::v-deep .el-input__icon {
    line-height: 30px !important;
  }
  .searchBar::v-deep .el-input__inner {
    height: 30px !important;
  }
}
</style>
