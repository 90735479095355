<template>
  <div class="add">
    <!-- 客户信息tab -->
    <div class="header">
      <template>
        <el-menu
          :default-active="$route.path"
          text-color="#7D7D7D"
          active-text-color="#E3620B"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item
            :index="item.path"
            v-for="item in addMenuList"
            :key="item.id"
            >{{ item.title }}</el-menu-item
          >
        </el-menu>
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      activeName: "first",
      addMenuList: [
        { id: 1, title: '编辑服务项目', name: 'first', path: '/home/serviceTabs/updateServiceDetails' },
        { id: 2, title: '服务清单图片', name: 'second', path: '/home/serviceTabs/serviceImg' },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab.name);
      if (tab.name === 'first') {
        // this.activeName = 'first';
        this.$router.push('/home/serviceTabs/updateServiceDetails')
      } else if (tab.name === 'second') {
        // this.activeName = 'second'
        this.$router.push('/home/serviceTabs/serviceImg')
      }
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    }
  },
  created() {
    // console.log(this.$route.path)
    // console.log(this.$route.params);
    this.addMenuList = [
      { id: 1, title: '编辑服务项目', name: 'first', path: '/home/serviceTabs/updateServiceDetails' },
      { id: 2, title: '服务清单图片', name: 'second', path: '/home/serviceTabs/serviceImg' }
    ]
    if (this.$route.path === '/home/serviceTabs') {
      this.activeName = 'first'
      this.$router.push('/home/serviceTabs/updateServiceDetails')
    } else if (this.$route.path === '/home/serviceTabs/serviceImg') {
      this.activeName = 'second'
      this.$router.push('/home/serviceTabs/serviceImg')
    }
  }
};
</script>

<style lang="scss" scoped>
.add {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu {
      position: sticky;
      top: 0;
      z-index: 6;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  // ::v-deep .add .header .el-menu--horizontal > .el-menu-item[data-v-442fe11b] {
  //   margin-right: 77px !important;
  // }
}
</style>