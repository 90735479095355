<template>
  <div class="statisticalAnalysis">统计的分析</div>
</template>

<script>
export default {
  name: 'statisticalAnalysis',

  data() {
    return {}
  }
}
</script>

<style lang='scss' scoped>
.statisticalAnalysis {
  min-height: 300px;
  padding: 20px 30px;
  background-color: red;
}
</style>