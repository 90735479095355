<template>
  <!-- 派发现金券 -->
  <div class="customerManagement">
    <div class="searchBar">
      <el-select
        v-model="page.isVip"
        placeholder="请选择是否会员"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>

      <el-select
        v-model="page.memberId"
        placeholder="请选择会员"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in memberOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="page.shopId"
        placeholder="请选择店铺"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in allShopList"
          :key="item.shopId"
          :label="item.shopName"
          :value="item.shopId"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="page.type"
        placeholder="是否拥有该现金券"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="item in options1"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="page.customerName"
        clearable
        placeholder="请输入客户名称"
      />
      <button @click="queryBtn" style="margin-left: 20px">查询</button>
      <button @click="distributionCouponBtn" style="margin-left: 20px">
        派发现金券
      </button>
    </div>

    <div class="staffTable">
      <el-table
        :data="tableData"
        border
        size="small"
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
        style="width: 100%"
      >
        <el-table-column type="selection" width="40"></el-table-column>
        <el-table-column type="index" label="编号" width="60">
        </el-table-column>
        <el-table-column prop="userName" label="客户名称" width="100">
        </el-table-column>
        <el-table-column prop="gender" label="性别" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.gender === 1">男</span>
            <span v-else>女</span>
          </template>
        </el-table-column>
        <el-table-column prop="telephone" label="手机号" width="120">
        </el-table-column>
        <el-table-column prop="isVip" label="是否会员" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.isVip === 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="memberName" label="会员名" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.memberName === null" style="font-size: 4px"
              >暂无会员</span
            >
            <span v-else>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="vipTime" label="开通会员时间" width="180">
        </el-table-column>
        <el-table-column prop="notUseDiscount" label="未使用现金券数" width="120">
        </el-table-column>
        <el-table-column prop="useDiscount" label="已使用现金券数" width="120">
        </el-table-column>
        <el-table-column prop="overdueDiscount" label="过期现金券总数" width="120">
        </el-table-column>
        <el-table-column prop="discountSum" label="现金券总数" width="100">
        </el-table-column>
        <el-table-column prop="orderNumber" label="最近消费订单号" width="180">
        </el-table-column>
        <el-table-column prop="carNumber" label="最近消费车牌号" width="120">
        </el-table-column>
        <el-table-column prop="shopName" label="最近消费店铺" width="140">
        </el-table-column>
        <el-table-column
          prop="consumeTime"
          sortable="custom"
          label="最近消费时间"
          width="180"
        >
        </el-table-column>
        <el-table-column
          prop="createTime"
          sortable="custom"
          label="注册时间"
          width="110"
        >
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getCouponList,
  getSelectAllShop,
  distributionCoupon,
  memberList
} from '@/api/memberManagement/memberManagement.js'

export default {
  name: 'customerManagement',
  data() {
    return {
      page: {
        memberId: null,
        createTimeOrder: '', // 最近注册时间 desc倒叙 asc正叙
        consumeTimeOrder: '',  // 最近消费时间 desc倒叙 asc正叙
        discountId: parseInt(sessionStorage.getItem('couponId')), // 现金券id
        page: 1,
        size: 10,
        customerName: '',
        isVip: null, // 是否会员:1是2否
        shopId: null, // 最近消费店铺id
        type: null // 0 全部客户 1 拥有此现金券 2 没有拥有次现金券
      },
      total: 0,
      options: [
        {
          id: 1,
          label: '是'
        },
        {
          id: 2,
          label: '否'
        }
      ],
      options1: [
        {
          id: 1,
          label: '是'
        },
        {
          id: 2,
          label: '否'
        }
      ],
      memberOptions: [],
      //表格数据
      tableData: [],
      allShopList: [], // 所有店铺
      distributionCouponData: {
        customerId: [],
        discountId: parseInt(sessionStorage.getItem('couponId'))
      } // 派发现金券数据
    }
  },
  created() {
    this.getCouponList();
    this.getSelectAllShop();
    this.memberList();
  },
  methods: {
    // 获取选择的用户id
    handleSelectionChange(val) {
      // console.log(val);
      this.distributionCouponData.customerId = [];
      if (val.length > 0) {
        val.forEach(item => {
          this.distributionCouponData.customerId.push(item.id);
        })
      }
      ;
      // console.log(this.distributionCouponData.customerId)
    },
    // 派发现金券客户列表
    async getCouponList() {
      const res = await getCouponList(this.page);
      // console.log(res);
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查看所有店铺不分页
    async getSelectAllShop() {
      const res = await getSelectAllShop({
        object: ""
      });
      // console.log(res);
      if (res.status === 200) {
        this.allShopList = res.data;
      }
    },
    // 查询
    queryBtn() {
      this.getCouponList();
    },
    // 会员列表
    async memberList() {
      const res = await memberList();
      if (res.status === 200) {
        this.memberOptions = res.data;
        // console.log("获取会员列表--->"， this.memberOptions)
      }
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getCouponList();
    },
    // 排序 {column,prop,order} 列数据|排序字段|排序方式
    sortChange(column) {
      // console.log(column);
      this.page.createTimeOrder = null;
      if (column.order === null) {
        this.page.createTimeOrder = null;
        this.page.consumeTimeOrder = null;
      }
      if (column.prop === 'createTime' && column.order === 'ascending') {
        this.page.createTimeOrder = 'asc';
      }
      if (column.prop === 'createTime' && column.order === 'descending') {
        this.page.createTimeOrder = 'desc';
      }
      if (column.prop === 'consumeTime' && column.order === 'ascending') {
        this.page.consumeTimeOrder = 'asc';
      }
      if (column.prop === 'consumeTime' && column.order === 'descending') {
        this.page.consumeTimeOrder = 'desc';
      }
      this.getCouponList();
    },
    // 派发现金券
    async distributionCouponBtn() {
      // console.log(this.distributionCouponData);
      const res = await distributionCoupon(this.distributionCouponData);
      // console.log(res);
      if (res.status === 200) {
        this.$message({
          showClose: true,
          message: '派发成功!',
          type: 'success'
        });
        this.getCouponList();
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.customerManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      min-width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    width: 100%;
    min-height: 300px;

    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}

.mycell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  .vipIcon {
    width: 19px;
    height: 16px;
    vertical-align: sub;
    margin-right: 3px;
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}

.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>