<template>
  <!-- 会员管理 -->
  <div class="memberManagement">
    <div class="memberList">
      <div class="searchBar">
        <el-input
          v-model="page.name"
          clearable
          placeholder="会员名称"
          @change="queryBtn"
        />
        <button @click="queryBtn">查询</button>
        <button @click="reset">重置</button>
        <button @click="editMember">新增</button>
      </div>
      <div class="staffTable">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="id" label="编号"></el-table-column>
          <el-table-column prop="name" label="会员名称"></el-table-column>
          <el-table-column prop="iconUrl" label="会员图标">
            <template slot-scope="scope">
              <el-image class="imgBox" :src="scope.row.iconUrl"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="grade" label="会员等级">
            <template slot-scope="scope">
              <span>{{ scope.row.grade + "(级)" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="会员类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type===1">个人</span>
              <span v-else>机构</span>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="会员类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type===1">个人</span>
              <span v-else>机构</span>
            </template>
          </el-table-column>
          <el-table-column prop="duration" label="享受会员时长">
            <template slot-scope="scope">
              <span>{{ scope.row.duration + "(年)" }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column prop="favorablePrice" label="优惠价格">-->
          <!--            <template slot-scope="scope">-->
          <!--              <span>{{ scope.row.favorablePrice + '(元)' }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column prop="purchase" label="购买会员价格">
            <template slot-scope="scope">
              <span>{{ scope.row.purchase + "(元)" }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="editMember(scope.row.id)"
                type="text"
                size="small"
                >编辑
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="deleteMember(scope.row.id)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            v-model="page"
            @current-change="currentChange"
            :current-page.sync="page.page"
            :page-size="page.size"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {showList, remove} from "@/api/memberManagement/memberManagement.js";

export default {
  name: 'memberManagement',
  data() {
    return {
      total: 0,
      page: {
        page: 1,
        size: 10,
        start: 0,
        name: ''
      },
      //表格数据
      tableData: []
    }
  },
  components: {},
  created() {
    this.memberList()
  },
  methods: {
    // 查询按钮
    queryBtn() {
      // console.log(this.page)
      this.page.page = 1;
      this.memberList();
    },
    // 分页
    currentChange(currentPage) {
      this.page.page = currentPage;
      // console.log("size==>" , this.page.page)
      this.memberList();
    },
    //重置
    reset() {
      this.page.name = ''
      this.memberList();
    },
    // 新增按钮
    editMember(id) {
      if (isNaN(id)) {
        this.$router.push('/home/memberDetails');
      } else {
        sessionStorage.setItem("memberId", id)
        this.$router.push('/home/updateMemberDetails');
      }

    },
    // 删除会员信息
    deleteMember(id) {
      this.$confirm('此操作将永久删除会员, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await remove(id);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.memberList();
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    async memberList() {
      const res = await showList(this.page)
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.memberManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    width: 100%;
    min-height: 300px;
    .imgBox {
      width: 20px;
      height: 17px;
    }

    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}

.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>