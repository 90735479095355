<template>
  <!-- 附加商品管理 -->
  <div class="additionalGoodsManagement">
    <div class="searchBar">
      <el-input v-model="page.name" clearable placeholder="请输入商品名称" />
      <button @click="queryBtn">查询</button>
      <button @click="addBtn">新增</button>
    </div>
    <div class="userTable">
      <el-table :data="additionalGoodsList" border style="width: 100%">
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="name" label="商品名称"> </el-table-column>
        <el-table-column prop="brand" label="品牌"> </el-table-column>
        <el-table-column prop="price" label="单价"> </el-table-column>
        <!-- <el-table-column prop="jobNo" label="库存"> </el-table-column> -->
        <el-table-column prop="totalSales" label="总销售量"> </el-table-column>
        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="editBtn(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteAdditionalGoods(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdditionalGoodsList, deleteAdditionalGoods } from '@/api/additionalGoodsManagement/index.js'
export default {
  name: 'additionalGoodsManagement',
  data() {
    return {
      additionalGoodsList: [], // 附加商品列表数据
      page: {
        page: 1,
        size: 12,
        name: ''
      }, // 附加商品请求数据
      total: 0,
    }
  },
  created() {
    this.getAdditionalGoodsList();
  },
  methods: {
    // 附加商品列表数据
    async getAdditionalGoodsList() {
      const res = await getAdditionalGoodsList(this.page);
      // console.log(res);
      if (res.status === 200) {
        this.additionalGoodsList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查询按钮
    queryBtn() {
      this.page.page = 1;
      this.getAdditionalGoodsList();
    },
    // 新增按钮
    addBtn() {
      sessionStorage.setItem("additionalGoodsType", 'add')
      this.$router.push({
        name: 'additionalGoods',
        params: {
          name: 'add'
        }
      })
    },
    // 编辑
    editBtn(val) {
      // console.log(val);
      sessionStorage.setItem("additionalGoodsType", 'edit')
      sessionStorage.setItem("additionalGoodsId", val.id)
      this.$router.push({
        name: 'additionalGoods',
        params: {
          name: 'edit'
        }
      })
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getAdditionalGoodsList();
    },
    // 删除附加商品
    deleteAdditionalGoods(val) {
      // console.log('删除附加商品', val);
      this.$confirm('确认删除该附加商品?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // console.log('删除分类', val);
        const res = await deleteAdditionalGoods(val.id);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.getAdditionalGoodsList();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
  },

}


</script>

<style lang='scss' scoped>
.additionalGoodsManagement {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .userTable {
    width: 100%;
    min-height: 300px;
    .noneBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>
