<template>
  <!-- 员工绩效 -->
  <div class="achievementsBox">
    <div class="searchBar">
      <el-input
        v-model="page.userName"
        clearable
        placeholder="请输入员工名称"
      />
      <el-select
        v-model="page.shopId"
        placeholder="请选择店铺"
        clearable
        @change="queryBtn"
      >
        <el-option
          v-for="(item, index) in shopOptions"
          :key="index"
          :label="item.shopName"
          :value="item.shopId"
          :disabled="item.disabled"
        ></el-option>
      </el-select>
      <el-date-picker
        v-model="page.date"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd HH:mm:ss"
        :default-time="['00:00:00', '23:59:59']"
      >
      </el-date-picker>
      <button @click="queryBtn">查询</button>
      <button @click="toConfigureBtn">配置</button>
      <button @click="recalculationBtn">
        <span v-if="!isRecalculation">重算</span>
        <span v-else>开始重算</span>
      </button>
    </div>
    <div class="userTable">
      <el-table
        :data="achievementsList"
        @selection-change="handleSelectionChange"
        border
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          width="40"
          v-if="isRecalculation"
        ></el-table-column>
        <el-table-column prop="orderNumber" label="订单编号" fixed width="180">
        </el-table-column>
        <el-table-column prop="carNumber" label="车牌号" width="100">
        </el-table-column>
        <el-table-column prop="userName" label="用户名称" width="160">
          <template slot-scope="scope">
            <div v-if="scope.row.constructorMark === 1">
              {{ scope.row.userName }} （洗车员工A）
            </div>
            <div v-if="scope.row.constructorMark === 2">
              {{ scope.row.userName }} （洗车员工B）
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="店铺名称" width="180">
        </el-table-column>
        <el-table-column
          prop="orderServiceProjectVos"
          label="服务项目名称"
          width="200"
        >
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.orderServiceProjectVos"
              :key="index"
            >
              {{ item.serviceProjectName }}&nbsp;&nbsp;&nbsp;
          </span>
          </template>
        </el-table-column>
        <el-table-column prop="performancePoints" label="绩效点" width="80">
        </el-table-column>
        <!-- <el-table-column
          prop="serviceProjectCommission"
          label="服务项目提成"
          width="120"
        >
        </el-table-column> -->
        <el-table-column prop="commission" label="员工提成" width="80">
        </el-table-column>
        <el-table-column prop="workTime" label="工作时长（分）" width="120">
        </el-table-column>
        <el-table-column prop="startTime" label="开始时间" width="180">
        </el-table-column>
        <el-table-column prop="endTime" label="结束时间" width="180">
        </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="editBtn(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteAdditionalGoods(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 配置弹窗 -->
    <el-dialog
      title="配置员工绩效点"
      :visible.sync="toConfigureDialog"
      width="30%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="medium"
        label-width="120px"
      >
        <el-form-item label="员工A绩效点" prop="constructionA">
          <el-input
            v-model="formData.constructionA"
            type="number"
            placeholder="请输入员工A绩效点"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工B绩效点" prop="constructionB">
          <el-input
            v-model="formData.constructionB"
            type="number"
            placeholder="请输入员工B绩效点"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="small">取 消</el-button>
        <el-button
          type="primary"
          @click="handelConfirm"
          size="small"
          :loading="loading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { addAchievementsConfigure, editAchievementsConfigure, getAchievementsConfigure, getAchievementsConfigureList, getSelectAllShop, achievementsRecount } from '@/api/achievements/index.js'
export default {
  name: 'achievements',
  data() {
    return {
      isRecalculation: false,
      toConfigureDialog: false,
      loading: false,
      achievementsList: [], // 绩效列表数据
      page: {
        page: 1,
        size: 12,
        userName: '',
        shopId: null,
        date: []
      }, // 绩效请求数据
      total: 0,
      shopOptions: [],
      isEdit: 1,
      formData: {
        constructionA: undefined,
        constructionB: undefined,
      },
      rules: {
        constructionA: [{
          required: true,
          message: '请输入员工A绩效点',
          trigger: 'blur'
        }],
        constructionB: [{
          required: true,
          message: '请输入员工B绩效点',
          trigger: 'blur'
        }],
      },
      shopStationRecordUserIds: [],
    }
  },
  created() {
    this.getAchievementsConfigureList();
    this.getSelectAllShop();
  },
  methods: {
    // 获取选择的数据
    handleSelectionChange(val) {
      this.shopStationRecordUserIds = [];
      // console.log(val);
      val.forEach(item => {
        this.shopStationRecordUserIds.push(item.shopStationRecordUserId)
      })
    },
    // 重算按钮
    async recalculationBtn() {
      if (this.isRecalculation) {
        if (this.shopStationRecordUserIds.length === 0) {
          return this.isRecalculation = !this.isRecalculation;
        }
        const res = await achievementsRecount(this.shopStationRecordUserIds);
        // console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '重算绩效点成功！',
            type: 'success'
          });
          this.getAchievementsConfigureList();
          this.isRecalculation = !this.isRecalculation;
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: 'success'
          });
        }
      } else {
        this.isRecalculation = !this.isRecalculation;
      }
    },
    // 分享列表数据
    async getAchievementsConfigureList() {
      const res = await getAchievementsConfigureList(this.page);
      // console.log(res);
      if (res.status === 200) {
        this.achievementsList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查看所有店铺不分页
    async getSelectAllShop() {
      const res = await getSelectAllShop({
        object: ''
      });
      // console.log(res);
      if (res.status === 200) {
        this.shopOptions = res.data;
      }
    },
    // 查询按钮
    queryBtn() {
      this.page.page = 1;
      this.getAchievementsConfigureList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getAchievementsConfigureList();
    },
    // 查询绩效点对象
    async getAchievementsConfigure() {
      const res = await getAchievementsConfigure();
      // console.log(res);
      if (res.status === 200) {
        if (res.data.id) {
          this.isEdit = 2;
          this.formData.constructionA = res.data.constructionA;
          this.formData.constructionB = res.data.constructionB;
          this.formData.id = res.data.id;
        } else {
          this.isEdit = 1;
        }
      }
    },
    // 配置
    toConfigureBtn() {
      this.getAchievementsConfigure();
      this.toConfigureDialog = true;
    },
    // 配置弹层取消
    cancel() {
      this.toConfigureDialog = false;
    },
    // 配置弹层确定
    handelConfirm() {
      this.$refs['elForm'].validate(async valid => {
        if (!valid) return
        this.formData.constructionA = Number(this.formData.constructionA);
        this.formData.constructionB = Number(this.formData.constructionB);
        if (this.isEdit === 1) {
          const res = await addAchievementsConfigure(this.formData);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增员工绩效点配置成功！',
              type: 'success'
            });
            this.getAchievementsConfigureList();
            this.cancel();
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
          }
        } else {
          const res = await editAchievementsConfigure(this.formData);
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '修改员工绩效点配置成功！',
              type: 'success'
            });
            this.getAchievementsConfigureList();
            this.cancel();
          } else {
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: 'success'
            });
          }
        }
      })
    }
  },

}


</script>

<style lang='scss' scoped>
.achievementsBox {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
  .userTable {
    width: 100%;
    min-height: 300px;
    .noneBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px;
  margin-right: 10px;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>


