<template>
  <!-- 分享管理 -->
  <div class="extensionManagement">
    <div class="searchBar">
      <el-input
        v-model="page.userName"
        clearable
        placeholder="请输入客户名称"
      />
      <el-input v-model="page.telephone" clearable placeholder="请输入手机号" />
      <button @click="queryBtn">查询</button>
      <!-- <button @click="addBtn">新增</button> -->
    </div>
    <div class="userTable">
      <el-table :data="additionalGoodsList" border style="width: 100%">
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="invitationUserName" label="邀请人">
          <template slot-scope="scope">
            <div class="mycell">
              {{ scope.row.invitationUserName }}
              <span
                v-if="scope.row.invitationGender === 1"
                style="margin-left: 10px"
                >先生</span
              >
              <span
                v-if="scope.row.invitationGender === 2"
                style="margin-left: 10px"
                >女士</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invitationTelephone" label="邀请人手机号">
        </el-table-column>
        <el-table-column prop="invitedUserName" label="被邀请人">
          <template slot-scope="scope">
            <div class="mycell">
              {{ scope.row.invitedUserName }}
              <span
                v-if="scope.row.invitedGender === 1"
                style="margin-left: 10px"
                >先生</span
              >
              <span
                v-if="scope.row.invitedGender === 2"
                style="margin-left: 10px"
                >女士</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invitedTelephone" label="被邀请人手机号">
        </el-table-column>
        <el-table-column prop="createdTime" label="邀请时间"> </el-table-column>
        <!-- <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="editBtn(scope.row)" type="text" size="small"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              @click="deleteAdditionalGoods(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column> -->
      </el-table>
      <div class="block">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getCustomerInvitationList } from '@/api/extensionManagement/index.js'
export default {
  name: 'extensionManagement',
  data() {
    return {
      additionalGoodsList: [], // 分享列表数据
      page: {
        page: 1,
        size: 12,
        userName: '',
        telephone: ''
      }, // 分享请求数据
      total: 0,
    }
  },
  created() {
    this.getCustomerInvitationList();
  },
  methods: {
    // 分享列表数据
    async getCustomerInvitationList() {
      const res = await getCustomerInvitationList(this.page);
      // console.log(res);
      if (res.status === 200) {
        this.additionalGoodsList = res.data.rows;
        this.total = res.data.total;
      }
    },
    // 查询按钮
    queryBtn() {
      this.page.page = 1;
      this.getCustomerInvitationList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.getCustomerInvitationList();
    },
  },

}


</script>

<style lang='scss' scoped>
.extensionManagement {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .userTable {
    width: 100%;
    min-height: 300px;
    .noneBox {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #7d7d7d;
    }
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>

