<template>
  <!-- 新增服务项目 -->
  <div class="serviceDetails">
    <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item>新增服务项目</el-menu-item>
    </el-menu>
    <!-- <el-tag style="margin-left: 80px; font-size: 14px">编辑服务项目:</el-tag>
    <br />
    <br /> -->
    <div class="editContent">
      <el-form
        ref="serviceForm"
        :model="serviceForm"
        :rules="rules"
        label-width="135px"
      >
        <el-form-item label="服务名称" prop="name">
          <el-input
            v-model="serviceForm.name"
            placeholder="请输入服务名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="服务类别" prop="stationType">
          <el-select
            v-model="serviceForm.serviceProjectCategoryId"
            placeholder="请选择服务类别"
          >
            <el-option
              v-for="item in serviceProjectCategoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="标题" prop="title">
          <el-input
            v-model="serviceForm.title"
            placeholder="请输入标题"
          ></el-input>
        </el-form-item>

        <el-form-item label="工位类型" prop="stationType">
          <el-select
            v-model="serviceForm.stationType"
            placeholder="请选择工位类型"
          >
            <el-option label="洗车工位" value="1"></el-option>
            <el-option label="美容工位" value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="价格" prop="price">
          <el-input
            v-model="serviceForm.price"
            type="number"
            placeholder="请输入原价格"
          ></el-input>
        </el-form-item>
        <el-form-item label="提成" prop="commission">
          <el-input
            type="number"
            v-model="serviceForm.commission"
            placeholder="请输入提成"
            clearable
          >
            <i slot="suffix" class="dw">元</i>
          </el-input>
        </el-form-item>
        <el-form-item label="提成百分比" prop="commissionPercentage">
          <el-input
            type="number"
            v-model="serviceForm.commissionPercentage"
            placeholder="请输入提成百分比"
            clearable
          >
            <i slot="suffix" class="dw">%</i>
          </el-input>
        </el-form-item>

        <el-form-item label="施工人数" prop="people">
          <el-input
            v-model="serviceForm.people"
            type="number"
            placeholder="请输入施工人数"
          ></el-input>
        </el-form-item>

        <el-form-item label="项目效果" prop="effect">
          <el-input
            type="textarea"
            v-model="serviceForm.effect"
            placeholder="请输入项目效果"
            style="width: 400px"
          ></el-input>
        </el-form-item>

        <el-form-item label="毛巾分类" prop="towel">
          <el-input
            type="textarea"
            v-model="serviceForm.towel"
            placeholder="请输入毛巾分类"
            style="width: 400px"
          ></el-input>
        </el-form-item>

        <el-form-item label="产品清单" prop="product">
          <el-input
            type="textarea"
            v-model="serviceForm.product"
            placeholder="请输入产品清单"
            style="width: 400px"
          ></el-input>
        </el-form-item>

        <el-form-item label="工具清单" prop="toll">
          <el-input
            type="textarea"
            v-model="serviceForm.toll"
            placeholder="请输入工具清单"
            style="width: 400px"
          ></el-input>
        </el-form-item>

        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="cancelBtn">取消</el-button>
          <el-button class="subBox" type="primary" @click="addBtn"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { addServiceProject, getServiceProjectCategoryList } from '@/api/goodsManagement/goodsManagement.js'

export default {
  name: 'serviceDetails',
  components: {},
  props: {},
  data() {
    return {
      serviceForm: {
        name: '',
        title: '',
        stationType: '',
        price: '',
        commission: null,
        commissionPercentage: null,
        serviceProjectCategoryId: null,
        people: '',
        effect: '',
        towel: '',
        toll: '',
        product: '',
      },
      page: {
        page: 1,
        size: 999,
        date: [],
        name: ''
      },
      serviceProjectCategoryOptions: [],
      rules: {
        name: [
          { required: true, message: '请输入服务名称', trigger: 'blur' },
        ],
        serviceProjectCategoryId: [
          { required: true, message: '请输入选择服务类别', trigger: 'blur' },
        ],
        title: [
          { required: true, message: '请输入标题', trigger: 'blur' },
        ],
        stationType: [
          { required: true, message: '请输入选择工位类型', trigger: 'blur' },
        ],
        price: [
          { required: true, message: '请输入原价格', trigger: 'blur' },
        ],
        people: [
          { required: true, message: '请输入施工人数', trigger: 'blur' },
        ],
        effect: [
          { required: true, message: '请输入项目效果', trigger: 'blur' },
        ],
        towel: [
          { required: true, message: '请输入毛巾分类', trigger: 'blur' },
        ],
        toll: [
          { required: true, message: '请输入工具清单', trigger: 'blur' },
        ],
        product: [
          { required: true, message: '请输入产品清单', trigger: 'blur' },
        ]
      }
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    this.serviceProjectCategoryList();
  },
  methods: {
    // 获取服务类别列表
    async serviceProjectCategoryList() {
      const res = await getServiceProjectCategoryList(this.page);
      if (res.status === 200) {
        this.serviceProjectCategoryOptions = res.data.rows;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    //确认按钮
    addBtn() {
      this.$refs['serviceForm'].validate(async valid => {
        if (valid) {
          if (this.serviceForm.commission && this.serviceForm.commissionPercentage) {
            return this.$message({
              showClose: true,
              message: '提成和提成百分比只能填写其中一个！',
              type: 'warning'
            });
          }
          if (this.serviceForm.people > 2) {
            return this.$message({
              showClose: true,
              message: '施工人数最大为2人！',
              type: 'warning'
            });
          }
          // console.log("参数---》", this.serviceForm)
          const res = await addServiceProject(this.serviceForm)
          // console.log("返回结果---》", res)
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            // console.log(res.data)
            sessionStorage.setItem("id", res.data)
            // this.$router.push('/home/goodsManagement');
            this.$router.push('/home/ServiceDetailedDetails');
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //取消按钮
    cancelBtn() {
      this.form = {}
      this.$router.push('/home/goodsManagement');
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceDetails {
  min-width: 200px;
  padding: 0px 30px;
  // padding-top: 40px;
  .editContent {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .el-input {
    width: 400px;
    margin-right: 10px;
  }

  .el-select {
    width: 400px;
    height: 30px;
    margin-right: 10px;
  }
  .btnBox {
    margin-top: 50px;
    // ::v-deep .el-form-item__content {
    //   margin-left: 14% !important;
    //   .resBox {
    //     width: 70px;
    //     height: 30px;
    //     line-height: 30px;
    //     padding: 0;
    //     background: #eaefff;
    //     border-color: #eaefff;
    //     border-radius: 2px;
    //     span {
    //       color: #5f6679;
    //     }
    //   }
    //   .subBox {
    //     width: 70px;
    //     height: 30px;
    //     line-height: 30px;
    //     padding: 0;
    //     background: #5f6679;
    //     border-color: #5f6679;
    //     border-radius: 2px;
    //     margin-left: 50px;
    //   }
    // }
    ::v-deep .el-button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      line-height: 5px;
      margin-bottom: 20px;
    }
    ::v-deep .el-button--default {
      background: #f4fff5;
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      color: #01df6e;
      margin-right: 20px;
    }
  }
  .dw {
    font-style: normal;
    color: #aaaaaa;
    font-weight: bold;
    line-height: 45px;
  }
}
</style>
