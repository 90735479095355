import request from '@/utils/request'
import qs from 'qs';

// 查看机构客户列表分页
export function getMechanismList(params) {
  return request({
    url: 'mechanism/list',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

// 查看所有客户
export function getSelectAllCustomer() {
  return request({
    url: 'customer/selectAllCustomer',
    method: 'get'
  });
}

// 查看车辆分类树状
export function getSelectCarClass() {
  return request({
    url: 'car-classification/selectCarClass',
    method: 'get'
  });
}

// 获取会员类型列表
export function getMember(type) {
  return request({
    url: 'member/member/type/' + type,
    method: 'get'
  });
}

// 新增机构
export function addMechanism(data) {
  return request({
    url: 'mechanism',
    method: 'post',
    data
  });
}

// 删除机构
export function deleteMechanism(params) {
  return request({
    url: 'mechanism/' + params,
    method: 'delete'
  });
}

// 查看机构详情
export function getMechanismDetails(params) {
  return request({
    url: 'mechanism/details',
    method: 'get',
    params
  });
}

// 机构余额变动
export function mechanismBalance(data) {
  return request({
    url: 'mechanism/mechanism/balance/change',
    method: 'put',
    data
  });
}

// 编辑机构信息
export function editMechanism(data) {
  return request({
    url: 'mechanism',
    method: 'put',
    data,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

// 机构消费充值记录列表
export function getRecordList(params) {
  return request({
    url: 'consume/record/mechanism/record',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}