// import axios from 'axios';
// import router from '@/router';
import {
  Notification,
  MessageBox
} from 'element-ui';
import axios from 'axios'

// 引入路由对象
import router from "../router"
// // import { getToken } from '@/utils/auth';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例

// 创建axios实例
const baseURL = 'https://manage.lediangroup.cn/api/'; // 客户线上
// const baseURL = 'https://ledianchemei.zhihanyixing.com/api/';
// const baseURL = 'http://192.168.0.114:8991/api';//蔡

const service = axios.create({
  // baseURL: 'http://test.zhihanyixing.com:8084', // api 的 base_url
  baseURL,
  timeout: 20000 // 请求超时时间
});

// import { ElMessageBox, ElMessage } from 'element-plus'


// 给axios添加请求拦截器：发送请求时，还没到后端，就把请求给拦截下来
service.interceptors.request.use((config) => {
  // console.log('config', config);
  // config.headers.Authorization = sessionStorage.token
  config.headers['token'] = sessionStorage.getItem('token'); // 让每个请求携带自定义token 请根据实际情况自行修改
  //     }
  config.headers['Content-Type'] = 'application/json';
  return config
}, (err) => {
  // console.log(err)
  return Promise.reject(err)
})

// 处理后端返回的报错信息：后端返回信息时，还没返回到前端，就把返回信息给拦截下来
// 给axios添加响应拦截器，有两个箭头函数，成功进入第一个箭头函数，失败进入第二个箭头函数
service.interceptors.response.use((response) => {
  // 响应成功
  // console.log('response', response);
  if (response.headers['content-disposition']) { //将响应文件名信息直接添加挂载rea.data
    response.data.contentDisposition = response.headers['content-disposition']
  }
  // console.log(response);
  if (response.data.status === 400) {
    Notification.error('登录已过期，请重新登录', '警告', {
      confirmButtonText: '确定',
      callback: action => {
        router.push('/')
      }
    });
    return router.push('/')
  }
  const code = response.status;
  if (code < 200 || code > 300) {
    Notification.error({
      title: response.data.msg
    });
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('error');
  } else {
    // alert(JSON.stringify(response.data))
    return response.data;
  }
  // return res.data
}, (err) => {
  // console.log(err);
  // 响应失败（后端返回了报错信息）
  if (err.response.data.status === 400) {
    // console.log('400')
    Notification.error('登录已过期，请重新登录', '警告', {
      confirmButtonText: '确定',
      callback: action => {
        router.push('/')
      }
    });
    ElMessageBox.alert('登录已过期，请重新登录', '警告', {
      confirmButtonText: '确定',
      callback: (action) => {
        router.push('/')
      },
    })
  } else {
    return Promise.reject(err)
  }
})

export default service;