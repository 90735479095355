<template>
  <!-- 新增现金券 -->
  <div class="serviceDetails">
    <el-menu
        :default-active="$route.path"
        text-color="#7D7D7D"
        active-text-color="#E3620B"
        mode="horizontal"
        :router="true"
    >
      <el-menu-item>新增现金券</el-menu-item>
    </el-menu>
    <!-- <el-tag type="info" style="margin-left: 10px; font-size: 15px"
      >新增现金券:</el-tag
    >
    <br />
    <br /> -->
    <div class="shopDetail">
      <el-form
          ref="couponForm"
          :model="couponForm"
          :rules="rules"
          label-width="135px"
      >
        <el-form-item label="现金券名" prop="name">
          <el-input
              v-model="couponForm.name"
              placeholder="请输入现金券名称"
          ></el-input>
        </el-form-item>

        <el-form-item label="消费类型" prop="discountType">
          <el-select
              v-model="couponForm.discountType"
              placeholder="请选择消费类型"
              @change="selectChange"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div v-show="isShow">
          <el-form-item label="指定消费" prop="consume">
            <el-input
                v-model="couponForm.consume"
                type="number"
                placeholder="请输入指定消费"
            >
              <i slot="suffix" class="dw">元</i>
            </el-input>
          </el-form-item>
        </div>

        <div v-show="denominationShow">
          <el-form-item label="优惠金额" prop="denomination">
            <el-input
                v-model="couponForm.denomination"
                placeholder="请输入优惠金额"
            >
              <i slot="suffix" class="dw">元</i>
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="使用时间类型" prop="type">
          <el-select
              v-model="couponForm.type"
              placeholder="请选择时间类型"
              @change="selectTypeChange"
          >
            <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div v-show="timeShow">
          <el-form-item label="开始时间" prop="startTime">
            <el-date-picker
                v-model="couponForm.startTime"
                type="date"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="选择日期"
                @change="startDateChange"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="结束时间" prop="endTime">
            <el-date-picker
                v-model="couponForm.endTime"
                value-format="yyyy-MM-dd 23:59:59"
                type="date"
                placeholder="选择日期"
                @change="endDateChange"
            >
            </el-date-picker>
          </el-form-item>
        </div>

        <div v-show="certainTimeShow">
          <el-form-item label="指定时间" prop="certainTime">
            <el-input
                v-model="couponForm.certainTime"
                type="number"
                placeholder="请输入指定时间"
            >
              <i slot="suffix" class="dw">小时</i>
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="是否允许机构车" prop="isMechanismCar">
          <el-select
              v-model="couponForm.isMechanismCar"
              placeholder="请选择是否允许机构车"
              @change="isMechanismCarChange"
          >
            <el-option
                v-for="item in isMechanismCarOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="指定车辆" prop="carRestrict">
          <el-select
              v-model="couponForm.carRestrict"
              placeholder="请选择指定车辆"
          >
            <el-option
                v-for="item in carRestrictOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="指定会员" prop="memberId">
          <el-select
              v-model="couponForm.memberId"
              placeholder="请选择指定会员"
          >
            <el-option
                v-for="item in memberOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="指定服务项目" prop="serviceProjectId">
          <el-select
              v-model="couponForm.serviceProjectId"
              placeholder="请选择指定服务项目"
          >
            <el-option
                v-for="item in serviceProjectOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>




        <el-form-item label="是否开启" prop="status">
          <el-switch
              v-model="couponForm.status"
              active-text="开启"
              active-value="2"
              inactive-value="1"
              active-color="#01DF6E"
          >
          </el-switch>
        </el-form-item>

        <el-form-item label="积分兑换" prop="isIntegral">
          <el-switch
              v-model="couponForm.isIntegral"
              active-text="开启"
              active-value="2"
              inactive-value="1"
              active-color="#01DF6E"
              @change="integralChange"
          >
          </el-switch>
        </el-form-item>

        <div v-show="isShowIntegral">
          <el-form-item label="积分数" prop="integralNumber">
            <el-input
                v-model="couponForm.integralNumber"
                type="number"
                placeholder="请输入积分兑换数"
            >
            </el-input>
          </el-form-item>
        </div>

        <el-form-item label="总数量" prop="sumNumber">
          <el-input
              v-model="couponForm.sumNumber"
              type="number"
              placeholder="请输入优惠价格"
          >
            <i slot="suffix" class="dw">张</i></el-input
          >
        </el-form-item>

        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="cancelBtn">取消</el-button>
          <el-button class="subBox" type="primary" @click="addBtn"
          >确定
          </el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import {addDiscount, selectMemberTypeList,allServiceProject} from "@/api/couponManagement/couponManagement";

export default {
  name: 'serviceDetails',
  components: {},
  props: {},
  data() {
    return {
      isShow: false,
      timeShow: false,
      certainTimeShow: false,
      denominationShow: true,
      isShowIntegral: false,
      couponForm: {
        name: '',
        discountType: '',
        consume: '',
        denomination: '',
        startTime: '',
        endTime: '',
        status: false,
        isIntegral: false,
        sumNumber: '',
        integralNumber: '',
        type: '',
        isMechanismCar: '',
        carRestrict: '',
        memberId: '',
        serviceProjectId:''
      },
      timeOptions: [
        {
          value: '1',
          label: '指定时间'
        },
        {
          value: '2',
          label: '派发现金券后指定时间到期'
        }
      ],
      serviceProjectOptions:[
        {
          value: 0,
          label: '通用'
        }
      ],
      memberOptions: [
        {
          value: 0,
          label: '通用'
        }
      ],
      carRestrictOptions: [
        {
          value: 0,
          label: '通用'
        },
        {
          value: 1,
          label: '会员车'
        },
        {
          value: 2,
          label: '普通车'
        },
        {
          value: 3,
          label: '机构车'
        }
      ],
      isMechanismCarOptions: [{
        value: 1,
        label: '允许'
      },
        {
          value: 2,
          label: '不允许'
        }],
      options: [{
        value: '1',
        label: '任意使用'
      },
        {
          value: '2',
          label: '指定消费金额使用'
        },
        {
          value: '3',
          label: '24小时洗车后下雨免费'
        }],
      rules: {
        serviceProjectId:[
          {required: true, message: '请选择服务项目', trigger: 'blur'},
        ],
        memberId: [
          {required: true, message: '请选择指定会员', trigger: 'blur'},
        ],
        carRestrict: [
          {required: true, message: '请选择指定车辆', trigger: 'blur'},
        ],
        isMechanismCar: [
          {required: true, message: '请选择是否允许机构车', trigger: 'blur'},
        ],
        name: [
          {required: true, message: '请输入现金券名', trigger: 'blur'},
        ],
        discountType: [
          {required: true, message: '请选择消费类型', trigger: 'blur'},
        ],
        // denomination: [
        //   {required: true, message: '请输入优惠金额', trigger: 'blur'},
        // ],
        // startTime: [
        //   {required: true, message: '请选择开始时间', trigger: 'blur'},
        // ],
        // endTime: [
        //   {required: true, message: '请选择结束时间', trigger: 'blur'},
        // ],
        status: [
          {required: true, message: '请选择是否开启', trigger: 'blur'},
        ],
        type: [
          {required: true, message: '请选择时间类型', trigger: 'blur'},
        ],
        isIntegral: [
          {required: true, message: '请选择是否积分兑换', trigger: 'blur'},
        ],
        sumNumber: [
          {required: true, message: '请输入总数量', trigger: 'blur'},
        ]
      }
    }
  },
  computed: {},
  watch: {},
  async created() {
    const res = await allServiceProject()
    if(res.status === 200){
      // console.log(res.data.rows)
      const  serviceList=res.data.rows
      for (let i = 0; i < serviceList.length; i++) {
        this.serviceProjectOptions.push({
          "value":serviceList[i].id,
          "label":serviceList[i].name
        })
      }

    }
  },
  methods: {
    // 允许机构车改变出发
    async isMechanismCarChange() {
      this.memberOptions=[{
        "value":0,
        "label":"通用"
      }]
      this.couponForm.memberId=''
      var res = [];
      if (this.couponForm.isMechanismCar === 1) {
        const resp = await selectMemberTypeList(2)
        if (resp.status === 200) {
          res = resp.data;
        }
      }
      else {
        const resp = await selectMemberTypeList(1)
        if (resp.status === 200) {
          res = resp.data;
        }
      }
      // 循环加对象数据
      // console.log("会员类型==", res.length)
      for (let i = 0; i < res.length; i++) {
        this.memberOptions.push({
              "value":res[i].id,
              "label":res[i].name
        })
      }
      // console.log("会员类型dd==", res.length)
    },
    // 时间类型改变触发
    selectTypeChange() {
      if (this.couponForm.type === '1') {
        this.timeShow = true
        this.certainTimeShow = false
      } else {
        this.certainTimeShow = true
        this.timeShow = false
      }
    },
    // 结束时间改变
    endDateChange() {
      if (this.couponForm.endTime < this.couponForm.startTime) {
        this.$message({
          message: '结束时间不能小于开始时间',
          type: 'error'
        });
        this.couponForm.endTime = ''
      }
    },
    // 开始时间改变
    startDateChange() {
      if (this.couponForm.endTime !== '' && this.couponForm.startTime > this.couponForm.endTime) {
        this.$message({
          message: '开始时间不能大于结束时间',
          type: 'error'
        });
        this.couponForm.startTime = ''
      }
    },
    //选择器改变
    selectChange() {
      if (this.couponForm.discountType === '2') {
        this.isShow = true
      } else {
        this.isShow = false
      }

      if (this.couponForm.discountType === '3') {
        // console.log("24小时免费洗车")
        this.denominationShow = false
      } else {
        this.denominationShow = true
      }
    },
    //积分兑换改变
    integralChange() {
      if (this.couponForm.isIntegral === '2') {
        this.isShowIntegral = true
      } else {
        this.isShowIntegral = false
      }
    },
    //确认按钮
    addBtn() {
      this.$refs['couponForm'].validate(async valid => {
        if (valid) {
          // console.log(this.couponForm)
          // console.log("参数---》" + this.couponForm)
          // console.log("status==>" + this.couponForm.status)
          // console.log("isIntegral==>" + this.couponForm.isIntegral)
          const res = await addDiscount(this.couponForm)
          // console.log("返回结果---》" + res)
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '新增成功',
              type: 'success'
            });
            // console.log(res.data)
            this.$router.push('/home/couponManagement');
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          // console.log('error submit!!');
          return false;
        }
      });

    },
    //取消按钮
    cancelBtn() {
      this.couponForm = {}
      this.$router.push('/home/couponManagement');
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceDetails {
  min-width: 200px;
  padding: 0px 30px;
  box-sizing: border-box;

  .shopDetail {
    margin-top: 40px;

    .btnBox {
      margin-top: 50px;

      // ::v-deep .el-form-item__content {
      //   margin-left: 14% !important;

      //   .resBox {
      //     width: 70px;
      //     height: 30px;
      //     line-height: 30px;
      //     padding: 0;
      //     background: #eaefff;
      //     border-color: #eaefff;
      //     border-radius: 2px;

      //     span {
      //       color: #5f6679;
      //     }
      //   }

      //   .subBox {
      //     width: 70px;
      //     height: 30px;
      //     line-height: 30px;
      //     padding: 0;
      //     background: #5f6679;
      //     border-color: #5f6679;
      //     border-radius: 2px;
      //     margin-left: 50px;
      //   }
      // }
  ::v-deep .el-button {
    width: 70px;
    height: 30px;
    background: linear-gradient(270deg, #01df6e 0%, #2df891 50%, #01df6e 100%);
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    border: none;
    font-size: 14px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 5px;
    margin-bottom: 20px;
  }
  ::v-deep .el-button--default {
    background: #f4fff5;
    box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: #01df6e;
    margin-right: 20px;
  }
    }
  }

  .el-input {
    width: 400px;
    margin-right: 10px;
  }

  .el-select {
    width: 400px;
    height: 30px;
    margin-right: 10px;
  }

  .dw {
    font-style: normal;
    color: #aaaaaa;
    font-weight: bold;
    line-height: 45px;
  }
}
</style>
