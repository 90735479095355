import request from '@/utils/request';
import qs from 'qs';

// 分享管理列表
export function getCustomerInvitationList(params) {
  return request({
    url: 'customer/invitation/record/page/list',
    method: 'get',
    params
  });
}