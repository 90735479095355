<template>
  <!-- 派发现金券记录 -->
  <div class="customerManagement">
    <div class="searchBar">
      <el-input
        v-model="page.customerName"
        clearable
        placeholder="请输入客户名称"
        @change="queryBtn"
      />

      <el-input
        v-model="page.discountName"
        clearable
        placeholder="请输入现金券名称"
        @change="queryBtn"
      />
      <div class="block">
        <el-date-picker
          v-model="page.time"
          type="daterange"
          range-separator="-"
          value-format="yyyy-MM-dd HH:mm:ss"
          start-placeholder="开始日期"
          :picker-options="pickerOptions"
          :default-time="['00:00:00', '23:59:59']"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </div>
      <button @click="queryBtn" style="margin-left: 20px">查询</button>
    </div>

    <div class="staffTable">
      <el-table
        :data="tableData"
        border
        size="small"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column type="index" label="编号" width="80">
        </el-table-column>
        <el-table-column prop="customerName" label="客户名称" width="120">
          <template slot-scope="scope">
            <div class="mycell">
              <span>{{ scope.row.customerName }}</span>
              <span v-if="scope.row.gender === 1" style="margin-left: 10px"
                >先生</span
              >
              <span v-if="scope.row.gender === 2" style="margin-left: 10px"
                >女士</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isVip" label="是否会员" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.isVip === 1">是</span>
            <span v-else>否</span>
          </template>
        </el-table-column>
        <el-table-column prop="memberName" label="会员名" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.memberName === ''" style="color: #ccc;"
              >暂无会员</span
            >
            <span v-else>{{ scope.row.memberName }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="discountName" label="现金券名称">
        </el-table-column>
        <el-table-column prop="type" label="现金券类型" width="100">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.type === 1"
                >新人优惠券</span
              >
              <span v-if="scope.row.type === 2"
                >后台派发</span
              >
              <span v-if="scope.row.type === 3"
                >分享奖励</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="userName" label="派发人" width="80">
        </el-table-column>
        <el-table-column prop="isUse" label="是否使用" width="80">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.isUse === 1"
                >是</span
              >
              <span v-if="scope.row.isUse === 2"
                >否</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="isOverdue" label="是否过期" width="80">
          <template slot-scope="scope">
            <div class="mycell">
              <span v-if="scope.row.isOverdue === 1"
                >是</span
              >
              <span v-if="scope.row.isOverdue === 2"
                >否</span
              >
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createdTime" sortable="custom" label="派发时间" width="170">
        </el-table-column>
        <el-table-column
          prop="expirationTime"
          sortable="custom"
          label="过期时间" width="170"
        >
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          v-model="page"
          @current-change="handleCurrentChange"
          :current-page.sync="page.page"
          :page-size="page.size"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { distributionRecordList } from '@/api/memberManagement/memberManagement.js'

export default {
  name: 'customerManagement',
  data() {
    return {
      page: {
        page: 1,
        size: 10,
        customerName: '', // 客户名称
        discountName: '', // 现金券名称
        time: []
      },
      total: 0,
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      //表格数据
      tableData: [],
    }
  },
  created() {
    this.distributionRecordList();
  },
  methods: {
    // 查看现金券派发记录列表
    async distributionRecordList() {
      const res = await distributionRecordList(this.page)
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
    // 查询
    queryBtn() {
      this.distributionRecordList();
    },
    // 翻页
    handleCurrentChange(currentPage) {
      this.page.page = currentPage;
      this.distributionRecordList();
    },
    // 排序 {column,prop,order} 列数据|排序字段|排序方式
    sortChange(column) {
      // console.log(column);
      this.page.createTimeOrder = null;
      if (column.order === null) {
        this.page.createTimeOrder = null;
        this.page.consumeTimeOrder = null;
      }
      if (column.prop === 'expirationTime' && column.order === 'ascending') {
        this.page.createTimeOrder = 'asc';
      }
      if (column.prop === 'expirationTime' && column.order === 'descending') {
        this.page.createTimeOrder = 'desc';
      }
      if (column.prop === 'createdTime' && column.order === 'ascending') {
        this.page.consumeTimeOrder = 'asc';
      }
      if (column.prop === 'createdTime' && column.order === 'descending') {
        this.page.consumeTimeOrder = 'desc';
      }
      this.distributionRecordList();
    }
  }
}
</script>

<style lang='scss' scoped>
.customerManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    width: 100%;
    min-height: 300px;

    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}

.mycell {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;

  .vipIcon {
    width: 19px;
    height: 16px;
    vertical-align: sub;
    margin-right: 3px;
  }
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}

.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>