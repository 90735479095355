import request from '@/utils/request';

// 查看用户列表
export function getSelectShopUser(data) {
  return request({
    url: 'sys-user/selectShopUser',
    method: 'post',
    data
  });
}

// 查询角色列表
export function getSelectRole() {
  return request({
    url: 'sys-role/selectRole',
    method: 'get'
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}

// 重置密码
export function resetPwd(params) {
  return request({
    url: 'sys-user/resetPwd',
    method: 'get',
    params
  });
}

// 删除用户
export function deleteUser(params) {
  return request({
    url: 'sys-user/deleteUser',
    method: 'get',
    params
  });
}

// 新增用户
export function addUser(data) {
  return request({
    url: 'sys-user/add',
    method: 'post',
    data
  });
}

// 编辑用户
export function updateUser(data) {
  return request({
    url: 'sys-user/update',
    method: 'post',
    data
  });
}

// 根据id查详情
export function getSelectUserById(params) {
  return request({
    url: 'sys-user/selectUserById',
    method: 'get',
    params
  });
}