import request from '@/utils/request';
import qs from 'qs';

// 分享配置管理列表
export function getExtensionConfigureList(params) {
  return request({
    url: 'extension/configure/page',
    method: 'get',
    params
  });
}

// 删除分享配置
export function deleteExtensionConfigure(id) {
  return request({
    url: 'extension/configure/' + id,
    method: 'delete'
  });
}

// 新增分享配置
export function addExtensionConfigure(data) {
  return request({
    url: 'extension/configure',
    method: 'post',
    data
  });
}

// 分享配置详情
export function getExtensionConfigureDetil(id) {
  return request({
    url: 'extension/configure/' + id,
    method: 'get'
  });
}

// 修改分享配置
export function editExtensionConfigure(data) {
  return request({
    url: 'extension/configure',
    method: 'put',
    data
  });
}

// 查询优惠卷列表
export function getDiscountAll(params) {
  return request({
    url: 'discount/all',
    method: 'get',
    params
  });
}

// 奖励领取记录
export function getExtensionConfigureRecordList(params) {
  return request({
    url: 'extension/configure/reward/collection/record',
    method: 'get',
    params
  });
}