import request from '@/utils/request'
import qs from 'qs';

// 查看客户列表分页
export function getSelectCustomer(data) {
  return request({
    url: 'customer/selectCustomer',
    method: 'post',
    data
  });
}

// 删除客户
export function deleteCustomer(params) {
  return request({
    url: 'customer/deleteCustomer',
    method: 'get',
    params
  });
}

// 新增客户
export function addCustomer(data) {
  return request({
    url: 'customer/manageCustomer',
    method: 'post',
    data
  });
}

// 根据客户id查详情
export function getSelectCustomerById(params) {
  return request({
    url: 'customer/selectCustomerById',
    method: 'get',
    params
  });
}

// 个人消费充值记录列表
export function getRecordList(params) {
  return request({
    url: 'consume/record/personal/record',
    method: 'get',
    params
  });
}

// 积分记录列表
export function getIntegralList(params) {
  return request({
    url: 'integral/Record/list',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

// 编辑客户
export function updateCustomer(data) {
  return request({
    url: 'customer/updateManageCustomer',
    method: 'put',
    data
  });
}

// 查看车辆分类树状
export function getSelectCarClass() {
  return request({
    url: 'car-classification/selectCarClass',
    method: 'get'
  });
}

// 获取会员类型列表
export function getMember(type) {
  return request({
    url: 'member/member/type/' + type,
    method: 'get'
  });
}

// 客户余额变动
export function customerBalanceChange(data) {
  return request({
    url: 'customer/customerBalanceChange',
    method: 'put',
    data
  });
}

// 客户积分变动
export function customerIntegralChange(data) {
  return request({
    url: 'customer/customerIntegralChange',
    method: 'put',
    data
  });
}

// 根据文件id删除文件
export function deleteFile(params) {
  return request({
    url: '/file/delete',
    method: 'post',
    params
  })
}

// 新增客户常用地址
export function addAddress(data) {
  return request({
    url: 'customer-address/addAddress',
    method: 'post',
    data
  });
}

// 编辑客户常用地址
export function updateAddress(data) {
  return request({
    url: 'customer-address/updateAddress',
    method: 'post',
    data
  });
}

// 客户导出excel
export function importConnectionExcel(params) {
  return request({
    url: '/customer/import/excel',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    },
    responseType: 'blob'
  })
}

// 删除客户常用地址
export function deleteAddress(params) {
  return request({
    url: 'customer-address/deleteAddress',
    method: 'get',
    params
  });
}

// 获取店铺地址前缀
export function getPrefixList(params) {
  return request({
    url: 'shop/address/prefix',
    method: 'get',
    params
  });
}

// 根据客户id查询常用地址
export function getSelectAddressByCustomerId(params) {
  return request({
    url: 'customer-address/selectAddressByCustomerId',
    method: 'get',
    params
  });
}

// 新增客户权益朋友
export function addFriend(params) {
  return request({
    url: 'customer-equity-friend/addFriend',
    method: 'get',
    params
  });
}

// 编辑客户权益朋友
export function updateFriend(data) {
  return request({
    url: 'customer-equity-friend/updateFriend',
    method: 'post',
    data
  });
}

// 根据客户id查询权益朋友
export function getSelectFriendByCustomerId(params) {
  return request({
    url: 'customer-equity-friend/selectFriendByCustomerId',
    method: 'get',
    params
  });
}

// 删除客户权益朋友
export function deleteFriend(params) {
  return request({
    url: 'customer-equity-friend/deleteFriend',
    method: 'get',
    params
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}