<template>
  <div class="add">
    <!-- 客户信息tab -->
    <div class="header">
      <template>
        <el-menu
          :default-active="$route.path"
          text-color="#7D7D7D"
          active-text-color="#E3620B"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item
            :index="item.path"
            v-for="item in addMenuList"
            :key="item.id"
            >{{ item.title }}</el-menu-item
          >
        </el-menu>
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      activeName: "first",
      addMenuList: [
        { id: 1, title: '机构详情', name: 'first', path: '/home/mechanismDetailTabs/mechanismDetail' },
        // { id: 2, title: '常用地址', name: 'second', path: '/home/mechanismDetailTabs/mechanismAddress' },
        // { id: 3, title: '权益朋友', name: 'third', path: '/home/mechanismDetailTabs/mechanismMyfriend' },
        { id: 4, title: '消费记录', name: 'fouth', path: '/home/mechanismDetailTabs/mechanismOrderRecords' },
        // { id: 5, title: '积分使用记录', name: 'fifth', path: '/home/mechanismDetailTabs/mechanismMoneyRecords' }
      ],
      checkEdit: sessionStorage.getItem('checkEdit')
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab.name);
      if (tab.name === 'first') {
        // this.activeName = 'first';
        this.$router.push('/home/mechanismDetailTabs/mechanismDetail')
      // } else if (tab.name === 'second') {
      //   // this.activeName = 'second'
      //   this.$router.push('/home/mechanismDetailTabs/mechanismAddress')
      // } else if (tab.name === 'third') {
      //   // this.activeName = 'second'
      //   this.$router.push('/home/mechanismDetailTabs/mechanismMyfriend')
      } else if (tab.name === 'forth') {
        // this.activeName = 'second'
        this.$router.push('/home/mechanismDetailTabs/mechanismOrderRecords')
      }
      // else {
      //   // this.activeName = 'second'
      //   this.$router.push('/home/mechanismDetailTabs/mechanismMoneyRecords')
      // }
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      console.log(key, keyPath);
    }
  },
  created() {
    console.log(this.$route.path)
    console.log(this.$route.params);
    if (this.checkEdit === 'add') {
      this.addMenuList = [
        { id: 1, title: '机构详情', name: 'first', path: '/home/mechanismDetailTabs/mechanismDetail' },
        // { id: 2, title: '常用地址', name: 'second', path: '/home/mechanismDetailTabs/mechanismAddress' },
        // { id: 3, title: '权益朋友', name: 'third', path: '/home/mechanismDetailTabs/mechanismMyfriend' },
        // { id: 4, title: '消费记录', name: 'fouth', path: '/home/mechanismDetailTabs/mechanismOrderRecords' },
      ]
    } else if (this.checkEdit === 'edit') {
      this.addMenuList = [
        { id: 1, title: '机构详情', name: 'first', path: '/home/mechanismDetailTabs/mechanismDetail' },
        // { id: 2, title: '常用地址', name: 'second', path: '/home/mechanismDetailTabs/mechanismAddress' },
        // { id: 3, title: '权益朋友', name: 'third', path: '/home/mechanismDetailTabs/mechanismMyfriend' },
        { id: 4, title: '消费记录', name: 'fouth', path: '/home/mechanismDetailTabs/mechanismOrderRecords' },
        // { id: 5, title: '积分使用记录', name: 'fifth', path: '/home/mechanismDetailTabs/mechanismMoneyRecords' }
      ]
    }
    // if (this.$route.path === '/home/mechanismDetailTabs') {
    //   this.activeName = 'first'
    //   this.$router.push('/home/mechanismDetailTabs/mechanismDetail')
    // } else if (this.$route.path === '/home/mechanismDetailTabs/mechanismAddress') {
    //   this.activeName = 'second'
    //   this.$router.push('/home/mechanismDetailTabs/mechanismAddress')

    // } else if (this.$route.path === '/home/mechanismDetailTabs/mechanismMyfriend') {
    //   this.activeName = 'third'
    //   this.$router.push('/home/mechanismDetailTabs/mechanismMyfriend')

    // } else if (this.$route.path === '/home/mechanismDetailTabs/orderRecordst') {
    //   this.activeName = 'forth'
    //   this.$router.push('/home/mechanismDetailTabs/orderRecordst')

    // } else {
    //   this.$route.path === '/home/mechanismDetailTabs/mechanismMoneyRecords'
    //   this.activeName = 'fifth'
    //   this.$router.push('/home/mechanismDetailTabs/mechanismMoneyRecords')

    // }
  }
};
</script>

<style lang="scss" scoped>
.add {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu {
      position: sticky;
      top: 0;
      z-index: 6;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  // ::v-deep .add .header .el-menu--horizontal > .el-menu-item[data-v-442fe11b] {
  //   margin-right: 77px !important;
  // }
}
</style>