<template>
  <div class="sharConfigurationTabs">
    <!-- 分享配置tab -->
    <div class="header">
      <template>
        <el-menu
          :default-active="$route.path"
          text-color="#7D7D7D"
          active-text-color="#E3620B"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item
            :index="item.path"
            v-for="item in addMenuList"
            :key="item.id"
            >{{ item.title }}</el-menu-item
          >
        </el-menu>
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'sharConfigurationTabs',
  data() {
    return {
      activeName: "first",
      addMenuList: [
        { id: 1, title: '配置信息', name: 'first', path: '/home/sharConfigurationTabs/sharConfigurationDatil' },
        { id: 2, title: '奖励领取记录', name: 'second', path: '/home/sharConfigurationTabs/sharConfigurationRecord' }
      ],
      sharConfigurationType: sessionStorage.getItem('sharConfigurationType')
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab.name);
      if (tab.name === 'first') {
        // this.activeName = 'first';
        this.$router.push('/home/sharConfigurationTabs/sharConfigurationDatil')
      } else {
        // this.activeName = 'second'
        this.$router.push('/home/sharConfigurationTabs/sharConfigurationRecord')
      }
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    }
  },
  created() {
    if (this.sharConfigurationType === 'add') {
      this.addMenuList = [
        { id: 1, title: '配置信息', name: 'first', path: '/home/sharConfigurationTabs/sharConfigurationDatil' }
      ]
    } else if (this.sharConfigurationType === 'edit') {
      this.addMenuList = [
        { id: 1, title: '配置信息', name: 'first', path: '/home/sharConfigurationTabs/sharConfigurationDatil' },
        { id: 2, title: '奖励领取记录', name: 'second', path: '/home/sharConfigurationTabs/sharConfigurationRecord' }
      ]
    }
    // console.log(this.$route.path)
    // if (this.$route.path === '/home/sharConfigurationTabs/sharConfigurationDatil') {
    //   this.activeName = 'first'
    //   this.$router.push('/home/sharConfigurationTabs/sharConfigurationDatil')
    // } else {
    //   this.activeName = 'second'
    //   this.$router.push('/home/sharConfigurationTabs/sharConfiguration')
    // }
  }
};
</script>

<style lang="scss" scoped>
.sharConfigurationTabs {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu {
      position: sticky;
      top: 0;
      z-index: 6;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  // ::v-deep .add .header .el-menu--horizontal > .el-menu-item[data-v-442fe11b] {
  //   margin-right: 77px !important;
  // }
}
</style>