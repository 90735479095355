<template>
  <div class="add">
    <!-- 客户信息tab -->
    <div class="header">
      <template>
        <el-menu
          :default-active="$route.path"
          text-color="#7D7D7D"
          active-text-color="#E3620B"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item
            :index="item.path"
            v-for="item in addMenuList"
            :key="item.id"
            >{{ item.title }}</el-menu-item
          >
        </el-menu>
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      activeName: "first",
      addMenuList: [
        { id: 1, title: '客户详情', name: 'first', path: '/home/detailTabs/customerDetail' },
        { id: 2, title: '常用地址', name: 'second', path: '/home/detailTabs/address' },
        { id: 3, title: '权益朋友', name: 'third', path: '/home/detailTabs/myfriend' },
        { id: 4, title: '消费记录', name: 'fouth', path: '/home/detailTabs/orderRecords' },
        { id: 5, title: '积分记录', name: 'fifth', path: '/home/detailTabs/moneyRecords' }
      ],
      checkEdit: sessionStorage.getItem('checkEdit')
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab.name);
      if (tab.name === 'first') {
        // this.activeName = 'first';
        this.$router.push('/home/detailTabs/customerDetail')
      } else if (tab.name === 'second') {
        // this.activeName = 'second'
        this.$router.push('/home/detailTabs/address')
      } else if (tab.name === 'third') {
        // this.activeName = 'second'
        this.$router.push('/home/detailTabs/myfriend')
      } else if (tab.name === 'forth') {
        // this.activeName = 'second'
        this.$router.push('/home/detailTabs/orderRecords')
      } else {
        // this.activeName = 'second'
        this.$router.push('/home/detailTabs/moneyRecords')
      }
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    }
  },
  created() {
    // console.log(this.$route.path)
    // console.log(this.$route.params);
    if (this.checkEdit === 'add') {
      this.addMenuList = [
        { id: 1, title: '客户详情', name: 'first', path: '/home/detailTabs/customerDetail' },
        { id: 2, title: '常用地址', name: 'second', path: '/home/detailTabs/address' },
        { id: 3, title: '权益朋友', name: 'third', path: '/home/detailTabs/myfriend' }
      ]
    } else if (this.checkEdit === 'edit') {
      this.addMenuList = [
        { id: 1, title: '客户详情', name: 'first', path: '/home/detailTabs/customerDetail' },
        { id: 2, title: '常用地址', name: 'second', path: '/home/detailTabs/address' },
        { id: 3, title: '权益朋友', name: 'third', path: '/home/detailTabs/myfriend' },
        { id: 4, title: '消费记录', name: 'fouth', path: '/home/detailTabs/orderRecords' },
        { id: 5, title: '积分记录', name: 'fifth', path: '/home/detailTabs/moneyRecords' }
      ]
    }
    // if (this.$route.path === '/home/detailTabs') {
    //   this.activeName = 'first'
    //   this.$router.push('/home/detailTabs/customerDetail')
    // } else if (this.$route.path === '/home/detailTabs/address') {
    //   this.activeName = 'second'
    //   this.$router.push('/home/detailTabs/address')

    // } else if (this.$route.path === '/home/detailTabs/myfriend') {
    //   this.activeName = 'third'
    //   this.$router.push('/home/detailTabs/myfriend')

    // } else if (this.$route.path === '/home/detailTabs/orderRecordst') {
    //   this.activeName = 'forth'
    //   this.$router.push('/home/detailTabs/orderRecordst')

    // } else {
    //   this.$route.path === '/home/detailTabs/moneyRecords'
    //   this.activeName = 'fifth'
    //   this.$router.push('/home/detailTabs/moneyRecords')

    // }
  }
};
</script>

<style lang="scss" scoped>
.add {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu {
      position: sticky;
      top: 0;
      z-index: 6;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  // ::v-deep .add .header .el-menu--horizontal > .el-menu-item[data-v-442fe11b] {
  //   margin-right: 77px !important;
  // }
}
</style>