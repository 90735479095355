import request from '@/utils/request';
import qs from 'qs';

// 查询绩效分页列表
export function getAchievementsConfigureList(params) {
  return request({
    url: 'achievements/page',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}

// 添加绩效点对象
export function addAchievementsConfigure(data) {
  return request({
    url: 'achievements/configure',
    method: 'post',
    data
  });
}

// 修改绩效点对象
export function editAchievementsConfigure(data) {
  return request({
    url: 'achievements/configure',
    method: 'put',
    data
  });
}

// 查询绩效点对象
export function getAchievementsConfigure() {
  return request({
    url: 'achievements/configure/one',
    method: 'get'
  });
}

// 重算绩效
export function achievementsRecount(data) {
  return request({
    url: 'achievements/recount',
    method: 'put',
    data
  });
}