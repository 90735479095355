<template>
  <div class="orderDetail">
    <!-- 订单信息tab -->
    <div class="header">
      <template>
        <el-menu
          :default-active="$route.path"
          text-color="#7D7D7D"
          active-text-color="#E3620B"
          mode="horizontal"
          @select="handleSelect"
          :router="true"
        >
          <el-menu-item
            :index="item.path"
            v-for="item in addMenuList"
            :key="item.id"
            >{{ item.title }}</el-menu-item
          >
        </el-menu>
        <router-view></router-view>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'carTabs',
  data() {
    return {
      activeName: "first",
      addMenuList: [
        { id: 1, title: '车辆信息', name: 'first', path: '/home/carTabs/editCarCat' },
        { id: 2, title: '消费记录', name: 'second', path: '/home/carTabs/carConsumption' }
      ],
      carCheckEdit: sessionStorage.getItem('carCheckEdit')
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab.name);
      if (tab.name === 'first') {
        // this.activeName = 'first';
        this.$router.push('/home/carTabs/editCarCat')
      } else {
        // this.activeName = 'second'
        this.$router.push('/home/carTabs/carConsumption')
      }
    },
    handleSelect(key, keyPath) {
      // console.log(this.$route.path)
      // console.log(key, keyPath);
    }
  },
  created() {
    if (this.carCheckEdit === 'add') {
      this.addMenuList = [
        { id: 1, title: '车辆信息', name: 'first', path: '/home/carTabs/editCarCat' }
      ]
    } else if (this.carCheckEdit === 'edit') {
      this.addMenuList = [
        { id: 1, title: '车辆信息', name: 'first', path: '/home/carTabs/editCarCat' },
        { id: 2, title: '消费记录', name: 'second', path: '/home/carTabs/carConsumption' }
      ]
    }
    // console.log(this.$route.path)
    // if (this.$route.path === '/home/carTabs/editCarCat') {
    //   this.activeName = 'first'
    //   this.$router.push('/home/carTabs/editCarCat')
    // } else {
    //   this.activeName = 'second'
    //   this.$router.push('/home/carTabs/carConsumption')
    // }
  }
};
</script>

<style lang="scss" scoped>
.orderDetail {
  padding: 0px 30px;
  // padding-top: 10px;
  box-sizing: border-box;
  height: 100%;

  .header {
    ::v-deep.el-tabs__header {
      margin: 0 !important;
    }
    .el-menu {
      position: sticky;
      top: 0;
      z-index: 6;
    }
    .el-menu--horizontal > .el-menu-item {
      padding: 0;
      margin-right: 45px;
    }
  }

  ::v-deep.el-tabs__header.is-top {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: white;
  }
  // ::v-deep .add .header .el-menu--horizontal > .el-menu-item[data-v-442fe11b] {
  //   margin-right: 77px !important;
  // }
}
</style>