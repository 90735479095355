<template>
  <!-- 编辑会员 -->
  <div class="serviceDetails">
    <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item>编辑会员</el-menu-item>
    </el-menu>
    <!-- <el-tag type="info" style="margin-left: 10px; font-size: 15px"
      >编辑会员:</el-tag
    >
    <br />
    <br /> -->
    <div class="shopDetail">
      <el-form
        ref="memberForm"
        :model="memberForm"
        :rules="rules"
        label-width="135px"
      >
        <el-form-item label="会员名" prop="name">
          <el-input
            v-model="memberForm.name"
            placeholder="请输入会员名"
          ></el-input>
        </el-form-item>

        <el-form-item label="会员等级" prop="grade">
          <el-input v-model="memberForm.grade" placeholder="请输入会员等级">
            <i slot="suffix" class="dw">级</i>
          </el-input>
        </el-form-item>

        <el-form-item label="会员等级" prop="grade">
          <el-select v-model="memberForm.type" placeholder="请选择会员类型">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="享受时长" prop="duration">
          <el-input
            v-model="memberForm.duration"
            placeholder="请输入享受会员时长(年)"
          >
            <i slot="suffix" class="dw">年</i>
          </el-input>
        </el-form-item>

        <el-form-item label="购买价格" prop="purchase">
          <el-input
            v-model="memberForm.purchase"
            type="number"
            placeholder="请输入购买会员价格"
          >
            <i slot="suffix" class="dw">元</i>
          </el-input>
        </el-form-item>

        <el-form-item label="会员图标" prop="iconUrl">
          <el-input
            v-model="memberForm.iconUrl"
            type="text"
            placeholder="请输入会员图标"
          ></el-input>
        </el-form-item>

        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="cancelBtn">取消</el-button>
          <el-button class="subBox" type="primary" @click="addBtn"
            >确定</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getById, update } from '@/api/memberManagement/memberManagement.js'

export default {
  name: 'serviceDetails',
  components: {},
  props: {},
  data() {
    return {
      memberForm: {
        name: '',
        grade: '',
        duration: '',
        favorablePrice: '',
        purchase: '',
      },
      options: [
        {
          id: 1,
          name: '个人'
        },
        {
          id: 2,
          name: '机构'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入会员名称', trigger: 'blur' },
        ],
        duration: [
          { required: true, message: '请输入享受会员时长', trigger: 'blur' },
        ],
        grade: [
          { required: true, message: '请输入会员等级', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择会员类型', trigger: 'blur' },
        ],
        // favorablePrice: [
        //   { required: true, message: '请输入优惠价格', trigger: 'blur' },
        // ],
        iconUrl: [
          { required: true, message: '请输入会员图标', trigger: 'blur' },
        ],
        purchase: [
          { required: true, message: '请输入购买会员价格', trigger: 'blur' },
        ]
      }
    }
  },
  computed: {},
  watch: {},
  created() {
    const id = sessionStorage.getItem("memberId");
    this.getMemberById(id)
  },
  methods: {
    //根据会员id获取会员对象
    async getMemberById(id) {
      const res = await getById(id);
      if (res.status === 200) {
        this.memberForm = res.data
      } else {
        this.$message({
          message: res.msg,
          type: 'error'
        });
      }
    },

    //确认按钮
    addBtn() {
      this.$refs['memberForm'].validate(async valid => {
        if (valid) {
          // console.log("参数---》", this.memberForm)
          const res = await update(this.memberForm)
          // console.log("返回结果---》", res)
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '编辑成功',
              type: 'success'
            });
            this.$router.push('/home/memberManagement');
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //取消按钮
    cancelBtn() {
      this.memberForm = {}
      this.$router.push('/home/memberManagement');
    }
  }
}
</script>

<style lang="scss" scoped>
.serviceDetails {
  min-width: 200px;
  padding: 0px 30px;
  box-sizing: border-box;
  .shopDetail {
    margin-top: 40px;
    .btnBox {
      margin-top: 50px;
      // ::v-deep .el-form-item__content {
      //   margin-left: 14% !important;
      //   .resBox {
      //     width: 70px;
      //     height: 30px;
      //     line-height: 30px;
      //     padding: 0;
      //     background: #eaefff;
      //     border-color: #eaefff;
      //     border-radius: 2px;
      //     span {
      //       color: #5f6679;
      //     }
      //   }
      //   .subBox {
      //     width: 70px;
      //     height: 30px;
      //     line-height: 30px;
      //     padding: 0;
      //     background: #5f6679;
      //     border-color: #5f6679;
      //     border-radius: 2px;
      //     margin-left: 50px;
      //   }
      // }
      ::v-deep .el-button {
        width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 5px;
        margin-bottom: 20px;
      }
      ::v-deep .el-button--default {
        background: #f4fff5;
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        color: #01df6e;
        margin-right: 20px;
      }
    }
  }

  .el-input {
    width: 400px;
    margin-right: 10px;
  }

  .el-select {
    width: 400px;
    height: 30px;
    margin-right: 10px;
  }
  .dw {
    font-style: normal;
    color: #aaaaaa;
    font-weight: bold;
    line-height: 45px;
  }
}
</style>
