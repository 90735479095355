import request from '@/utils/request'

// 获取评价客户列表
export function getEvaluateCustomerList(params) {
    return request({
        url: 'evaluate/customer/list',
        method: 'get',
        params
    });
}
// 根据客户id获取客户评价内容列表
export function getEvaluateCustomerListById(params) {
    return request({
        url: 'evaluate/customer/list/ById',
        method: 'get',
        params
    });
}