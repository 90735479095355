import request from '@/utils/request'
import qs from 'qs';

// 新增服务项目
export function addServiceProject(data) {
  return request({
    url: 'service-project/add',
    method: 'post',
    data
  });
}

// 新增服务项目会员价格
export function addServiceDetailed(data) {
  return request({
    url: 'service-project/add/service/detailed',
    method: 'post',
    data
  });
}

// 获取会员列表
export function getMemberName() {
  return request({
    url: 'service-project/member/name',
    method: 'get'

  });
}

// 获取会员价格及原价
export function selectPrice(memberId, serviceProjectId) {
  return request({
    url: 'service-project/selectPrice' + "?" + "memberId=" + memberId + "&" + "serviceProjectId=" + serviceProjectId,
    method: 'get'
  });
}

// 新增会员价格
export function addServiceMember(data) {
  return request({
    url: 'service-project/add/service/member',
    method: 'post',
    data
  });
}

// 删除服务项目
export function removeServiceProject(id) {
  return request({
    url: 'service-project/' + id,
    method: 'delete'
  });
}

// 删除服务清单
export function removeServiceDetailed(id) {
  return request({
    url: 'service-project/removeServiceDetailed/' + id,
    method: 'delete'
  });
}

// 删除服务会员价格
export function removeServiceProjectMemberGrade(id) {
  return request({
    url: 'service-project/removeServiceProjectMemberGrade/' + id,
    method: 'delete'
  });
}

// 根据服务项目id查询服务项目
export function selectById(id) {
  return request({
    url: 'service-project/byId/' + id,
    method: 'get'
  });
}
// 修改服务项目
export function updateServiceProject(data) {
  return request({
    url: 'service-project/update',
    method: 'put',
    data
  });
}

// 根据服务项目查询服务清单
export function findServiceDetailedById(id) {
  return request({
    url: 'service-project/by/id/' + id,
    method: 'get',

  });
}
// 修改服务项目清单
export function updateServiceDetailed(data) {
  return request({
    url: 'service-project/update/service/detailed',
    method: 'put',
    data
  });
}

// 根据服务项目id获取服务项目会员
export function getServiceProjectMemberGradeById(id) {
  return request({
    url: 'service-project/service/project/member/grade/' + id,
    method: 'get'
  });
}
// 修改服务项目会员
export function updateServiceMember(data) {
  return request({
    url: 'service-project/update/service/member',
    method: 'put',
    data
  });
}



// 查看所有服务项目列表
export function getSelectAllServiceProject(params) {
  return request({
    url: 'service-project/all',
    method: 'get',
    params
  });
}

// 查看所有服务项目列表
export function getSelectServiceProjectById(id) {
  return request({
    url: `service-project/selectServiceProjectById/${id}`,
    method: 'get'
  });
}

// 修改服务项目及清单
// export function updateServiceProject(data) {
//     return request({
//         url: 'service-project/updateServiceProject',
//         method: 'put',
//         data
//     });
// }

// 获取所有项目价格
export function getSelectAllServiceMemberGrade() {
  return request({
    url: 'service-project/selectAllServiceMemberGrade',
    method: 'get'
  });
}

// 修改服务价格
export function updateServiceMemberGrade(data) {
  return request({
    url: 'service-project/updateServiceMemberGrade',
    method: 'put',
    data
  });
}

//  服务清单样式列表
export function getDetailedStyle(data) {
  return request({
    url: 'service/detailed/style/list',
    method: 'post',
    data
  });
}

//  新增服务清单样式
export function addDetailedStyle(data) {
  return request({
    url: 'service/detailed/style',
    method: 'post',
    data
  });
}

//  删除服务清单样式
export function delDetailedStyle(val) {
  return request({
    url: 'service/detailed/style/' + val,
    method: 'delete'
  });
}

//  服务项目类别列表
export function getServiceProjectCategoryList(params) {
  return request({
    url: 'service/project/category/page',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

//  删除服务项目类别
export function delServiceProjectCategory(val) {
  return request({
    url: 'service/project/category/' + val,
    method: 'delete'
  });
}

//  新增服务项目类别
export function addServiceProjectCategory(data) {
  return request({
    url: 'service/project/category/save',
    method: 'post',
    data
  });
}

//  服务项目类别详情
export function getServiceProjectCategoryDetails(val) {
  return request({
    url: 'service/project/category/details/' + val,
    method: 'get'
  });
}

//  编辑服务项目类别
export function editServiceProjectCategory(data) {
  return request({
    url: 'service/project/category/change',
    method: 'post',
    data
  });
}