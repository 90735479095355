import request from '@/utils/request'

// 店铺列表及分页显示
export function getShopList(data) {
  return request({
    url: '/shop/selectShop',
    method: 'POST',
    data
  })
}
// 删除店铺
export function delShop(params) {
  return request({
    url: '/shop/deleteShop',
    method: 'get',
    params: params
  })
}
// 查看店铺预览图
export function getPhoto(params) {
  return request({
    url: '/shop/selectShopPhotoById',
    method: 'get',
    params: params
  })
}
// 获取店铺详情
export function getShopBasicInfo(params) {
  return request({
    url: '/shop/selectShopById',
    method: 'get',
    params: params
  })
}
// 新增店铺
export function newShop(data) {
  return request({
    url: '/shop/addShop',
    method: 'post',
    data
  })
}
// 编辑店铺
export function editShop(data) {
  return request({
    url: '/shop/updateShop',
    method: 'post',
    data
  })
}
//获取工位列表信息
export function getSiteList(data) {
  return request({
    url: '/shop-station/selectByStationId',
    method: 'POST',
    data
  })
}
//获取工位使用记录信息
export function getSiteUseRecordsList(data) {
  return request({
    url: '/shop-station-record/selectRecordByStationId',
    method: 'POST',
    data
  })
}
//新增工位
export function addSite(data) {
  return request({
    url: '/shop-station/addShopStation',
    method: 'post',
    data: data
  })
}
//编辑工位
export function updateShopStation(data) {
  return request({
    url: '/shop-station/updateShopStation',
    method: 'post',
    data: data
  })
}
//删除工位信息
export function delSite(params) {
  return request({
    url: '/shop-station/deleteShopStation',
    method: 'get',
    params: params
  })
}
//删除工位使用记录信息
export function delSiteUseRecords(params) {
  return request({
    url: '/shop-station-record/deleteShopStationRecord',
    method: 'get',
    params: params
  })
}
//获取店铺员工信息
export function getShopStaffs(params) {
  return request({
    url: '/shop-staff/selectShopStaffByShopId',
    method: 'get',
    params: params
  })
}
// 获取全部店铺用户信息
export function getAllShopStaffs(data) {
  return request({
    url: '/sys-user/selectShopUser',
    method: 'post',
    data
  })
}
// 获取角色信息
export function getRole(params) {
  return request({
    url: '/sys-role/selectRole',
    method: 'get',
    params: params
  })
}
//新增员工
export function newStaff(data) {
  return request({
    url: '/shop-staff/addShopStaff',
    method: 'post',
    data
  })
}
// 删除员工
export function delStaff(params) {
  return request({
    url: '/shop-staff/deleteShopStaffId',
    method: 'get',
    params: params
  })
}