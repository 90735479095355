import request from '@/utils/request';
import qs from 'qs';

// 车辆列表
export function getCarList(data) {
  return request({
    url: 'car/selectCar',
    method: 'post',
    data
  });
}

// 新增车辆
export function addCar(data) {
  return request({
    url: 'car/addCar',
    method: 'post',
    data
  });
}

// 编辑车辆
export function updateCar(data) {
  return request({
    url: 'car/updateCar',
    method: 'post',
    data
  });
}

// 根据id删除车辆
export function deleteCar(params) {
  return request({
    url: 'car/deleteCar',
    method: 'get',
    params
  });
}

// 根据id查看详情
export function getSelectById(params) {
  return request({
    url: 'car/selectById',
    method: 'get',
    params
  });
}

// 根据客户id查详情
export function getSelectCustomerById(params) {
  return request({
    url: 'customer/selectCustomerById',
    method: 'get',
    params
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}

// 车辆消费记录
export function getCarConsumeRecord(params) {
  return request({
    url: 'car/car/consume/record',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}

// 查看所有客户
export function getSelectAllCustomer() {
  return request({
    url: 'customer/selectAllCustomer',
    method: 'get'
  });
}

// 车辆导出excel
export function importCarExcel(params) {
  return request({
    url: '/car/import/excel',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

// 查看车辆分类树状
export function getSelectCarClass() {
  return request({
    url: 'car-classification/selectCarClass',
    method: 'get'
  });
}

// 新增车辆分类
export function addCarClass(data) {
  return request({
    url: 'car-classification/addCarClass',
    method: 'post',
    data
  });
}


// 根据id删除车辆分类
export function deleteCarClass(params) {
  return request({
    url: 'car-classification/deleteCarClass',
    method: 'get',
    params
  });
}

// 编辑车辆分类
export function updateCarClass(data) {
  return request({
    url: 'car-classification/updateCarClass',
    method: 'post',
    data
  });
}