<template>
  <!-- 编辑积分配置 -->
  <div class="integralConfigure">
    <el-menu
      :default-active="$route.path"
      text-color="#7D7D7D"
      active-text-color="#E3620B"
      mode="horizontal"
      :router="true"
    >
      <el-menu-item>编辑积分配置</el-menu-item>
    </el-menu>
    <div class="shopDetail">
      <el-form
        ref="integralConfigureForm"
        :model="integralConfigureForm"
        :rules="rules"
        label-width="135px"
      >
        <el-form-item label="兑换类型" prop="type">
          <el-select
            v-model="integralConfigureForm.type"
            placeholder="请选择兑换类型"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="要求" prop="integralRequire">
          <el-input
            v-model="integralConfigureForm.integralRequire"
            placeholder="请输入要求"
            type="number"
          >
            <i slot="suffix" class="dw" v-if="integralConfigureForm.type === 1"
              >字</i
            >
            <i slot="suffix" class="dw" v-if="integralConfigureForm.type === 2"
              >元</i
            >
          </el-input>
        </el-form-item>

        <el-form-item label="积分数" prop="number">
          <el-input
            v-model="integralConfigureForm.number"
            placeholder="请输入获取积分数"
            type="number"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="btnBox" size="large">
          <el-button class="resBox" @click="cancelBtn">取消</el-button>
          <el-button class="subBox" type="primary" @click="addBtn"
            >确定
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { update, byId } from '@/api/integralConfigureManagement/integralConfigureManagement.js'

export default {
  name: 'integralConfigure',
  components: {},
  props: {},
  data() {
    return {
      integralConfigureForm: {
        type: '',
        integralRequire: '',
        number: ''
      },
      options: [
        {
          id: 1,
          name: '评价'
        },
        {
          id: 2,
          name: '消费'
        }
      ],
      rules: {
        type: [
          { required: true, message: '请选择积分类型', trigger: 'blur' },
        ],
        number: [
          { required: true, message: '请输入积分数', trigger: 'blur' },
        ]
      }
    }
  },
  computed: {},
  watch: {},
  async created() {
    const integralConfigureId = sessionStorage.getItem("integralConfigureId")
    const res = await byId(integralConfigureId)
    if (res.status === 200) {
      this.integralConfigureForm = res.data
    }
  },
  methods: {
    //确认按钮
    addBtn() {
      this.$refs['integralConfigureForm'].validate(async valid => {
        if (valid) {
          // console.log("参数---》", this.integralConfigureForm)
          const res = await update(this.integralConfigureForm)
          // console.log("返回结果---》", res)
          if (res.status === 200) {
            this.$message({
              showClose: true,
              message: '编辑成功',
              type: 'success'
            });
            // console.log(res.data)
            this.$router.push('/home/integralConfigureManagement');
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    //取消按钮
    cancelBtn() {
      this.integralConfigureForm = {}
      this.$router.push('/home/integralConfigureManagement');
    }
  }
}
</script>

<style lang="scss" scoped>
.integralConfigure {
  min-width: 200px;
  padding: 0px 30px;
  box-sizing: border-box;

  .shopDetail {
    margin-top: 40px;

    .btnBox {
      margin-top: 50px;

      // ::v-deep .el-form-item__content {
      //   margin-left: 14% !important;

      //   .resBox {
      //     width: 70px;
      //     height: 30px;
      //     line-height: 30px;
      //     padding: 0;
      //     background: #eaefff;
      //     border-color: #eaefff;
      //     border-radius: 2px;

      //     span {
      //       color: #5f6679;
      //     }
      //   }

      //   .subBox {
      //     width: 70px;
      //     height: 30px;
      //     line-height: 30px;
      //     padding: 0;
      //     background: #5f6679;
      //     border-color: #5f6679;
      //     border-radius: 2px;
      //     margin-left: 50px;
      //   }
      // }
      ::v-deep .el-button {
        width: 70px;
        height: 30px;
        background: linear-gradient(
          270deg,
          #01df6e 0%,
          #2df891 50%,
          #01df6e 100%
        );
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        line-height: 5px;
        margin-bottom: 20px;
      }
      ::v-deep .el-button--default {
        background: #f4fff5;
        box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        color: #01df6e;
        margin-right: 20px;
      }
    }
  }

  .el-input {
    width: 400px;
    margin-right: 10px;
  }

  .el-select {
    width: 400px;
    height: 30px;
    margin-right: 10px;
  }

  .dw {
    font-style: normal;
    color: #aaaaaa;
    font-weight: bold;
    line-height: 45px;
  }
}
</style>
