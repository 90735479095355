
<template>
  <div class="systemUsers">
    <div class="searchBar">
      <el-select placeholder="请选择店铺" />
      <el-select placeholder="请选择职位" />
      <el-input placeholder="请输入姓名" />
      <button>查询</button>
      <button @click="add">新增</button>
    </div>
    <div class="userTable">
      <el-table :data="userData" border style="width: 100%">
        <el-table-column prop="id" label="编号"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="number" label="身份证号" width="180">
        </el-table-column>
        <el-table-column prop="jobNum" label="工号"> </el-table-column>
        <el-table-column prop="role" label="角色"> </el-table-column>
        <el-table-column prop="tel" label="联系方式"> </el-table-column>
        <el-table-column prop="status" label="权限状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-color="#5F6679"
              inactive-color="#B3B3B3"
              @change="changeStatus(scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="150">
          <template slot-scope="scope">
            <el-button @click="add(scope.row)" type="text" size="small"
              >编辑</el-button
            ><el-button @click="editPwd(scope.row)" type="text" size="small"
              >修改密码</el-button
            >
            <el-button type="text" size="small" @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage1"
          :page-size="100"
          layout="total, prev, pager, next, jumper"
          :total="1000"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'systemUsers',
  data() {
    return {
      userData: [
        {
          id: 1,
          name: '员工1',
          tel: '12345678',
          number: "1234567898765",
          jobNum: '01',
          role: '菜鸟',
          shop: '锦江区',
          status: true
        },
        {
          id: 2,
          name: '员工2',
          tel: '12345678',
          number: "1234567898765",
          jobNum: '02',
          role: '菜鸟',
          shop: '锦江区',
          status: true
        }
        ,
        {
          id: 3,
          name: '员工3',
          tel: '12345678',
          number: "1234567898765",
          jobNum: '03',
          role: '菜鸟',
          shop: '锦江区',
          status: true
        },
        {
          id: 4,
          name: '员工4',
          tel: '42345678',
          number: "4234567890986543234",
          jobNum: '04',
          role: '菜鸟',
          shop: '锦江区',
          status: true
        },
        {
          id: 5,
          name: '员工5',
          tel: '52345678',
          number: "5234567890986543234",
          jobNum: '05',
          role: '菜鸟',
          shop: '锦江区',
          status: true
        }
      ]
    }
  },
  methods: {
    // 新增按钮
    add(val) {
      // console.log('xinzeng员工');
      sessionStorage.setItem("userId", val.id)
      this.$router.push('/home/adduser')
    },
    // 删除用户
    deleteUser(val) {
      console.log('删除用户', val);

    },
    //切换状态事件
    changeStatus(val) {
      console.log('切换状态', val);
    },
    //编辑按钮
    editShop(val) {
      console.log('编辑', val);
    },
    //删除按钮
    deleteShop(val) {
      console.log();
    }

  }
}


</script>

<style lang='scss' scoped>
.systemUsers {
  // width: 100%;
  min-height: 300px;
  padding: 20px 30px;
  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;
    button {
      width: 70px;
      height: 30px;
      background: #5f6679;
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }
  .userTable {
    width: 100%;
    min-height: 300px;
    .block {
      text-align: center;
      padding-top: 20px;
    }
  }
}
// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}
.el-select {
  width: 160px !important;
  height: 30px !important;
  margin-right: 10px;
}
.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}
.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>