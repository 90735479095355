<template>
  <!-- 现金券管理 -->
  <div class="couponManagement">
    <div class="couponList">
      <div class="searchBar">
        <el-input
          v-model="page.name"
          clearable
          placeholder="现金券名称"
          @change="queryBtn"
        />
        <el-select
          v-model="page.status"
          placeholder="请选择现金券状态"
          clearable
        >
          <el-option key="0" label="关闭" value="0"></el-option>
          <el-option key="1" label="开启" value="1"></el-option>
        </el-select>
        <div class="block">
          <el-date-picker
            v-model="page.time"
            type="daterange"
            range-separator="-"
            value-format="yyyy-MM-dd HH:mm:ss"
            start-placeholder="开始日期"
            :picker-options="pickerOptions"
            :default-time="['00:00:00', '23:59:59']"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <button @click="queryBtn" style="margin-left: 10px">查询</button>
        <button @click="reset">重置</button>
        <button @click="editMember">新增</button>
        <button @click="distributionBtn">派发记录</button>
      </div>
      <div class="staffTable">
        <el-table :data="tableData" border>
          <el-table-column
            fixed
            prop="id"
            label="编号"
            width="60"
          ></el-table-column>
          <el-table-column
            fixed
            prop="name"
            label="名称"
            width="150"
          ></el-table-column>
          <el-table-column prop="discountType" label="消费类型" width="170">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.discountType === 1"
                >任意使用
              </el-tag>
              <el-tag type="success" v-else-if="scope.row.discountType === 2"
                >指定消费</el-tag
              >
              <el-tag type="success" v-else>24小时洗车后下雨免费</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="consume" label="指定消费" width="100">
            <template slot-scope="scope">
              <span>{{ scope.row.consume + "(元)" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="denomination" label="优惠价格" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.denomination !== 0">{{
                scope.row.denomination + "(元)"
              }}</span>
              <span v-else>{{ "免费" }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="isOverdue" label="是否过期" width="80">
            <template slot-scope="scope">
              <el-tag type="info" v-if="scope.row.isOverdue === 1">过期</el-tag>
              <el-tag type="success" v-else>未过期</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="type" label="时间类型" width="120">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.type === 1"
                >指定时间到期</el-tag
              >
              <el-tag type="success" v-else>一定时间到期</el-tag>
            </template>
          </el-table-column>

          <el-table-column prop="startTime" label="开始时间" width="100">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.startTime === null || scope.row.startTime === ''
                "
                >{{ "暂无" }}</span
              >
              <span v-else>{{ scope.row.startTime }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="endTime" label="结束时间" width="100">
            <template slot-scope="scope">
              <span
                v-if="scope.row.endTime === null || scope.row.endTime === ''"
                >{{ "暂无" }}</span
              >
              <span v-else>{{ scope.row.endTime }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="certainTime" label="一定时间">
            <template slot-scope="scope">
              <span v-if="scope.row.certainTime === null">{{ "暂无" }}</span>
              <span v-else>{{ scope.row.certainTime + "(小时)" }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="isMechanismCar" label="是否允许机构车">
            <template slot-scope="scope">
              <span v-if="scope.row.isMechanismCar === 1">{{ "允许" }}</span>
              <span v-else>{{ "不允许" }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="carRestrict" label="指定车辆">
            <template slot-scope="scope">
              <span v-if="scope.row.carRestrict === 1">{{ "会员车" }}</span>
              <span v-else-if="scope.row.carRestrict === 2">{{
                "普通车"
              }}</span>
              <span v-else-if="scope.row.carRestrict === 3">{{
                "机构车"
              }}</span>
              <span v-else>{{ "通用" }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="memberId" label="指定会员">
            <template slot-scope="scope">
              <span v-if="scope.row.memberId === 0">{{ "通用" }}</span>
              <span v-else>{{ scope.row.memberName }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="serviceProjectId" label="指定项目">
            <template slot-scope="scope">
              <span v-if="scope.row.serviceProjectId === 0">{{ "通用" }}</span>
              <span v-else>{{ scope.row.serviceProjectName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否开启" width="80">
            <template slot-scope="scope">
              <el-switch
                disabled
                v-model="scope.row.status"
                active-color="#01DF6E "
                inactive-color="#B3B3B3"
                :active-value="2"
                :inactive-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="isIntegral" label="积分兑换" width="80">
            <template slot-scope="scope">
              <el-switch
                disabled
                v-model="scope.row.isIntegral"
                active-color="#01DF6E "
                inactive-color="#B3B3B3"
                :active-value="2"
                :inactive-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            prop="sumNumber"
            label="总数量"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="integralNumber"
            label="积分数"
            width="70"
          ></el-table-column>
          <el-table-column
            prop="allocation"
            label="派发数量"
            width="80"
          ></el-table-column>

          <el-table-column
            prop="createTime"
            label="创建时间"
            width="100"
          ></el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <!-- <div
                class="update"
                v-show="scope.row.allocation === 0 ? true : false"
              >
                <el-button
                  @click="editMember(scope.row.id)"
                  type="text"
                  size="small"
                  >编辑
                </el-button>
              </div> -->
              <div class="update">
                <el-button
                  @click="editMember(scope.row.id)"
                  type="text"
                  size="small"
                  >编辑
                </el-button>
              </div>
              <div>
                <el-button
                  type="text"
                  size="small"
                  v-show="scope.row.allocation === 0 ? true : false"
                  @click="deleteMember(scope.row.id)"
                  >删除
                </el-button>
              </div>
              <div>
                <el-button
                  type="text"
                  size="small"
                  v-show="
                    scope.row.sumNumber === scope.row.allocation ? false : true
                  "
                  @click="distribution(scope.row.id)"
                  >派发现金券
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            v-model="page"
            @current-change="currentChange"
            :current-page.sync="page.page"
            :page-size="page.size"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!--      弹窗-->
      <div>
        <el-dialog title="收货地址" :visible.sync="dialogVisible">
          <el-table :data="gridData">
            <el-table-column
              prop="userName"
              label="客户名称"
              width="150"
            ></el-table-column>
            <el-table-column prop="isUse" label="是否使用" width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.isUse === 1" style="color: #d23131"
                  >已使用</span
                >
                <span v-else style="color: #36cb0a">未使用</span>
              </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="订单号" width="200">
              <template slot-scope="scope">
                <span v-if="scope.row.orderNum !== null">{{
                  scope.row.orderNum
                }}</span>
                <span v-else>未知</span>
              </template>
            </el-table-column>
            <el-table-column prop="isOverdue" label="是否过期">
              <template slot-scope="scope">
                <span v-if="scope.row.isOverdue === 1" style="color: #d23131"
                  >过期</span
                >
                <span v-else style="color: #36cb0a">未过期</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center; padding-top: 20px; margin-left: 50px">
            <el-pagination
              v-model="dialogPage"
              @current-change="dialogCurrentChange"
              :current-page.sync="dialogPage.page"
              :page-size="dialogPage.size"
              layout="total, prev, pager, next, jumper"
              :total="dialogTotal"
            >
            </el-pagination>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { showList, removeDiscount, getCustomerDiscountByDiscountId } from "@/api/couponManagement/couponManagement.js";

export default {
  name: 'couponManagement',
  data() {
    return {
      dialogVisible: false,
      gridData: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      total: 0,
      dialogTotal: 0,
      dialogPage: {
        page: 1,
        size: 6,
        id: ''
      },
      page: {
        page: 1,
        size: 10,
        start: 0,
        name: '',
        status: '',
        time: ''
      },
      //表格数据
      tableData: []
    }
  },
  components: {},
  created() {
    this.couponList()
  },
  methods: {
    //派发按钮
    distribution(id) {
      // 跳页面
      this.$router.push('/home/distributionCoupon');
      sessionStorage.setItem('couponId', id);
      // 穿现金券id
      // this.$message({
      //   showClose: true,
      //   message: '发放现金券啦',
      //   type: 'success'
      // });
    },
    // 弹窗按钮关闭
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {
        });
    },
    // 查询按钮
    queryBtn() {
      // console.log(this.page)
      this.couponList();
    },
    // 分页
    currentChange(currentPage) {
      this.page.page = currentPage;
      // console.log("size==>", this.page.page)
      this.couponList();
    },
    // 弹窗分页
    dialogCurrentChange(currentPage) {
      this.page.page = currentPage;
      // console.log("size==>", this.page.page)
    },
    //重置
    reset() {
      this.page.name = ''
      this.page.status = ''
      this.page.time = ''
      this.couponList();
    },
    // 派发记录按钮
    distributionBtn() {
      this.$router.push('/home/distributionRecord');
    },
    // 新增按钮
    editMember(id) {
      if (isNaN(id)) {
        this.$router.push('/home/addCoupon');
      } else {
        sessionStorage.setItem("discountId", id)
        this.$router.push('/home/updateCoupon');
      }

    },
    // 删除会员信息
    deleteMember(id) {
      this.$confirm('此操作将永久删除现金券, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const res = await removeDiscount(id);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: '删除成功',
            type: 'success'
          });
          this.couponList();
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: 'error'
          });
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });

    },
    // 现金券列表
    async couponList() {
      const res = await showList(this.page)
      if (res.status === 200) {
        this.tableData = res.data.rows;
        this.total = res.data.total;
      } else {
        this.$message({
          showClose: true,
          message: res.msg,
          type: 'error'
        });
      }
    },
  }
}
</script>

<style lang='scss' scoped>
.couponManagement {
  min-height: 300px;
  padding: 20px 30px;

  .searchBar {
    width: 100%;
    height: 30px;
    margin-bottom: 20px;
    display: flex;

    button {
      width: 70px;
      height: 30px;
      background: linear-gradient(
        270deg,
        #01df6e 0%,
        #2df891 50%,
        #01df6e 100%
      );
      box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border: none;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .staffTable {
    // width: 100%;
    // min-height: 300px;
    .el-tag {
      margin: 5px 0 !important;
    }

    .block {
      text-align: center;
      margin-top: 20px;
    }
  }
}
::v-deep.el-table {
  display: block !important;
}

// 输入框和选择框大小
.el-input {
  width: 160px !important;
  margin-right: 10px !important;
}

.el-select {
  width: 180px !important;
  height: 30px !important;
  margin-right: 10px;
}

.searchBar::v-deep .el-input__icon {
  line-height: 29px !important;
}

.searchBar::v-deep .el-input__inner {
  height: 29px !important;
}
</style>