import request from '@/utils/request';
import qs from 'qs';

// 附加商品列表
export function getAdditionalGoodsList(params) {
  return request({
    url: 'additional/goods/list',
    method: 'get',
    params
  });
}

// 删除附加商品
export function deleteAdditionalGoods(id) {
  return request({
    url: 'additional/goods/' + id,
    method: 'delete'
  });
}

// 新增附加商品
export function addAdditionalGoods(data) {
  return request({
    url: 'additional/goods',
    method: 'post',
    data
  });
}

// 附加商品详情
export function getAdditionalGoodsDetil(id) {
  return request({
    url: 'additional/goods/details/' + id,
    method: 'get'
  });
}

// 编辑附加商品
export function editAdditionalGoods(data) {
  return request({
    url: 'additional/goods',
    method: 'put',
    data
  });
}

// 查看所有店铺不分页
export function getSelectAllShop(data) {
  return request({
    url: 'shop/selectAllShop',
    method: 'post',
    data
  });
}

// 附加商品消费记录
export function getAdditionalGoodsRecordList(params) {
  return request({
    url: 'additional/goods/record/list',
    method: 'get',
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { indices: false })
    }
  });
}