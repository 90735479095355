<template>
  <!-- 操作日志 -->
  <div class="logs">操作日志</div>
</template>

<script>
export default {
  name: 'logs',
  data() {
    return {

    }
  }
}
</script>

<style lang='scss' scoped>
.logs {
  min-height: 300px;
  padding: 20px 30px;
  background-color: red;
}
</style>